import { gql } from "@apollo/client";

export const UPDATE_VACANCY = gql`
  mutation updateVacancy($form: VacancyInputType!, $id: Int!) {
    updateVacancy(form: $form, id: $id) {
      ... on VacancyType {
        id
        inWork
        inBlocked
        address {
          block
          blockType
          city
          cityType
          house
          houseType
          lat
          lng
          region
          regionType
          settlement
          street
          streetType
          value
        }
        position {
          id
          name
          key
        }
        age {
          from
          to
        }
        callCenterInfo {
          addresses
          description
          driverLicense
          experience
          farePayment
          goodsCategory
          mobilePayment
          personalCar
          presenceOfPda
          project
          vaccination
        }
        citizenship {
          id
          key
          name
        }
        claims
        closedAt
        contacts {
          email
          phone
          fullName
        }
        createdAt
        deletedAt
        description
        location {
          lat
          lng
        }
        manager {
          city
          email
          firstName
          id
          phone
          secondName
        }
        metro {
          distance
          line
          name
        }
        name
        platformTags
        publishedAt
        publishedOnAvito
        publishedOnHh
        publishedOnRabotut
        shortDescription
        salary {
          currency
          from
          gross
          to
        }
        showContacts
        status
        terms
        updatedAt
        replyCount
        replyCountNew
        employer {
          id
          key
          name
          logo
        }
        employment {
          name
          key
          id
        }
        citizenship {
          id
          key
          name
        }
        tag {
          colorBg
          color
          key
          name
        }
        region
        city
        relevance
        extraAvito {
          publicationFinishTime
          remoteId
          remoteUrl
        }
        extraHh {
          remoteUrl
          remoteId
          publicationFinishTime
        }
        additionalHh {
          area
          driverLicense
          experience
          languages
          professionalRoles
          name
          acceptTemporary
          acceptKids
          acceptHandicapped
        }
        additionalAvito {
          name
          experience
          businessArea
        }
      }
      ... on Error {
        statusCode
        errors
        message
      }
    }
  }
`;
