import React, { memo, useState } from "react";

import { Modal } from "components/UI/Modal";
import { AvitoForm } from "../AvitoForm";
import { HHForm } from "../HHForm";
import { UserRole, VacancyType } from "graphql/types/types";
import { Tabs } from "components/UI/Tabs";
import { Platform, platformName } from "common/const/platform";
import { RabotutForm } from "../RabotutForm";
import { useFetchUsers } from "common/hooks/users/useFetchUsers";
import { ZarplataForm } from "../ZarplataForm";

interface Props {
  open: boolean;
  vacancy: VacancyType;
  publishItems: Platform[];
  onChangeOpen: () => void;
}

const PublishModalComponent: React.FC<Props> = ({
  open,
  vacancy,
  publishItems,
  onChangeOpen,
}) => {
  const { userList } = useFetchUsers({}, 400, [
    UserRole.Manager,
    UserRole.Hrbp,
  ]);

  const [activeKey, setActiveKey] = useState("rabotut");

  const handleTabClick = (key: string) => {
    setActiveKey(key);
  };

  const platformForm: Record<Platform, React.ReactElement> = {
    headhunter: (
      <HHForm
        vacancy={vacancy}
        managerList={userList}
        publishItems={publishItems}
        changeTab={handleTabClick}
      />
    ),
    avito: (
      <AvitoForm
        vacancy={vacancy}
        managerList={userList}
        changeTab={handleTabClick}
      />
    ),
    rabotut: (
      <RabotutForm
        vacancy={vacancy}
        managerList={userList}
        publishItems={publishItems}
        changeTab={handleTabClick}
      />
    ),
    zarplata: (
      <ZarplataForm
        vacancy={vacancy}
        managerList={userList}
        publishItems={publishItems}
        changeTab={handleTabClick}
      />
    ),
  };

  const items = publishItems.map((platform) => ({
    key: platform,
    label: platformName[platform],
    children: platformForm[platform],
  }));

  return (
    <Modal open={open} size="large" onCancel={onChangeOpen} footer={null}>
      <Tabs
        defaultActiveKey="rabotut"
        activeKey={activeKey}
        onTabClick={handleTabClick}
        items={items}
      />
    </Modal>
  );
};

export const PublishModal = memo(PublishModalComponent);
