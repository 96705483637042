import { apiRequest } from "../apiRequest";

type SendUserEventPayload = {
  userId: number;
  url: string;
  context?: string;
  elementId: string;
  timestamp: string;
};

export const createEvent = <T>({
  userId,
  url,
  context,
  elementId,
  timestamp,
}: SendUserEventPayload) => {
  return apiRequest<T>("API", "post", "/activity/click_stream/", {
    user_id: userId,
    url,
    context,
    element_id: elementId,
    timestamp,
  });
};

export const getEvent = <T>(userId: number) => {
  return apiRequest<T>("API", "get", `/activity/click_stream/${userId}`);
};
