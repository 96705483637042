import React, { useRef, useEffect } from "react";
import { List, Avatar } from "antd";
import { Message } from "../../../services/store/modules/chats/types";
import { ContactsType } from "../../../graphql/types/types";

const TelegramIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      position: "absolute",
      top: "23px",
      left: "calc(50%)",
      zIndex: 2,
    }}
  >
    <path
      d="M18 9C18 13.9725 13.9725 18 9 18C4.0275 18 0 13.9725 0 9C0 4.0275 4.0275 0 9 0C13.9725 0 18 4.0275 18 9Z"
      fill="#2696D6"
    />
    <path
      d="M7.35 13.125C7.0575 13.125 7.11 13.0125 7.005 12.735L6.15 9.915L11.46 6.6L12.0825 6.765L11.565 8.175L7.35 13.125Z"
      fill="#C8DAEA"
    />
    <path
      d="M7.35 13.125C7.575 13.125 7.6725 13.02 7.8 12.9C7.995 12.7125 10.5 10.275 10.5 10.275L8.9625 9.9L7.5375 10.8L7.35 13.05L7.35 13.125Z"
      fill="#A9C9DD"
    />
    <path
      d="M7.5 10.83L11.13 13.5075C11.5425 13.7325 11.8425 13.62 11.9475 13.125L13.425 6.165C13.575 5.5575 13.1925 5.2875 12.795 5.4675L4.125 8.8125C3.5325 9.0525 3.54 9.3825 4.02 9.525L6.2475 10.2225L11.4 6.975C11.64 6.825 11.865 6.9075 11.685 7.0725L7.5 10.83Z"
      fill="url(#paint0_linear_12_907)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_12_907"
        x1="9.67432"
        y1="9.28163"
        x2="11.5493"
        y2="12.2816"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EFF7FC" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
    </defs>
  </svg>
);

interface ReplyChatMessageProps {
  messages: Message[];
  contacts: ContactsType;
  chatRef: React.RefObject<HTMLDivElement>;
}

export const ReplyChatMessage: React.FC<ReplyChatMessageProps> = ({
  messages,
  contacts,
  chatRef,
}) => {
  const messageListRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (messageListRef.current) {
      messageListRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);
  if (!messages) {
    return (
      <div className="empty-chat-image">{/* ... Empty chat content */}</div>
    );
  }
  return (
    <div className="chat-list" ref={chatRef}>
      <List
        itemLayout="horizontal"
        dataSource={messages}
        className="custom-chat-list"
        renderItem={(message, index) => (
          <List.Item
            className={`reply-chat-message ${
              !message.from_user ? "reply-own-message" : "reply-other-message"
            }`}
            style={{
              paddingTop: index === 0 ? "10px" : "0",
            }}
          >
            {!message.from_user ? (
              <>
                <List.Item.Meta
                  description={
                    <>
                      {message.text}
                      {message?.files && message?.files[0]?.length > 0 && (
                        <>
                          {message.files.map((imageUrl, imageIndex) => (
                            <div key={imageIndex}>
                              <a
                                href={imageUrl}
                                download={`Attached_Image_${imageIndex + 1}`}
                                rel="noopener noreferrer"
                                style={{
                                  display: "block",
                                  textDecoration: "none",
                                  color: "inherit",
                                }}
                              >
                                <img
                                  src={imageUrl}
                                  alt={`Attached Image ${imageIndex + 1}`}
                                  style={{
                                    maxWidth: "100%",
                                    borderRadius: "12px",
                                    marginTop: "8px",
                                  }}
                                />
                              </a>
                            </div>
                          ))}
                        </>
                      )}{" "}
                    </>
                  }
                  style={{
                    backgroundColor: "#f0f0f0",
                    color: "#000",
                    borderRadius: "12px 12px 0 12px",
                    padding: "10px 12px",
                    wordWrap: "break-word",
                    maxWidth: "300px",
                  }}
                />
              </>
            ) : (
              <>
                <Avatar
                  size={45}
                  style={{
                    margin: "45px 10px 0",
                    background: "none",
                  }}
                >
                  <svg
                    width="35"
                    height="35"
                    viewBox="0 0 34 34"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    {/* ... SVG paths */}
                  </svg>
                  <TelegramIcon />
                </Avatar>{" "}
                <List.Item.Meta
                  title={contacts.fullName}
                  description={
                    <>
                      {message.text}
                      {message.files?.map((imageUrl, imageIndex) => (
                        <div key={imageIndex}>
                          <a
                            href={imageUrl}
                            download={`Attached_Image_${imageIndex + 1}`}
                            rel="noopener noreferrer"
                            style={{
                              display: "block",
                              textDecoration: "none",
                              color: "inherit",
                            }}
                          >
                            <img
                              src={imageUrl}
                              alt={`Attached Image ${imageIndex + 1}`}
                              style={{
                                maxWidth: "100%",
                                borderRadius: "12px",
                                marginTop: "8px",
                              }}
                            />
                          </a>
                        </div>
                      ))}
                    </>
                  }
                  style={{
                    backgroundColor: "#ffffff",
                    color: "#000",
                    borderRadius: "12px 12px 12px 0",
                    padding: "10px 12px",
                    wordWrap: "break-word",
                    border: "1px solid #D1D1D1",
                    marginBottom: "12px",
                  }}
                  className="custom-list-item-meta"
                />
              </>
            )}
          </List.Item>
        )}
      />
      <div ref={messageListRef}></div>
    </div>
  );
};
