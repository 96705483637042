import React, { useCallback } from "react";
import { useParams } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import moment from "moment";

import { useAppDispatch, useAppSelector } from "services/store/store";
import { ReportTable } from "./components/ReportTable";
import { Filters } from "components/UI/Filters";
import { useToggle } from "common/hooks/useToggle";
import { FiltersModal, FiltersState } from "./components/FiltersModal";
import { getReportCallCenter } from "services/store/modules/reports/actions";
import {
  selectReport,
  selectReportList,
  selectReportUrl,
} from "services/store/modules/reports/selectors";
import { resetReportList } from "services/store/modules/reports";
import { preparePayload, titleByReportName } from "./helpers";
import { NotificationStatus } from "components/UI/Notifications/Notification";
import { Button } from "components/UI/Button";
import { Excel } from "components/UI/icons";
import { PaginationPayload } from "components/UI/Pagination";
import { ReportName } from "common/const/reports";
import { useChangeTitle } from "common/hooks/header/useChangeTitle";
import { PAGINATION } from "common/const/pagination";

import "./styles.scss";

export interface RouteParams {
  id: ReportName;
}

export const INITIAL_STATE: FiltersState = {
  dateFrom: moment().subtract(1, "months"),
  dateTo: moment(),
  departamentId: null,
  managerId: null,
  projectName: "",
};

export const Report: React.FC = () => {
  const dispatch = useAppDispatch();
  const { id: reportName } = useParams<RouteParams>();

  useChangeTitle({ title: titleByReportName[reportName] });

  const { loading, meta = null, error } = useAppSelector(selectReport) || {};
  const reportList = useAppSelector(selectReportList);
  const reportUrl = useAppSelector(selectReportUrl);

  const [open, toggleOpen] = useToggle(false);

  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty, dirtyFields, errors },
    setError,
    clearErrors,
    getValues,
  } = useForm<FiltersState>({
    defaultValues: INITIAL_STATE,
  });

  const getReport = useCallback(
    (form: FiltersState, pagination?: PaginationPayload) => {
      dispatch(
        getReportCallCenter(
          preparePayload(form, pagination ?? PAGINATION.REPORT, reportName)
        )
      );
    },
    [dispatch, reportName]
  );

  const updateCandidateForm: SubmitHandler<FiltersState> = useCallback(
    async (formData) => getReport(formData),
    [getReport]
  );

  return (
    <div className="report">
      <div className="report-header">
        <Filters
          className="report-filters"
          count={Object.values(dirtyFields).length}
          onOpen={toggleOpen}
          onReset={() => {
            reset();
            dispatch(resetReportList());
          }}
        />

        {reportList?.length && reportUrl ? (
          <a href={reportUrl} download="report">
            <Button icon={<Excel />} type="button" variant="primary">
              Выгрузить xls
            </Button>
          </a>
        ) : null}
      </div>
      {reportList?.length && !loading ? (
        <ReportTable
          formData={getValues()}
          reportList={reportList}
          meta={meta}
          onChangePagination={getReport}
        />
      ) : (
        <NotificationStatus
          loading={loading}
          error={error}
          isCondition={isDirty}
          notFoundText="Отчет не найден"
        />
      )}

      <FiltersModal
        control={control}
        isDirty={isDirty}
        open={open}
        errors={errors}
        onCancel={toggleOpen}
        onReset={() => reset(INITIAL_STATE)}
        setError={setError}
        clearErrors={clearErrors}
        omSubmit={handleSubmit(updateCandidateForm)}
      />
    </div>
  );
};
