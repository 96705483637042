import React from "react";
import cn from "classnames";

import { ChevronForward } from "../icons";

import "./styles.scss";

type Props = {
  mode: CardMode;
  text: string;
  icon?: React.ReactNode;
  className?: string;
  onClick?: () => void;
};

type CardMode = "list" | "grid";

export const Card: React.FC<Props> = ({
  mode,
  icon,
  text,
  className,
  onClick,
}) => {
  return (
    <div onClick={onClick} className={cn(className, "card", {
      "card--list": mode === "list",
      "card--grid": mode === "grid",
    })}>
      <div className="card-icon">{icon}</div>
      <div className="card-text">{text}</div>

      <span className="card-chevron"><ChevronForward /></span>
    </div>
  );
};
