import React from "react";
import { useSelector } from "react-redux";
import CandidateFormTables from "./CandidateFormTables";
import {
  selectCandidate,
  selectMainReply,
} from "../../../services/store/modules/candidates/selectors";
import CandidateStatusSwitcher from "./CandidateStatusSwitcher";
import InfoBlock from "../../UI/InfoBlock/InfoBlock";
import Alerts from "../../UI/Alerts/Alerts";
import CommunicationComponent from "./CommunicationComponent";

const CandidateForm = () => {
  const replyMain = useSelector(selectMainReply);

  const { candidate } = useSelector(selectCandidate);
  const { contacts } = candidate || {};
  const phone = contacts ? contacts[0].contact : undefined;

  return (
    <>
      {!phone && replyMain?.utm?.content && (
        <Alerts
          headText={
            "Номер телефона может быть неактуален, так как отклик поступил с HeadHunter"
          }
          text={
            "Для получения актуальных контактных данных перейдите в резюме кандидата:"
          }
          link={replyMain?.utm?.content}
        />
      )}
      {!phone && !replyMain?.utm?.content && replyMain?.chatLink && (
        <Alerts
          headText={"Отклик поступил с HeadHunter без резюме."}
          text={
            "Для получения актуальных контактных данных перейдите в чат HeadHunter:"
          }
          link={replyMain?.chatLink}
        />
      )}
      {candidate && (
        <div className="mx-auto rounded-lg grid grid-cols-3 gap-4 p-4">
          <InfoBlock label="ФИО" value={candidate.fullName} />
          <InfoBlock label="Телефон" value={phone} />
          <CommunicationComponent
            id={candidate.id}
            fullName={candidate.fullName ?? "Не указано"}
            phoneNumber={phone ?? "Не указано"}
            setupCall={candidate?.callCompleted ?? false}
          />
          <InfoBlock label="Возраст" value={candidate.age} />
          <InfoBlock label="Гражданство" value={candidate.citizenshipId} />
          <InfoBlock label="Город" value={replyMain?.address?.city} />
        </div>
      )}
      <div className="p-4">
        <p className="text-gray-500 mb-0">
          После контакта с кандидатом, поменяйте его статус и напишите
          комментарий к статусу
        </p>
      </div>
      <CandidateStatusSwitcher />
      <CandidateFormTables />
    </>
  );
};
export default CandidateForm;
