import { RootState } from "../../rootReducer";
import { createSelector } from "reselect";
import { IUser } from "./types";

export const userList = (state: RootState) => state.user.userList;
export const userListMetaSelector = (state: RootState) =>
  state.user.userListMeta;
export const getUserByIDSelector = (state: RootState) => state.user.getUserByID;

export const selectUsersById = createSelector(userList, (state) =>
  state.reduce<Record<number, IUser>>((acc, user) => {
    acc[user.id] = user;
    return acc;
  }, {})
);
