import React from "react";

type Props = {
  width?: number;
  height?: number;
  color?: string;
};

export const Company: React.FC<Props> = ({
  width = 24,
  height = 24,
  color = "#A6ACB9",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3831_6282)">
        <path
          d="M6 4.5V3.75C6 2.50736 7.00736 1.5 8.25 1.5H9.75C10.9926 1.5 12 2.50736 12 3.75V4.5M1.5 9.75H16.5M6 8.625V10.875M12 8.625V10.875M4.5 16.5H13.5C15.1569 16.5 16.5 15.1569 16.5 13.5V7.5C16.5 5.84315 15.1569 4.5 13.5 4.5H4.5C2.84315 4.5 1.5 5.84315 1.5 7.5V13.5C1.5 15.1569 2.84315 16.5 4.5 16.5Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3831_6282">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
