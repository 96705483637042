import React, { Fragment, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import CandidateTabs from "./CandidateTabs";
import { useAppDispatch } from "../../services/store/store";
import {
  fetchCandidateById,
  fetchReplyListByCandidate,
} from "services/store/modules/candidates/action";
import { useSelector } from "react-redux";
import {
  selectCandidate,
  selectReplies,
} from "../../services/store/modules/candidates/selectors";
import { Circles } from "react-loader-spinner";
import { clearData } from "services/store/modules/recommendations";
import {
  clearCandidateData,
  clearRepliesData,
} from "services/store/modules/candidates";

interface CandidateSidebarProps {
  open: boolean;
  onClose: () => void;
  candidateId: string;
}

export default function CandidateSidebar({
  open,
  onClose,
  candidateId,
}: CandidateSidebarProps) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (candidateId) {
      dispatch(fetchCandidateById(candidateId));
      dispatch(
        fetchReplyListByCandidate({
          id: candidateId,
          pagination: { limit: 5, offset: 0 },
        })
      );
    }
    return () => {
      dispatch(clearData());
      dispatch(clearRepliesData());
      dispatch(clearCandidateData());
    };
  }, [candidateId]);

  const { loading } = useSelector(selectCandidate);
  const ReplyLoading = useSelector(selectReplies);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <div className="fixed inset-0 overflow-y-auto" />
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-3xl">
                  <div className="flex h-full flex-col bg-white shadow-xl">
                    <div className="relative flex-1">
                      <div className=" absolute right-0 mr-8 mt-4">
                        <div className="ml-3 flex h-7 items-end">
                          <button
                            type="button"
                            className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            onClick={onClose}
                          >
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                      {loading ? (
                        <Circles
                          height="40"
                          width="40"
                          color="#6366F1"
                          ariaLabel="circles-loading"
                          wrapperStyle={{
                            justifyContent: "space-evenly",
                            marginTop: "50%",
                          }}
                          wrapperClass=""
                          visible={loading}
                        />
                      ) : (
                        <CandidateTabs />
                      )}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
