import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ItemState } from "services/store/types";
import { VacancyType } from "graphql/types/types";
import { fetchVacancy, updateVacancy } from "./actions";

type VacancyState = ItemState<VacancyType | null>;

const initialState: VacancyState = {
  data: null,
  loading: true,
  error: null,
};

const vacancySlice = createSlice({
  name: "vacancy",
  initialState,
  reducers: {
    decrementPeopleCountOpen(state) {
      if (!state.data) return;
      const { peopleCountOpen } = state.data;
      state.data.peopleCountOpen = Number(peopleCountOpen) - 1;
    },
    setVacancyInWork(state, { payload }: PayloadAction<{ inWork: boolean }>) {
      if (!state.data) return;
      state.data.inWork = payload.inWork;
    },
    setVacancyPeopleCount(
      state,
      {
        payload,
      }: PayloadAction<
        Pick<VacancyType, "peopleCountOpen" | "peopleCountClose">
      >
    ) {
      if (!state.data) return;
      state.data.peopleCountOpen = payload.peopleCountOpen;
      state.data.peopleCountClose = payload.peopleCountClose;
    },
    setVacancy(state, { payload }: PayloadAction<{ vacancy: VacancyType }>) {
      if (!state.data) return;

      state.data = payload.vacancy;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchVacancy.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchVacancy.fulfilled, (state, { payload }) => {
        state.data = payload.privateVacancy;
        state.loading = false;
      })
      .addCase(fetchVacancy.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message as string;
      })
      .addCase(updateVacancy.fulfilled, (state, { payload }) => {
        state.data = payload.updateVacancy;
        state.loading = false;
      })
      .addCase(updateVacancy.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message as string;
      });
  },
});
export const {
  decrementPeopleCountOpen,
  setVacancyInWork,
  setVacancyPeopleCount,
  setVacancy,
} = vacancySlice.actions;
export const vacancyReducer = vacancySlice.reducer;
