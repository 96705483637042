import moment from "moment";
import { getDateFormat } from "common/utils/date";
import { FiltersState } from "./components/FiltersModal";
import { PaginationPayload } from "components/UI/Pagination";
import { ReportName } from "common/const/reports";

export const preparePayload = (
  { dateFrom, dateTo, departamentId, managerId, projectName }: FiltersState,
  pagination: PaginationPayload,
  reportName: ReportName
) => ({
  dateFrom: dateFrom
    ? getDateFormat(dateFrom, "YYYY-MM-DD")
    : getDateFormat(moment().subtract(1, "months"), "YYYY-MM-DD"),
  dateTo: dateTo
    ? getDateFormat(dateTo, "YYYY-MM-DD")
    : getDateFormat(moment(), "YYYY-MM-DD"),
  sendExcel: "true",
  offset: String(pagination.offset),
  limit: String(pagination.limit),
  ...(departamentId ? { departamentId: String(departamentId) } : {}),
  ...(managerId ? { managerId: String(managerId) } : {}),
  ...(projectName ? { projectName } : {}),
  reportName,
});

export const titleByReportName: Record<ReportName, string> = {
  [ReportName.ReportCallCenter]: "Проверка вакансий КЦ",
};
