import { combineReducers, createSlice } from "@reduxjs/toolkit";
import { getInitialState } from "services/store/helpers";
import { getReportCallCenter } from "./actions";
import { Report } from "common/commonTypes/reports";

const reportSlice = createSlice({
  name: "report",
  initialState: getInitialState<Report[]>(),
  reducers: {
    resetReportList(state) {
      state.data = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getReportCallCenter.pending, (state) => {
        state.loading = true;
      })
      .addCase(getReportCallCenter.fulfilled, (state, { payload }) => {
        state.data = payload.data;
        state.meta = {
          limit: payload.limit,
          offset: payload.offset,
          total: payload.total,
        };
        state.url = payload.url;
        state.loading = false;
      })
      .addCase(getReportCallCenter.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message as string;
      })
  },
});

export const { resetReportList } = reportSlice.actions;

export const reportsReducer = combineReducers({
  report: reportSlice.reducer,
});