import React from "react";

export const PaperPlane: React.FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.07495 7.81025L17.6999 1.89658C17.7563 1.87502 17.8177 1.87025 17.8767 1.88283C17.9358 1.89541 17.9899 1.92482 18.0326 1.96749C18.0752 2.01016 18.1046 2.06428 18.1172 2.1233C18.1298 2.18233 18.125 2.24373 18.1035 2.3001L12.1898 17.9251C12.1663 17.9844 12.1251 18.0351 12.0719 18.0704C12.0187 18.1057 11.956 18.1238 11.8922 18.1224C11.8284 18.1209 11.7666 18.1 11.715 18.0623C11.6635 18.0247 11.6247 17.9722 11.6039 17.9118L8.97104 11.4239C8.94035 11.332 8.88869 11.2484 8.82015 11.1799C8.75161 11.1114 8.66807 11.0597 8.57612 11.029L2.08823 8.39853C2.02711 8.3782 1.97377 8.33947 1.93551 8.28766C1.89724 8.23584 1.87592 8.17347 1.87447 8.10907C1.87302 8.04467 1.8915 7.9814 1.92738 7.92791C1.96327 7.87442 2.01481 7.83333 2.07495 7.81025ZM17.9687 2.03135L8.86714 11.1329L17.9687 2.03135Z"
        fill="white"
        fillOpacity="0.6"
      />
      <path
        d="M17.9687 2.03135L8.86714 11.1329M2.07495 7.81025L17.6999 1.89658C17.7563 1.87502 17.8177 1.87025 17.8767 1.88283C17.9358 1.89541 17.9899 1.92482 18.0326 1.96749C18.0752 2.01016 18.1046 2.06428 18.1172 2.1233C18.1298 2.18233 18.125 2.24373 18.1035 2.3001L12.1898 17.9251C12.1663 17.9844 12.1251 18.0351 12.0719 18.0704C12.0187 18.1057 11.956 18.1238 11.8922 18.1224C11.8284 18.1209 11.7666 18.1 11.715 18.0623C11.6635 18.0247 11.6247 17.9722 11.6039 17.9118L8.97104 11.4239C8.94035 11.332 8.88869 11.2484 8.82015 11.1799C8.75161 11.1114 8.66807 11.0597 8.57612 11.029L2.08823 8.39853C2.02711 8.3782 1.97377 8.33947 1.93551 8.28766C1.89724 8.23584 1.87592 8.17347 1.87447 8.10907C1.87302 8.04467 1.8915 7.9814 1.92738 7.92791C1.96327 7.87442 2.01481 7.83333 2.07495 7.81025V7.81025Z"
        stroke="#0057AC"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
