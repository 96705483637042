import {
  CandidateUpdateType,
  UserRole,
  CandidateType,
  ContactType,
} from "graphql/types/types";
import moment from "moment";
import { getDateFormat } from "common/utils/date";
import { OPTION_GENDER } from "./constants/selectOptions";
import {
  transformToAddressInputType,
  transformToDaDataAddressSuggestion,
} from "common/helpers/transformDaData";
import { FiltersFormState } from "./types";
import { FieldErrors } from "react-hook-form";

const filterContactsByMain = (contacts: ContactType[]) => {
  const mainPhone = contacts.filter((contact) => contact.isMain);
  const otherPhones = contacts.filter((contact) => contact.id !== mainPhone[0]?.id)

  return [...mainPhone, ...otherPhones];
};

export const getInitialStateForm = (candidate: CandidateType) => ({
  age: candidate.age ? String(candidate.age) : "",
  dismissReason: candidate.dismissReason || "",
  driverLicense: candidate.driverLicense || [],
  email: candidate.email || "",
  lastShiftRate: candidate.lastShiftRate || "",
  managerComment: candidate.managerComment || "",
  firstName: candidate.firstName || "",
  secondName: candidate.secondName || "",
  patronymic: candidate.patronymic || "",
  contacts: filterContactsByMain(candidate.contacts || []),
  region: candidate.actualAddress?.region
    ? `${candidate.actualAddress.region} ${candidate.actualAddress.regionType}`
    : "",
  salaryExpectation: candidate.salaryExpectation
    ? String(candidate.salaryExpectation)
    : "",
  sex: OPTION_GENDER.find((sex) => sex.value === candidate.sex)?.value ?? "",
  shiftMaxDate: candidate.shiftMaxDate
    ? moment(candidate.shiftMaxDate)
    : undefined,
  shiftMinDate: candidate.shiftMinDate
    ? moment(candidate.shiftMinDate)
    : undefined,
  source: candidate.source ? String(candidate.source) : "",
  haveCar: String(candidate.haveCar),
  dateOfBirth: candidate.dateOfBirth
    ? moment(candidate.dateOfBirth)
    : undefined,
  russianCitizenship: String(candidate.russianCitizenship),
  inBlacklist: candidate.inBlacklist ?? false,
  actualAddress: transformToDaDataAddressSuggestion(candidate?.actualAddress),
  cardDeliveryRegion: candidate.cardDeliveryAddress?.region
    ? `${candidate.cardDeliveryAddress.region} ${candidate.cardDeliveryAddress.regionType}`
    : "",
  cardDeliveryAddress: candidate.cardDeliveryAddress
    ? transformToDaDataAddressSuggestion(candidate.cardDeliveryAddress)
    : undefined,
  metro: candidate?.metro || [],
  positionId: candidate.positionId || 0,
  positionsNew:
    candidate.positionsNew?.map((pos) => pos.position?.id || 0) || [],
  // поля, которые не меняются в форме, но мы их шлем на бэк
  positions: candidate.positions,
  badge: candidate.badge,
  calcDismissDate: candidate.calcDismissDate,
  citizenshipId: candidate.citizenshipId,
  city: candidate.city,
  companyId: candidate.companyId,
  departmentId: candidate.departmentId,
  dismissDate: candidate.dismissDate,
  experienceFull: candidate.experienceFull,
  experienceInCompany: candidate.experienceInCompany,
  hasChat: candidate.hasChat,
  isSpam: candidate.isSpam,
  managerId: candidate.managerId,
  ownerId: candidate.ownerId,
  receptionLastDate: candidate.receptionLastDate,
  status: candidate.status,
});

type FormPayloadProps = {
  formData: FiltersFormState;
};

const buildContacts = (formContacts: ContactType[]) => {
  return formContacts.map((item) => {
    const { id, ...rest } = item;
    const isTempId = Boolean(id?.includes("temp"));
    return {
      ...rest,
      ...(!isTempId ? { id } : {}),
    };
  });
};

export const getFormPayload = ({ formData }: FormPayloadProps) => {
  const updatedFields: FiltersFormState = { ...formData };
  const payload = {
    ...updatedFields,
    ...{ age: Number(updatedFields.age) },
    ...{ salaryExpectation: Number(updatedFields.salaryExpectation) },
    ...{ source: updatedFields.source ? updatedFields.source : null },
    ...{
      russianCitizenship: updatedFields.russianCitizenship === "true",
    },
    ...{ haveCar: updatedFields.haveCar === "true" },
    ...{
      dateOfBirth: updatedFields.dateOfBirth
        ? getDateFormat(updatedFields.dateOfBirth, "YYYY-MM-DD")
        : null,
    },
    ...{
      shiftMinDate: updatedFields.shiftMinDate
        ? getDateFormat(updatedFields.shiftMinDate, "YYYY-MM-DD")
        : null,
    },
    ...{
      shiftMaxDate: updatedFields.shiftMaxDate
        ? getDateFormat(updatedFields.shiftMaxDate, "YYYY-MM-DD")
        : null,
    },
    ...{
      actualAddress: transformToAddressInputType(updatedFields.actualAddress),
    },
    ...{
      fullName: `${updatedFields.secondName ?? ""} ${
        updatedFields.firstName ?? ""
      } ${updatedFields.patronymic ?? ""}`,
    },
    ...{
      cardDeliveryAddress: transformToAddressInputType(
        updatedFields.cardDeliveryAddress
      ),
    },
    ...{
      contacts: buildContacts(updatedFields.contacts),
    },
  };
  delete payload.cardDeliveryRegion;

  return payload as unknown as CandidateUpdateType;
};

export const getIsDisabled = (
  field: unknown,
  roleList: UserRole[],
  isDisable: boolean,
  role?: UserRole
) => {
  if (!Boolean(field) && !isDisable) {
    return false;
  }

  const isEnable = role && roleList.includes(role) && !isDisable;

  return !isEnable;
};

export const getActualAddressErrorMessage = ({actualAddress}: FieldErrors<FiltersFormState>) => {
  const { value, data } = actualAddress || {};
  const { house } = data || {};

  return value?.message ?? house?.message;
}