import React, { useMemo, useState } from "react";

import { RefusalType, VacancyType } from "graphql/types/types";
import { Modal } from "components/UI/Modal";
import { useAppDispatch, useAppSelector } from "services/store/store";
import { refusalReasons } from "services/store/modules/directory";
import { FormSelect } from "components/UI/Form/FormSelect";
import { Title } from "components/UI/Title";
import FormTextArea from "components/UI/Form/FormTextArea";
import { Button } from "components/UI/Button";
import { declineVacancy } from "services/store/modules/vacancy/actions";
import Text from "components/UI/Text";
import { deleteVacancy } from "services/store/modules/vacanciesList";
import toaster from "components/UI/Notifications/Notification";

type Props = {
  open: boolean;
  vacancyId: VacancyType["id"];
  onCancel: () => void;
};

export const RejectVacancyModal: React.FC<Props> = ({
  open,
  vacancyId,
  onCancel,
}) => {
  const dispatch = useAppDispatch();

  const [reason, setReason] = useState();
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<null | string>(null);

  const refusals = useAppSelector(refusalReasons);

  const options = useMemo(
    () =>
      refusals
        .filter(
          ({ type }) => type === RefusalType.Vacancy || type === RefusalType.All
        )
        .map(({ id, name }) => ({
          label: name,
          value: id,
        })),
    [refusals]
  );

  const rejectVacancy = async () => {
    if (!reason) {
      return;
    }

    setLoading(true);
    setError(null);
    try {
      await dispatch(
        declineVacancy({
          id: vacancyId,
          refusalId: reason,
          refusalText: comment,
        })
      );
      toaster.success({title: "Вакансия отклонена"});
      dispatch(deleteVacancy({id: vacancyId}));
      onCancel();
    } catch {
      setError("Ошибка отклонения вакансии");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal open={open} size="normal" onCancel={onCancel} footer={null}>
      <Title type="h2">Причина отказа</Title>
      <FormSelect
        label="Укажите причину отказа"
        defaultValue={"Укажите причину отказа"}
        options={options}
        value={reason}
        onChange={(v) => setReason(v)}
      />
      <FormTextArea
        label="Введите комментарий"
        placeholder="Введите комментарий"
        value={comment}
        onChange={(e) => setComment(e.target.value)}
      />
      <Button loading={loading} disabled={!reason} onClick={rejectVacancy}>
        Подтвердить
      </Button>
      {error && <Text style={{ color: "#E62249" }}>{error}</Text>}
    </Modal>
  );
};
