import { createAsyncThunk } from "@reduxjs/toolkit";
import { client as apollo } from "../../../../graphql/apollo";
import { VacancySuitableListResponse } from "./types";
import { VACANCY_SUITABLE_LIST } from "graphql/requests/query/vacancySuitableList";
import { AsyncThunkConfig } from "services/store/types";
import { QueryVacancySuitableListArgs } from "graphql/types/types";

export const fetchVacancySuitableList = createAsyncThunk<
  VacancySuitableListResponse,
  QueryVacancySuitableListArgs,
  AsyncThunkConfig
>(
  "recommendations/fetchVacancySuitableList",
  async (payload, { rejectWithValue }) => {
    try {
      const res = await apollo.query<VacancySuitableListResponse>({
        query: VACANCY_SUITABLE_LIST,
        variables: payload,
      });
      return res.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);
