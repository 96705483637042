import React, { ChangeEvent, FC, KeyboardEvent, useRef, useState } from "react";
import { Button, Input } from "antd";
import { SendOutlined, PaperClipOutlined } from "@ant-design/icons";
import ImageUploadModal from "./ImageUploadModal";
import useWebSocketConnection from "common/hooks/sockets/useWebSocketConnection";
import { Message } from "../../../services/store/modules/chats/types";
import {
  deleteFile,
  incrementByMessages,
} from "../../../services/store/modules/chats";
import { useAppDispatch } from "../../../services/store/store";
import { useSelector } from "react-redux";
import { selectFiles } from "../../../services/store/modules/chats/selectors";

interface MessageInputProps {
  chatId: string;
}

const MessageInput: FC<MessageInputProps> = ({ chatId }) => {
  const modalContainerRef = useRef<HTMLDivElement>(null);
  const [sendMessage, setSendMessage] = useState("");
  const dispatch = useAppDispatch();
  const { sendJsonMessage, readyState } = useWebSocketConnection(chatId);
  const { document } = useSelector(selectFiles);

  const [visible, setVisible] = useState<boolean>(false);

  const openModal = () => {
    setVisible(true);
  };

  const handlerHideModal = () => {
    dispatch(deleteFile());
    setVisible(false);
  };

  const handlerSendMessage = () => {
    if (sendMessage !== "" || document) {
      setSendMessage("");
      dispatch(deleteFile());
      if (!chatId) {
        return;
      }
      const newChatMessage: Message = {
        chat_id: chatId,
        from_user: false,
        text: sendMessage,
        files: document ? [document] : [],
      };
      dispatch(incrementByMessages(newChatMessage));

      if (readyState === WebSocket.OPEN) {
        sendJsonMessage({
          chat_id: chatId,
          from_user: false,
          text: sendMessage,
          messenger: "TELEGRAM",
          files: document ? [document.replace(/\?.*$/, "")] : [],
        });
      } else {
        console.error("WebSocket is not open.");
      }
    }
  };
  const handlerInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setSendMessage(event.target.value);
  };
  const handlerPressEnter = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    event.preventDefault();
    if (event.key === "Enter") {
      handlerSendMessage();
    }
  };

  return (
    <div className="input-container">
      <div ref={modalContainerRef} />
      <ImageUploadModal
        handlerSendMessage={handlerSendMessage}
        handlerHideModal={handlerHideModal}
        chatId={chatId}
        visible={visible}
      />
      <Button
        icon={
          <PaperClipOutlined
            color={"#fcfcfc"}
            style={{ fontSize: "24px", background: "#fcfcfc" }}
          />
        }
        onClick={openModal}
        style={{ border: "none", margin: "0 20px", background: "#fcfcfc" }}
      />
      <Input.TextArea
        value={sendMessage}
        onChange={handlerInputChange}
        onPressEnter={handlerPressEnter}
        placeholder="Введите сообщение..."
        autoSize={{ minRows: 1, maxRows: 6 }}
        style={{ width: "90%", padding: "10px" }}
      />
      <Button
        className="btn-send-message round-button"
        type="primary"
        shape="circle"
        onClick={handlerSendMessage}
        style={{ height: "39px" }}
      >
        <SendOutlined style={{ marginLeft: "3px", marginBottom: "5px" }} />
      </Button>
    </div>
  );
};

export default MessageInput;
