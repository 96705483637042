import React from "react";
import RecommendationsInput from "./RecommendationsInput";
import RecommendationsTables from "./RecommendationsTables";
import { useSelector } from "react-redux";
import { selectAddress } from "../../../services/store/modules/recommendations/selectors";
import { cn } from "../../../helpers/bem";
import classNames from "classnames/dedupe";
import "./styles.scss";

const b = cn("recommendations");
const Recommendations: React.FC = () => {
  const { data, error, loading } = useSelector(selectAddress);

  return (
    <div className={`${classNames(b())} pt-4`}>
      <RecommendationsInput />
      {loading ? (
        <div className="flex justify-center items-center h-[60vh]">
          <div className={b("spinner")}></div>
        </div>
      ) : Object.keys(data).length === 0 || error ? (
        <div className="flex justify-center items-center h-[60vh] flex-col space-y-4">
          <div className="text-red-500 text-lg font-semibold text-center">
            {error}
          </div>
          <p className="text-indigo-500 text-lg font-semibold text-center">
            {error &&
              "Попробуйте указать другой адрес или изменить вид маршрута"}
          </p>
        </div>
      ) : (
        <RecommendationsTables />
      )}
    </div>
  );
};

export default Recommendations;
