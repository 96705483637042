import React, { useEffect, useCallback } from "react";

import { useAppDispatch, useAppSelector } from "services/store/store";
import {
  selectVacancySuitableList,
  selectVacancySuitableListMeta,
  selectFilteredVacancySuitable,
} from "services/store/modules/recommendations/selectors";
import { RecommendMaps } from "./components/RecommendMaps";
import { RecommendTable } from "./components/RecommendTable";
import { CandidatesTable } from "components/CandidatesTable";
import { Pagination } from "components/UI/Pagination";
import { Title } from "components/UI/Title";
import { fetchVacancySuitableList } from "services/store/modules/recommendations/actions";
import {
  CandidateType,
  QueryVacancySuitableListArgs,
  VacancyType,
} from "graphql/types/types";
import { PAGINATION } from "common/const/pagination";
import { NotificationStatus } from "components/UI/Notifications/Notification";

import "./styles.scss";

export type RecommendedModeType = "reply" | "recommended" | "claim";

const titleTextByMode: Record<RecommendedModeType, string | null> = {
  recommended: null,
  reply: "Другие вакансии",
  claim: "Вакансии",
};

const renderTitle = (mode: RecommendedModeType) => {
  const titleText = titleTextByMode[mode];
  return titleText ? <Title type="h4">{titleText}</Title> : null;
};

type Props = {
  candidate: CandidateType;
  vacancyId?: VacancyType["id"];
  mode?: RecommendedModeType;
};

export const Recommended: React.FC<Props> = ({
  candidate,
  vacancyId,
  mode = "recommended",
}) => {
  const dispatch = useAppDispatch();
  const { loading, error } = useAppSelector(selectVacancySuitableList);
  const recommendedVacancies = useAppSelector(selectFilteredVacancySuitable);
  const meta = useAppSelector(selectVacancySuitableListMeta);

  const getVacancySuitableList = useCallback(
    (payload: QueryVacancySuitableListArgs) => {
      dispatch(fetchVacancySuitableList(payload));
    },
    [dispatch]
  );

  useEffect(() => {
    getVacancySuitableList({
      filter: { candidateId: candidate.id },
      pagination: PAGINATION.RECOMMENDED,
    });
  }, [dispatch, candidate.id]);

  return (
    <div className="recommended-container">
      {recommendedVacancies && !loading ? (
        <>
          {mode !== "recommended" && <Title type="h4">Анкета кандидата</Title>}
          <CandidatesTable
            mode={mode}
            vacancyId={vacancyId}
            className="recommended-container-candidate"
            candidatesList={[candidate]}
          />

          <div className="recommended-container-maps">
            {mode !== "claim" && (
              <RecommendMaps
                recommendedVacancies={recommendedVacancies}
                vacancyId={vacancyId}
              />
            )}
          </div>
          <div className="recommended-container-table">
            {renderTitle(mode)}
            <RecommendTable
              recommendedVacancies={recommendedVacancies}
              vacancyId={vacancyId}
            />
            <Pagination
              className="recommended-container-pagination"
              total={meta?.total}
              limit={meta?.limit}
              offset={meta?.offset}
              onChange={(pagination) =>
                getVacancySuitableList({
                  filter: { candidateId: candidate.id },
                  pagination,
                })
              }
            />
          </div>
        </>
      ) : (
        <NotificationStatus
          loading={loading}
          error={error}
          notFoundText="Вакансии не найдены"
        />
      )}
    </div>
  );
};
