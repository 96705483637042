import { createSelector } from "reselect";
import { RootState } from "../../rootReducer";

export const selectChats = (state: RootState) => state.chats.chat.chats;

export const selectChatsById = createSelector(
  selectChats,
  (state: RootState, props: any) => props.id,
  (chats, replyId: number) => chats[replyId] || null
);

export const selectMessages = (state: RootState) =>
  state.chats.message.messages;

export const selectNotification = (state: RootState) =>
  state.chats.notifications.data;

export const selectFiles = (state: RootState) => state.chats.file.fileURL;
