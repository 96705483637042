import { useCallback, useEffect, useState, useLayoutEffect } from "react";
import { useAppDispatch, useAppSelector } from "services/store/store";
import { wsClient } from "graphql/apollo";
import {
  callsSocket,
  getOktellStatus,
} from "services/store/modules/sockets/actions";
import { isEnvironment } from "common/utils";
import { statusSocketSelector } from "services/store/modules/sockets/selectors";
import {
  setStatusSocket,
  setStatusWorker,
} from "services/store/modules/sockets";
import { sendLog } from "services/store/modules/auth";
import { LOG_LEVEL } from "services/logger/log";

export const useOktell = () => {
  const dispatch = useAppDispatch();

  const statusSocket = useAppSelector(statusSocketSelector);

  const [statusWorkerIntermediate, setStatusWorkerIntermediate] =
    useState("Оффлайн");

  if ("serviceWorker" in navigator) {
    const appUrl = process.env.REACT_APP_APP_URL || "";
    window.addEventListener("load", () => {
      navigator.serviceWorker
        .register(`${appUrl}/worker.js`)
        .then((registration) => {
          const statusWorker =
            registration?.active?.state === "activated" ? "Онлайн" : "Оффлайн";
          setStatusWorkerIntermediate(statusWorker || "Оффлайн");
          dispatch(setStatusWorker(statusWorker || "Оффлайн"));
          if (statusWorker === "Онлайн") {
            oktellStatusCheck();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
    navigator.serviceWorker.addEventListener("message", (event) => {
      // Проверка статуса Service worker
      if (
        statusWorkerIntermediate !== (event?.data?.statusWorker || "Оффлайн")
      ) {
        setStatusWorkerIntermediate(event?.data?.statusWorker || "Оффлайн");
        dispatch(setStatusWorker(event?.data?.statusWorker || "Оффлайн"));
      }
    });
  } else {
    console.log("Service Worker is not supported by browser.");
  }

  // Проверка статуса Oktell
  const oktellStatusCheck = useCallback(() => {
    dispatch(getOktellStatus());
  }, [dispatch]);

  const loadWebSocketCalls = useCallback(async () => {
    if (statusSocket === "Оффлайн") {
      await dispatch(callsSocket());
    }
  }, [dispatch, statusSocket]);

  useEffect(() => {
    setInterval(() => {
      if (statusWorkerIntermediate === "Онлайн") {
        oktellStatusCheck();
      }
    }, 30000);
  }, [oktellStatusCheck, statusWorkerIntermediate]);

  useLayoutEffect(() => {
    if (isEnvironment("production")) {
      loadWebSocketCalls();
    }
  }, [loadWebSocketCalls, statusSocket]);

  useLayoutEffect(() => {
    if (statusSocket === "Оффлайн") {
      wsClient.onConnected(() => {
        const logData = {
          level: LOG_LEVEL.DEBUG,
          message: `WebSocket onConnected`,
          component: "MainLayout",
        };
        dispatch(sendLog(logData));
        console.log("WebSocket onConnected");
        dispatch(setStatusSocket("Онлайн"));
      });
    } else {
      wsClient.onDisconnected(() => {
        const logData = {
          level: LOG_LEVEL.DEBUG,
          message: `WebSocket onDisconnected`,
          component: "MainLayout",
        };
        dispatch(sendLog(logData));
        console.log("WebSocket onDisconnected");
        dispatch(setStatusSocket("Оффлайн"));
      });
    }
    wsClient.onReconnected(() => {
      const logData = {
        level: LOG_LEVEL.DEBUG,
        message: `WebSocket onReconnected`,
        component: "MainLayout",
      };
      dispatch(sendLog(logData));
      console.log("WebSocket reconnected!");
      dispatch(setStatusSocket("Онлайн"));
    });
  }, [dispatch, statusSocket]);
};
