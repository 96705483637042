import React, { useState } from "react";
import classNames from "classnames/dedupe";
import { cn } from "helpers/bem";
import "./styles.scss";
import { TagColorType } from "types/TagColorType";
import { getTagTextColor } from "./helpers/getTagTextColor";
import { getTagBgColor } from "./helpers/getTagBgColor";

const b = cn("app-tag");

interface Props {
  className?: string;
  text: string;
  color?: TagColorType;
  tooltipText?: string;
}

export const AppTag: React.FC<Props> = (props) => {
  const { className, text, color = TagColorType.GRAY, tooltipText } = props;
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  return (
    <div
      className={classNames(
        b(),
        `relative inline-flex items-center justify-center rounded-full px-2 py-1 text-xs font-medium cursor-pointer`,
        getTagBgColor(color),
        getTagTextColor(color),
        className
      )}
      onMouseEnter={() => {
        setIsTooltipVisible(true);
      }}
      onMouseLeave={() => {
        setIsTooltipVisible(false);
      }}
    >
      {text}
      {isTooltipVisible && tooltipText && (
        <div className="absolute z-10 -top-10 left-1/2 transform -translate-x-1/2 bg-white border border-gray-300 shadow-lg p-2 rounded-md">
          {tooltipText}
        </div>
      )}
    </div>
  );
};
