import React, { FC, ReactNode } from "react";
import classNames from "classnames/dedupe";
import { cn } from "helpers/bem";
import "./styles.scss";

const b = cn("icon-button");

interface Props {
  className?: string;
  onClick?: () => void;
  icon: ReactNode;
  isActive?: boolean;
  iconType?: "stroke" | "fill";
}

export const IconButton: FC<Props> = (props) => {
  const {
    className,
    onClick,
    icon,
    iconType = "fill",
    isActive = true,
  } = props;

  return (
    <div
      className={classNames(
        b(),
        className,
        b({
          active: isActive,
          stroke: iconType === "stroke",
        })
      )}
      onClick={onClick}
    >
      {icon}
    </div>
  );
};
