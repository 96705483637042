import React, { useMemo } from "react";
import cn from "classnames/dedupe";

import { CandidateType, ContactType } from "graphql/types/types";
import { Button } from "components/UI/Button";
import { PhoneCall } from "components/UI/icons";
import { useAppDispatch, useAppSelector } from "services/store/store";
import {
  selectIsActiveCall,
  selectOktellStatus,
} from "services/store/modules/sockets/selectors";
import toaster from "components/UI/Notifications/Notification";
import { setCurrentCallCandidate } from "services/store/modules/calls";
import { callOktell } from "services/store/modules/sockets/actions";
import { setupCallCandidate } from "services/store/modules/candidates/action";
import { companySettings } from "services/store/modules/company";
import { isCallTimeValid } from "common/helpers/getDateTimezone";
import { Info } from "components/UI/icons/Info";
import { Tooltip } from "components/UI/Tooltip";

import "./styles.scss";

interface Props {
  candidate: CandidateType;
  vacancyId: number;
  contact: ContactType["contact"];
  className?: string;
}
export const CallButton: React.FC<Props> = ({
  contact,
  candidate,
  vacancyId,
  className,
}) => {
  const dispatch = useAppDispatch();
  const company = useAppSelector(companySettings);
  const isOktellAccess = useAppSelector(selectOktellStatus);
  const isActiveCall = useAppSelector(selectIsActiveCall);
  const timezone = candidate?.timezone ?? "";
  const callTimeStart = company?.callTimeStart ?? "";
  const callTimeEnd = company?.callTimeEnd ?? "";
  const isCallButtonEnabled = useMemo(() => {
    if (!timezone) {
      return true;
    }
    return isCallTimeValid(timezone, callTimeStart, callTimeEnd);
  }, [timezone, callTimeStart, callTimeEnd]);

  const canCall = isOktellAccess !== "Оффлайн";

  const onCallOktell = async () => {
    if (isActiveCall) {
      toaster.warning({ title: "the call is already in progress" });
      return;
    }

    if (contact) {
      await dispatch(setupCallCandidate({ id: candidate.id, vacancyId }));
      dispatch(setCurrentCallCandidate(candidate));
      dispatch(callOktell(contact));
    }
  };

  const handleClickOktell = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    if (!canCall) {
      return;
    }

    onCallOktell();
  };

  return (
    <Button
      icon={
        isCallButtonEnabled ? (
          <PhoneCall />
        ) : (
          <Tooltip
            placement="bottomRight"
            title={`Звонок кандидату доступен с ${callTimeStart} до ${callTimeEnd} по его часовому поясу`}
          >
            <span className="tooltip-icon-wrapper">
              <Info color="#0057AC" height={20} width={20} />
            </span>
          </Tooltip>
        )
      }
      className={cn(className)}
      onClick={handleClickOktell}
      disabled={!isCallButtonEnabled}
    >
      Позвонить
    </Button>
  );
};
