import { gql } from "@apollo/client";

export const USER_LIST = gql`
  query userList($filter: UserFilterType, $pagination: PaginationType) {
    userList(filter: $filter, pagination: $pagination) {
      ... on UserListSuccess {
        data {
          createdAt
          city
          companyId
          departmentId
          email
          firstName
          deletedAt
          id
          idpId
          phone
          role
          secondName
          updatedAt
          department {
            id
            name
            ownerId
            companyId
            owner {
              companyId
              id
              secondName
              firstName
            }
          }
          company {
            id
            name
          }
          tz
        }
        meta {
          limit
          offset
          total
        }
      }
      ... on Error {
        errors
        statusCode
        message
      }
    }
  }
`;
