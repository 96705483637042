import React, { useRef } from "react";
import cn from "classnames/dedupe";

import { Pagination, PaginationPayload } from "components/UI/Pagination";
import { PageInfoType, VacancyStatus } from "graphql/types/types";
import { Table, Props as TableProps } from "components/UI/Table";
import { Manager, Report } from "common/commonTypes/reports";
import { FiltersState } from "../FiltersModal";
import { VACANCY_STATUS_TYPE } from "common/const/status";
import { COLOR_BY_VACANCY_STATUS } from "common/const/colors";
import useDraggableScroll from "common/hooks/useDraggableScroll";
import { getDateFormat } from "common/utils/date";

import "./styles.scss";

type Props = {
  reportList: Report[];
  meta: PageInfoType | null;
  formData: FiltersState;
  onChangePagination: (
    filter: FiltersState,
    pagination?: PaginationPayload
  ) => void;
};
export const ReportTable: React.FC<Props> = ({
  reportList,
  meta,
  formData,
  onChangePagination,
}) => {
  const tableRef = useRef<HTMLDivElement>(null);
  const { onMouseDown } = useDraggableScroll(tableRef);

  const columns: TableProps<Report>["columns"] = [
    {
      title: "Департамент",
      dataIndex: "department",
      key: "department",
      render: (department) => {
        const departmentName = department?.name ?? "";
        return (
          <div className="nowrap" title={departmentName}>
            {departmentName}
          </div>
        );
      },
    },
    {
      title: "ФИО менеджера",
      dataIndex: "manager",
      key: "manager",
      render: (manager: Manager) => {
        const { firstName = "", secondName = "" } = manager ?? {};
        const name = `${firstName} ${secondName}`;
        return (
          <div className="report-table-column-name" title={name}>
            {name}
          </div>
        );
      },
    },
    {
      title: "ID вакансии",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Вакансия",
      dataIndex: "name",
      key: "name",
      render: (name) => (
        <div className="nowrap" title={name}>
          {name}
        </div>
      ),
    },
    {
      title: "Проект",
      dataIndex: "callCenterInfo",
      key: "callCenterInfo",
      render: (callCenterInfo) => {
        const { project = "" } = callCenterInfo || {};
        return (
          <div
            className={cn("nowrap", "report-table-column-info")}
            title={project}
          >
            {project}
          </div>
        );
      },
    },
    {
      title: "Адрес",
      dataIndex: "address",
      key: "address",
      render: (address) => (
        <div
          className={cn("nowrap", "report-table-column-address")}
          title={address?.value}
        >
          {address?.value ?? ""}
        </div>
      ),
    },
    {
      title: "Потребность",
      dataIndex: "peopleCount",
      key: "peopleCount",
    },
    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
      render: (status: VacancyStatus) => (
        <div
          className="nowrap"
          title={VACANCY_STATUS_TYPE[status]}
          style={{ color: COLOR_BY_VACANCY_STATUS[status] }}
        >
          {VACANCY_STATUS_TYPE[status]}
        </div>
      ),
    },
    {
      title: "Дата и время взятия в работу",
      dataIndex: "dateStartWork",
      key: "dateStartWork",
      render: (dateStartWork) =>
        dateStartWork ? getDateFormat(dateStartWork, "DD.MM.YY HH:mm") : null,
    },
    {
      title: "Дата и время окончания работы с вакансией",
      dataIndex: "dateEndWork",
      key: "dateEndWork",
      render: (dateEndWork) =>
        dateEndWork ? getDateFormat(dateEndWork, "DD.MM.YY HH:mm") : null,
    },
    {
      title: "Дата и время открытия вакансии без обработки",
      dataIndex: "managerStartWork",
      key: "managerStartWork",
      render: (managerStartWork) =>
        managerStartWork
          ? getDateFormat(managerStartWork, "DD.MM.YY HH:mm")
          : null,
    },
    {
      title: "Длительность вакансии в работе",
      dataIndex: "dateRangeWork",
      key: "dateRangeWork",
      render: (dateRangeWork) => (dateRangeWork ? `${dateRangeWork} ч.` : null),
    },
    {
      title: "Подобрано кандидатов системой",
      dataIndex: "cntCandidatesFind",
      key: "cntCandidatesFind",
    },
    {
      title: "Обзвон кандидатов количество",
      dataIndex: "cntCandidatesCalls",
      key: "cntCandidatesCalls",
    },
    {
      title: "Направлено кандидатов",
      dataIndex: "cntCandidatesDirected",
      key: "cntCandidatesDirected",
    },
    {
      title: "ФИО направленных кандидатов",
      dataIndex: "fioCandidatesDirected",
      key: "fioCandidatesDirected",
    },
    {
      title: "Размещена Rabotut.ru",
      dataIndex: "extraRbt",
      key: "extraRbt",
      render: (extraRbt) =>
        extraRbt.remoteUrl ? (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={extraRbt.remoteUrl}
          >
            Ссылка
          </a>
        ) : null,
    },
    {
      title: "Размещена Авито",
      dataIndex: "extraAvito",
      key: "extraAvito",
      render: (extraAvito) =>
        extraAvito.remoteUrl ? (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={extraAvito.remoteUrl}
          >
            Ссылка
          </a>
        ) : null,
    },
    {
      title: "Размещена HH.ru",
      dataIndex: "extraHH",
      key: "extraHH",
      render: (extraHH) =>
        extraHH.remoteUrl ? (
          <a target="_blank" rel="noopener noreferrer" href={extraHH.remoteUrl}>
            Ссылка
          </a>
        ) : null,
    },
  ];

  return (
    <div className="report-table">
      <div
        className="report-table-container"
        ref={tableRef}
        onMouseDown={onMouseDown}
      >
        <Table tableLayout="auto" dataSource={reportList} columns={columns} />
      </div>
      <Pagination
        className="candidates-container-pagination"
        total={meta?.total}
        limit={meta?.limit}
        offset={meta?.offset}
        onChange={(pagination) => onChangePagination(formData, pagination)}
      />
    </div>
  );
};
