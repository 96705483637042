import React from "react";
import { Form } from "antd";
import { InputMask, InputMaskProps } from "components/UI/InputMask";
import classNames from "classnames";
import "./styles.scss";

interface FormInputMaskProps extends InputMaskProps {
  label: string;
  error?: string;
  mask: InputMaskProps["mask"];
}
export const FormInputMask: React.FC<FormInputMaskProps> = ({
  className,
  required,
  label,
  error,
  ...props
}) => {
  const formPhoneInputClassName = classNames(
    "ui-form--input-mask",
    { "ui-form--input-mask--required": required },
    className
  );

  return (
    <Form.Item
      label={label}
      className={formPhoneInputClassName}
      rules={[{ required, message: `${label}` }]}
      required={required}
    >
      <InputMask {...props} />
      {error && <div className="ui-form--input-mask-error">{error}</div>}
    </Form.Item>
  );
};
