import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { ContentLayout } from "layouts/ContentLayout";
import { Recommended } from "components/Recommended";
import { RouteParams } from "common/commonTypes/types";
import { fetchCandidateById } from "services/store/modules/candidates/action";
import { useAppDispatch, useAppSelector } from "services/store/store";
import { selectCandidate } from "services/store/modules/candidates/selectors";
import { NotificationStatus } from "components/UI/Notifications/Notification";

export const RecommendedPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams<RouteParams>();

  const { candidate, loading, error } = useAppSelector(selectCandidate);

  useEffect(() => {
    dispatch(fetchCandidateById(id));
  }, [id]);

  return (
    <ContentLayout
      centralColumn={{
        content: (
          <>
            {candidate ? (
              <Recommended candidate={candidate} />
            ) : (
              <NotificationStatus
                loading={loading}
                error={error}
                notFoundText="Ошибка загрузки кандидата"
              />
            )}
          </>
        ),
      }}
    />
  );
};
