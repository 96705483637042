import React, { useMemo, useEffect, useState, useCallback } from "react";
import { useForm, Controller, SubmitHandler, useWatch } from "react-hook-form";
import { DaDataAddressSuggestion } from "react-dadata";
import { yupResolver } from "@hookform/resolvers/yup";
import { useHistory } from "react-router";

import { IAvitoVacInfo } from "components/Vacancy/types";
import { VacancyStatus, VacancyType } from "graphql/types/types";
import { Notification } from "components/UI/Notifications/Notification";
import { getRootProfile } from "api/avito";
import { Title } from "components/UI/Title";
import FormText from "components/UI/Form/FormText";
import {
  AvitoBusinessAreaList,
  AvitoExperienceList,
  PublicationStatus,
  ROOT_ERROR_STATUS,
  publicationStatuses,
} from "common/const/avito";
import { FormInput } from "components/UI/Form/FormInput";
import { FormSelect } from "components/UI/Form/FormSelect";
import { publishVacancy, getInfo } from "api/avito";
import { Button } from "components/UI/Button";
import {
  EMPTY_PUBLICATION,
  getContacts,
  getIsDisabledStatuses,
} from "../helpers";
import { Editor } from "components/UI/Editor";
import { VACANCY_CHARACTER_LIMITS } from "common/const/characterLimits";
import RowLayout from "layouts/RowLayout";
import Input from "components/UI/Input";
import { FormSelectDadata } from "components/UI/Form/FormSelectDadata";
import { PublicationStatusModal } from "../PublicationStatusModal";
import {
  selectEmploymentAvito,
  selectProfessions,
  selectRegistrationMethodAvito,
  selectBonusesAvito,
} from "services/store/modules/directory";
import { useAppSelector } from "services/store/store";
import { filterOption } from "components/UI/Select/helpers";
import { FormCheckbox } from "components/UI/Form/FormCheckbox";
import { BOOLEAN_OPTIONS } from "common/const/options";
import { Platform } from "common/const/platform";
import { maskPhone } from "common/utils";
import { authInfo } from "services/store/modules/auth";
import { schema } from "./validation";
import { getInitialState, getPayload } from "./helpers";
import { IUser } from "services/store/modules/user/types";

import "./styles.scss";

interface Props {
  vacancy: VacancyType;
  managerList: IUser[];
  changeTab: (key: Platform) => void;
}

export type AvitoState = {
  name: string;
  description: string;
  shortDescription: string;
  terms: string;
  claims: string;
  platformTags: string;
  businessArea: number;
  experience: string;
  salaryFrom: number;
  salaryTo: number;
  managerId: number;
  address: DaDataAddressSuggestion;
  schedule: string;
  profession: string;
  registrationMethod: string[];
  bonuses: string[];
  isRemote?: string;
  isSideJob?: string;
};

export const AvitoForm: React.FC<Props> = ({ vacancy, managerList }) => {
  const history = useHistory();

  const employmentAvitoOptions = useAppSelector(selectEmploymentAvito);
  const professionsOptions = useAppSelector(selectProfessions);
  const registrationMethodAvitoOptions = useAppSelector(
    selectRegistrationMethodAvito
  );
  const bonusesAvitoOptions = useAppSelector(selectBonusesAvito);
  const userData = useAppSelector(authInfo);

  const [avitoInfo, setAvitoInfo] = useState<IAvitoVacInfo | null>(null);
  const [rootPublicationStatus, setRootPublicationStatus] = useState<
    string | null
  >(null);
  const [loading, setLoading] = useState(false);
  const [publication, setPublication] = useState(EMPTY_PUBLICATION);

  const initialState = useMemo<AvitoState>(
    () => getInitialState(vacancy),
    [vacancy]
  );

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<AvitoState>({
    // @ts-expect-error
    resolver: yupResolver(schema),
    defaultValues: initialState,
  });

  const managerId = useWatch({
    control,
    name: "managerId",
  });

  const { phone, email, fullName } = useMemo(
    () => getContacts(managerId, managerList),
    [managerId, managerList]
  );

  const statusMessage = useMemo(() => {
    const { publication_status: publicationStatus } = avitoInfo || {};
    const status = publicationStatus
      ? publicationStatuses(true, window.location.host, fullName)[
          publicationStatus
        ]
      : undefined;

    return status ? <div dangerouslySetInnerHTML={{ __html: status }} /> : null;
  }, [avitoInfo, fullName]);

  const onPublish: SubmitHandler<AvitoState> = useCallback(
    async (formData) => {
      setLoading(true);
      const payload = getPayload(formData, managerList);
      try {
        const { data } = await publishVacancy<IAvitoVacInfo>(
          vacancy.id,
          managerId,
          userData?.id ?? 0,
          payload
        );

        setAvitoInfo(data);
        setPublication({
          status: "success",
          message: "Вакансия успешно опубликована",
        });
      } catch (e) {
        setPublication({
          status: "error",
          message: e?.response?.data?.detail || "Ошибка публикации",
        });
      } finally {
        setLoading(false);
      }
    },
    [managerId, vacancy]
  );
  const handleClosePublishModal = () => {
    setPublication((prev) => ({ ...prev, message: null }));
    history.goBack();
  };

  useEffect(() => {
    Promise.all([
      getInfo<IAvitoVacInfo>(vacancy.id, managerId)
        .then(({ data }) => setAvitoInfo(data))
        .catch((e) => {
          if (
            e?.response?.status === 404 &&
            e.response.data?.detail === "User not found"
          ) {
            setAvitoInfo({
              ...avitoInfo,
              is_active: false,
              is_published: false,
              publication_status: PublicationStatus.NotAuthorized,
            });

            return;
          }

          setAvitoInfo(null);
        }),
      getRootProfile(managerId)
        .then(() => setRootPublicationStatus(null))
        .catch((e) => {
          if (e?.response?.status === 404) {
            setRootPublicationStatus(ROOT_ERROR_STATUS);
          }
        }),
    ]);
  }, [managerId]);

  useEffect(() => {
    reset(initialState);
  }, [initialState, reset]);

  return (
    <div className="avito-form">
      <Title type="h2">Опубликовать вакансию</Title>
      {statusMessage && (
        <Notification
          type={
            avitoInfo?.publication_status === PublicationStatus.Active
              ? "success"
              : "error"
          }
          title={statusMessage}
          className="hh-form-notification"
        />
      )}
      {rootPublicationStatus && (
        <Notification
          type="error"
          title={rootPublicationStatus}
          className="hh-form-notification"
        />
      )}

      {vacancy.status !== VacancyStatus.Published && (
        <Notification
          type="error"
          title='Для публикации на Авито, вакансия должна быть в статусе "Опубликовано"'
          className="hh-form-notification"
        />
      )}

      <Title type="h3" color="blue">
        Описание
      </Title>

      <Controller
        name="name"
        control={control}
        rules={{ required: true }}
        render={({ field, fieldState: { error } }) => (
          <FormInput
            disabled={true}
            error={error?.message}
            label="Название обьявления"
            required={true}
            {...field}
          />
        )}
      />

      <Controller
        name="profession"
        control={control}
        render={({ field }) => (
          <FormSelect
            label="Профессия"
            showSearch
            placeholder="Укажите профессию"
            optionFilterProp="children"
            filterOption={filterOption}
            options={professionsOptions.map(({ id, name }) => ({
              label: name,
              value: id,
            }))}
            {...field}
          />
        )}
      />

      <Controller
        name="shortDescription"
        control={control}
        render={({ field }) => (
          <Editor
            containerClassName="avito-form-desc"
            disabled={true}
            label="Описание вакансии"
            count={VACANCY_CHARACTER_LIMITS.SHORT_DESCRIPTION}
            {...field}
          />
        )}
      />

      <Controller
        name="description"
        control={control}
        render={({ field }) => (
          <Editor
            containerClassName="avito-form-desc"
            disabled={true}
            label="Обязанности"
            count={VACANCY_CHARACTER_LIMITS.DESCRIPTION}
            {...field}
          />
        )}
      />

      <Controller
        name="terms"
        control={control}
        render={({ field }) => (
          <Editor
            containerClassName="avito-form-desc"
            disabled={true}
            label="Условия"
            count={VACANCY_CHARACTER_LIMITS.TERMS}
            {...field}
          />
        )}
      />

      <Controller
        name="claims"
        control={control}
        render={({ field }) => (
          <Editor
            containerClassName="avito-form-desc"
            disabled={true}
            label="Требования"
            count={VACANCY_CHARACTER_LIMITS.CLAIMS}
            {...field}
          />
        )}
      />

      <Controller
        name="platformTags"
        control={control}
        render={({ field }) => (
          <Editor
            label="Ключевые слова"
            disabled={true}
            containerClassName="avito-form-desc"
            count={VACANCY_CHARACTER_LIMITS.PLATFORM_TAGS}
            {...field}
          />
        )}
      />

      <Title type="h3" color="blue">
        Условия для кандидата
      </Title>

      <Controller
        name="businessArea"
        control={control}
        rules={{ required: true }}
        render={({ field, fieldState: { error } }) => (
          <FormSelect
            label="Сфера деятельности"
            required={true}
            error={error?.message}
            options={AvitoBusinessAreaList.map(({ id, name }) => ({
              label: name,
              value: id,
            }))}
            {...field}
          />
        )}
      />

      <Controller
        name="experience"
        control={control}
        rules={{ required: true }}
        render={({ field, fieldState: { error } }) => (
          <FormSelect
            label="Опыт работы"
            required={true}
            error={error?.message}
            options={AvitoExperienceList.map(({ key, name }) => ({
              label: name,
              value: key,
            }))}
            {...field}
          />
        )}
      />

      <Controller
        name="schedule"
        control={control}
        rules={{ required: true }}
        render={({ field, fieldState: { error } }) => (
          <FormSelect
            label="График работы"
            placeholder="Укажите график работы"
            required={true}
            error={error?.message}
            options={employmentAvitoOptions.map(({ key, name }) => ({
              label: name,
              value: key,
            }))}
            {...field}
          />
        )}
      />

      <FormInput
        label="Работодатель"
        disabled={true}
        value={vacancy.employer?.name ?? ""}
      />

      <Controller
        name="registrationMethod"
        control={control}
        render={({ field }) => (
          <FormCheckbox
            label="Способ оформления"
            className="avito-form-checkbox"
            options={registrationMethodAvitoOptions.map(({ key, name }) => ({
              label: name,
              value: key,
            }))}
            {...field}
          />
        )}
      />

      <Controller
        name="isRemote"
        control={control}
        render={({ field }) => (
          <FormSelect
            label="Работа из дома"
            options={BOOLEAN_OPTIONS}
            {...field}
          />
        )}
      />

      <Controller
        name="isSideJob"
        control={control}
        render={({ field }) => (
          <FormSelect label="Подработка" options={BOOLEAN_OPTIONS} {...field} />
        )}
      />

      <RowLayout
        style={{ display: "inlineBlock", alignItems: "center" }}
        leftComponent={
          <Controller
            name="salaryFrom"
            control={control}
            render={({ field }) => (
              <FormInput
                label="Предполагаемый уровень дохода в месяц"
                {...field}
              />
            )}
          />
        }
        leftSpan={12}
        rightComponent={
          <Controller
            name="salaryTo"
            control={control}
            render={({ field }) => <Input {...field} />}
          />
        }
        rightSpan={12}
      />

      <Controller
        name="bonuses"
        control={control}
        render={({ field }) => (
          <FormCheckbox
            label="Что получают работники"
            className="avito-form-checkbox"
            options={bonusesAvitoOptions.map(({ key, name }) => ({
              label: name,
              value: key,
            }))}
            {...field}
          />
        )}
      />

      <Title type="h3" color="blue">
        Место работы
      </Title>

      <Controller
        name="address"
        control={control}
        render={({ field }) => (
          <FormSelectDadata label="Где будет работать сотрудник" {...field} />
        )}
      />

      <Title type="h3" color="blue">
        Контакты
      </Title>

      <FormText label="Телефон" text={maskPhone(phone)} />
      <FormText label="Адрес электронной почты" text={email} />

      <Controller
        name="managerId"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <FormSelect
            label="Менеджер вакансии"
            showSearch
            required={true}
            optionFilterProp="children"
            filterOption={filterOption}
            options={managerList?.map(({ fullName, id }) => ({
              label: fullName,
              value: id,
            }))}
            {...field}
          />
        )}
      />

      <Button
        loading={loading}
        disabled={
          Boolean(Object.values(errors).length) ||
          getIsDisabledStatuses(avitoInfo)
        }
        onClick={handleSubmit(onPublish)}
      >
        Опубликовать
      </Button>

      <PublicationStatusModal
        isVisible={Boolean(publication.message)}
        onClose={handleClosePublishModal}
        publication={publication}
        jobBoard="avito"
      />
    </div>
  );
};
