import { createSelector } from "reselect";
import { RootState } from "../../rootReducer";

export const selectVacancy = (state: RootState) => state.vacancy;

export const selectVacancyData = createSelector(
  selectVacancy,
  (state) => state.data
);

export const selectVacancyLoading = createSelector(
  selectVacancy,
  (state) => state.loading
);
