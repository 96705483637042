import { gql } from "@apollo/client";

export const CANDIDATES_LIST_BY_VACANCY = gql`
  query candidateListByVacancy(
    $filter: CandidateFilterType
    $pagination: PaginationType
    $vacancyId: Int!
  ) {
    candidateListByVacancy(
      filter: $filter
      vacancyId: $vacancyId
      pagination: $pagination
    ) {
      ... on CandidateListSuccess {
        data {
          id
          age
          citizenshipId
          city
          companyId
          createdAt
          deletedAt
          updatedAt
          departmentId
          email
          fullName
          hasHistory
          hasChat
          inBlacklist
          isSpam
          russianCitizenship
          source
          managerComment
          managerId
          timezone
          ownerId
          phone
          positions {
            key
          }
          positionsNew {
            createdAt
            id
            position {
              categoryId
              id
              key
              name
              restriction
            }
          }
          region
          status
        }
        meta {
          limit
          offset
          total
        }
      }
      ... on Error {
        errors
        statusCode
        message
      }
    }
  }
`;
