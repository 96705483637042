import React, { useState } from "react";
import { useSelector } from "react-redux";
import { AppSelect, TWSelectOption } from "../../UI/AppSelect/AppSelect";
import { AppInput } from "../../UI/AppInput/AppInput";
import {
  selectCandidate,
  selectCandidateStatuses,
  selectCandidateStatusesByRole,
} from "../../../services/store/modules/candidates/selectors";
import { cn } from "helpers/bem";
import { useAppDispatch } from "../../../services/store/store";
import { changeCandidateStatus } from "services/store/modules/candidates/action";
import getColorByStatus from "../../UI/AppSelect/helpers/getColorByStatus";
import { authInfo } from "../../../services/store/modules/auth";

const b = cn("candidates-page");

const MemoizedAppInput = React.memo(AppInput);

const CandidateStatusSwitcher = () => {
  const authData = useSelector(authInfo);
  const { candidate } = useSelector(selectCandidate);
  const role = authData?.role || "ADMIN";
  const statusState = useSelector(selectCandidateStatuses);
  const selectStatus = useSelector(selectCandidateStatusesByRole(role));
  const [comment, setComment] = useState<string>(
    candidate?.managerComment ?? ""
  );
  const [isEditingComment, setIsEditingComment] = useState(false);

  const objStatus = statusState?.find((el) => el.key === candidate.status);
  const [status, setStatus] = useState<TWSelectOption | null>(
    objStatus || null
  );
  const dispatch = useAppDispatch();
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const isFinalStatus = (statusKey) => {
    if (role === "EMPLOYEE_CC" || role === "MANAGER") {
      const finalStatuses = [
        "NOT_THROUGH",
        "RESERVE",
        "CANDIDATE",
        "DENIED",
        "REFUSAL_CANDIDATE",
      ];
      return finalStatuses.includes(statusKey);
    } else {
      return false;
    }
  };

  const eventMouseEnter = () => {
    setIsTooltipVisible(true);
  };

  const eventMouseLeave = () => {
    setIsTooltipVisible(false);
  };

  const handlerChangeStatus = () => {
    if (status && candidate?.id) {
      setStatus(status);
      dispatch(
        changeCandidateStatus({
          id: candidate.id,
          //@ts-ignore
          status: status.key,
          comment: comment,
        })
      );
    }
  };

  return (
    <div className="p-4 ">
      <div className="flex space-x-4 items-center">
        <AppSelect
          className={b("select")}
          label="Статус"
          value={status}
          onChange={(e) => {
            setStatus(e);
            setIsEditingComment(true);
            setComment("");
          }}
          options={selectStatus as TWSelectOption[]}
          color={getColorByStatus(status?.key)}
          disabled={isFinalStatus(status?.key)}
        />

        {isEditingComment ? (
          <>
            <MemoizedAppInput
              value={comment}
              label="Комментарий к статусу"
              id={candidate?.id}
              onChange={(e) => {
                if (e.currentTarget.value.length <= 255) {
                  setComment(e.currentTarget.value);
                }
              }}
              count={255}
            />
            <button
              type="button"
              className={`rounded-md mt-8 px-3 py-2 text-sm font-semibold text-white shadow-sm bg-indigo-500 hover:bg-indigo-400"
                  }`}
              style={{ flexShrink: 0 }}
              onClick={handlerChangeStatus}
            >
              Сохранить
            </button>
          </>
        ) : (
          <div className="relative">
            <label className="block text-sm text-gray-700">
              Комментарий к статусу
            </label>
            <div
              className="mt-3 p-2 max-h-[36px] w-full border rounded-md bg-gray-100 relative"
              onMouseEnter={eventMouseEnter}
              onMouseLeave={eventMouseLeave}
            >
              {comment.length > 55
                ? `${comment.slice(0, 55)}...`
                : comment || "Не указан"}
              {isTooltipVisible && comment.length > 55 && (
                <div className="absolute z-10 top-10 left-60 w-full transform -translate-x-1/2 bg-white border border-gray-300 shadow-lg p-2 rounded-md">
                  {comment}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CandidateStatusSwitcher;
