import React from "react";
import { ContentLayout } from "layouts/ContentLayout";
import { Candidate } from "components/redezign/Candidate";
import { CandidateActions } from "components/redezign/Candidate/components/CandidateActions";

export const CandidatePage: React.FC = () => {
  return (
    <ContentLayout
      centralColumn={{content: <Candidate />, width: 18}}
      rightColumn={{ width: 6, content: <CandidateActions /> }}
    />
  );
};
