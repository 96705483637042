import React from "react";

type Props = {
  width?: number;
  height?: number;
  color?: string;
};

export const FileTextOutlined: React.FC<Props> = ({
  width = 24,
  height = 24,
  color = "#A6ACB9",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.25 13.5H1.5H2.25ZM2.25 4.5H3H2.25ZM5.25 1.5V0.75V1.5ZM12.75 1.5V0.75V1.5ZM15.75 4.5H15H15.75ZM15.75 9.25736H16.5H15.75ZM8.50736 16.5V15.75V16.5ZM5.25 16.5V17.25V16.5ZM14.8713 11.3787L14.341 10.8483L14.8713 11.3787ZM10.6287 15.6213L11.159 16.1516L10.6287 15.6213ZM9.75 13.5H9H9.75ZM12.75 10.5V9.75V9.75V10.5ZM6 4.5C5.58579 4.5 5.25 4.83579 5.25 5.25C5.25 5.66421 5.58579 6 6 6V4.5ZM12 6C12.4142 6 12.75 5.66421 12.75 5.25C12.75 4.83579 12.4142 4.5 12 4.5V6ZM6 8.25C5.58579 8.25 5.25 8.58579 5.25 9C5.25 9.41421 5.58579 9.75 6 9.75V8.25ZM9 9.75C9.41421 9.75 9.75 9.41421 9.75 9C9.75 8.58579 9.41421 8.25 9 8.25V9.75ZM3 13.5L3 4.5L1.5 4.5L1.5 13.5H3ZM5.25 2.25L12.75 2.25V0.75L5.25 0.75L5.25 2.25ZM15 4.5V9.25736H16.5V4.5H15ZM8.50736 15.75H5.25V17.25H8.50736V15.75ZM14.341 10.8483L10.0983 15.091L11.159 16.1516L15.4016 11.909L14.341 10.8483ZM8.50736 17.25C9.50192 17.25 10.4557 16.8549 11.159 16.1516L10.0983 15.091C9.67639 15.5129 9.1041 15.75 8.50736 15.75V17.25ZM15 9.25736C15 9.85409 14.7629 10.4264 14.341 10.8483L15.4016 11.909C16.1049 11.2057 16.5 10.2519 16.5 9.25736H15ZM3 4.5C3 3.25736 4.00736 2.25 5.25 2.25L5.25 0.75C3.17893 0.75 1.5 2.42893 1.5 4.5L3 4.5ZM1.5 13.5C1.5 15.5711 3.17893 17.25 5.25 17.25V15.75C4.00736 15.75 3 14.7426 3 13.5H1.5ZM12.75 2.25C13.9926 2.25 15 3.25736 15 4.5L16.5 4.5C16.5 2.42893 14.8211 0.75 12.75 0.75V2.25ZM10.5 16.5V13.5H9V16.5H10.5ZM12.75 11.25L15.75 11.25V9.75H12.75V11.25ZM10.5 13.5C10.5 12.2574 11.5074 11.25 12.75 11.25V9.75C10.6789 9.75 9 11.4289 9 13.5H10.5ZM6 6H12V4.5H6V6ZM6 9.75H9V8.25H6V9.75Z"
        fill={color}
      />
    </svg>
  );
};
