export interface dataType {
  textSms: string;
  templateNameSms: string;
  key: string;
}
export const dataSms: dataType[] = [
  {
    textSms:
      "Добрый день! Чат с менеджером по вакансии, на которую Вы откликались - https://t.me/rabotut_connect_bot?start=",
    templateNameSms: "Отправка ссылки на чат в Telegram",
    key: "telegram",
  },
];
