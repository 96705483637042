import { gql } from "@apollo/client";

export const TRANSFER_CANDIDATE = gql`
  mutation transferCandidate($id: Int!) {
    transferCandidate(replyId: $id) {
      ... on CandidateType {
        id
        email
        age
        citizenshipId
        city
        companyId
        createdAt
        deletedAt
        departmentId
        fullName
        hasChat
        hasHistory
        inBlacklist
        isSpam
        managerComment
        managerId
        ownerId
        phone
        callCompleted
        positions {
          key
        }
        region
        russianCitizenship
        source
        status
        updatedAt
      }
      ... on Error {
        errors
        message
        statusCode
      }
    }
  }
`;
