import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "services/store/store";
import {
  selectCandidate,
  selectCandidateHistory,
  selectCandidateMainPhone,
  selectCandidateStatuses,
} from "services/store/modules/candidates/selectors";
import { authInfo } from "services/store/modules/auth";
import { CANDIDATE_STATUS_TYPE } from "common/const/status";
import { CandidateStatus } from "graphql/types/types";
import { FormSelect } from "components/UI/Form/FormSelect";
import SendSMSButton from "components/SendSMS/SendSMSButton";
import FormText from "components/UI/Form/FormText";
import { CallButton } from "components/Calls/CallButton";
import { changeCandidateStatus } from "services/store/modules/candidates/action";
import { selectVacancyData } from "services/store/modules/vacancy/selectors";
import toaster from "components/UI/Notifications/Notification";
import { Button } from "components/UI/Button";
import { ROUTE } from "routes";
import { getDateFormat } from "common/utils/date";
import { CandidateModals } from "components/CandidateModals";
import { CandidateHistory } from "./components/CandidateHistory";
import { Tooltip } from "components/UI/Tooltip";
import { Warning } from "components/UI/icons";

import "./styles.scss";

interface LocationState {
  vacancyId?: string;
}

type Props = {
  className?: string;
};

export const CandidateActions: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation<LocationState>();

  const { candidate } = useAppSelector(selectCandidate);
  const candidateStatuses = useAppSelector(selectCandidateStatuses);
  const authData = useAppSelector(authInfo);
  const vacancy = useAppSelector(selectVacancyData);
  const candidateHistory = useAppSelector(selectCandidateHistory);
  const { contact } = useAppSelector(selectCandidateMainPhone) || {};

  const { vacancyId } = location.state || {};
  const numericVacancyId = Number(vacancyId);
  const {
    id: candidateId,
    fullName,
    status,
    callCompleted,
    updater,
    updatedAt,
    vacancyCount,
  } = candidate;

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [candidateStatus, setCandidateStatus] = useState(
    CANDIDATE_STATUS_TYPE[status as CandidateStatus]
  );
  const [loading, setLoading] = useState(false);

  const handleToRecommendedJobs = () => {
    history.push(`${ROUTE.RECOMMENDED}/${candidateId}`);
  };

  const handleChange = async (status: CandidateStatus) => {
    setCandidateStatus(status);
    setLoading(true);

    try {
      await dispatch(
        changeCandidateStatus({
          id: candidateId,
          status,
          comment: "",
          vacancyId: numericVacancyId ? numericVacancyId : null,
        })
      );

      if (
        CandidateStatus.Candidate === status &&
        numericVacancyId === vacancy?.id &&
        numericVacancyId
      ) {
        setIsModalOpen(true);
      }
      toaster.success({ title: "Статус успешно изменен" }, { autoClose: 3000 });
    } catch (error) {
      toaster.error({ title: `Ошибка: ${error}` }, { autoClose: 3000 });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (status) {
      setCandidateStatus(CANDIDATE_STATUS_TYPE[status]);
    }
  }, [status]);

  return (
    <div className="candidates-actions">
      {vacancyId && (
        <FormText
          className="candidates-actions-vacancy"
          label="Вакансия"
          text={vacancy?.name}
        />
      )}
      <div className="candidates-actions-btns">
        {candidate && Object.keys(candidate).length > 0 && (
          <CallButton
            candidate={candidate}
            contact={contact}
            vacancyId={numericVacancyId}
          />
        )}

        <SendSMSButton
          phoneNumber={contact || ""}
          candidateId={candidateId}
          userName={fullName || ""}
          userId={authData?.id ?? 0}
          disabled={!callCompleted}
        />
      </div>

      <FormSelect
        label="Статус"
        value={candidateStatus}
        style={{ width: "100%" }}
        loading={loading}
        options={candidateStatuses.map(({ name, key }) => ({
          label: name,
          value: key,
        }))}
        onChange={handleChange}
      />

      <FormText
        label="ФИО заполнившего карточку"
        className="candidates-actions-updater"
        text={
          updater ? (
            <>
              <span>{`${updater.firstName} ${updater.secondName}`}</span>
              <span>{getDateFormat(updatedAt, "DD.MM.YYYY")}</span>
            </>
          ) : (
            "Изменений по кандидату не было."
          )
        }
      />
      {!Boolean(vacancyCount) && (
        <Tooltip
          placement="bottomLeft"
          title="Подходящие для кандидата вакансии отсутствуют"
        >
          <div className="candidates-actions-tooltip">
            <Warning />
          </div>
        </Tooltip>
      )}
      <Button
        onClick={handleToRecommendedJobs}
        disabled={!Boolean(vacancyCount)}
        className="candidates-actions-btn"
      >
        Подобрать вакансии
      </Button>
      {Boolean(candidateHistory?.items?.length) && candidateHistory && (
        <CandidateHistory candidateHistory={candidateHistory} />
      )}
      <CandidateModals
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        mode="candidate"
      />
    </div>
  );
};
