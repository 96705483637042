import React, { useEffect, useState } from "react";
import axios from "axios";
import toaster from "components/UI/Notifications/Notification";
import { useLocation } from "react-router-dom";
import { createEvent } from "../../api/clickStream";
import { getDateTimezone } from "../../common/helpers/getDateTimezone";
import { SendSMSButtonProps, storedStateType } from "./types";
import ModalSms, { SEND_SMS_BUTTON_ID } from "./ModalSms";
import { dataType } from "./data";
import { Button } from "components/UI/Button";
import { PaperPlane } from "components/UI/icons";

const USERNAME = process.env.REACT_APP_BASIC_AUTH_SMS_USERNAME ?? "";
const PASSWORD = process.env.REACT_APP_BASIC_AUTH_SMS_PASSWORD ?? "";

const url = "https://api.prostor-sms.ru/messages/v2/send/";

const SendSMSButton: React.FC<SendSMSButtonProps> = ({
  candidateId,
  phoneNumber,
  userName,
  userId,
  disabled,
}) => {
  const location = useLocation();
  const [isButtonVisible, setIsButtonVisible] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [historySendSms, setHistorySendSms] = useState<
    storedStateType[] | null
  >(null);

  useEffect(() => {
    const storedState = localStorage.getItem(`buttonState_${candidateId}`);
    if (storedState) {
      const parsStoredState = JSON.parse(storedState);
      setHistorySendSms(parsStoredState);
    }
  }, [candidateId]);

  const handlerSendMessageSms = (item: dataType) => {
    const text = `${item.textSms}${candidateId}`;
    const params = {
      phone: phoneNumber,
      text,
      sender: "OOO_RABOTUT",
    };
    axios
      .get(url, {
        params,
        auth: {
          username: USERNAME,
          password: PASSWORD,
        },
      })
      .then(() => {
        toaster.success({ title: "СМС сообщение отправлено." });

        setIsButtonVisible(isButtonVisible + 1);
        const existingData = localStorage.getItem(`buttonState_${candidateId}`);
        let dataArray: storedStateType[] = [];
        if (existingData) {
          dataArray = JSON.parse(existingData);
        }
        const dataToSave = {
          isButtonVisible: isButtonVisible,
          timestamp: new Date().toISOString(),
          templateNameSms: item.templateNameSms,
        };
        dataArray.push(dataToSave);
        localStorage.setItem(
          `buttonState_${candidateId}`,
          JSON.stringify(dataArray)
        );
        setHistorySendSms(dataArray);
      })
      .catch((error) => {
        toaster.error({
          title: `СМС сообщение не отправлено, ошибка: ${error}`,
        });
      });

    createEvent({
      userId,
      elementId: SEND_SMS_BUTTON_ID,
      url: location.pathname,
      timestamp: getDateTimezone("Europe/Moscow"),
    }).catch(() => null);
  };

  return (
    <>
      <Button
        variant="primary"
        icon={<PaperPlane />}
        onClick={() => {
          if (
            (!historySendSms || historySendSms?.length === 1) &&
            phoneNumber
          ) {
            setIsOpen(true);
          }
        }}
      />
      {isOpen && !disabled && (
        <ModalSms
          open={isOpen}
          onClose={() => setIsOpen(false)}
          title={`Отправить ${userName} СМС`}
          onConfirm={handlerSendMessageSms}
        />
      )}
    </>
  );
};

export default SendSMSButton;
