import React, { FC } from "react";
import classNames from "classnames/dedupe";
import { cn } from "helpers/bem";
import "./styles.scss";

const b = cn("app-checkbox");

interface Props {
  className?: string;
  label?: string;
  description?: string;
  id: string;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const AppCheckbox: FC<Props> = (props) => {
  const { className, label, description, checked, onChange, id } = props;

  return (
    <div className={classNames(b(), className, "relative flex items-start")}>
      <div className="flex h-6 items-center">
        <input
          id={id}
          type="checkbox"
          checked={checked}
          onChange={onChange}
          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
        />
      </div>
      {(label || description) && (
        <div className="ml-3 text-sm leading-6">
          {label && (
            <label htmlFor={id} className="font-medium text-gray-900">
              {label}
            </label>
          )}
          {description && (
            <p id="comments-description" className="text-gray-500">
              {description}
            </p>
          )}
        </div>
      )}
    </div>
  );
};
