import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { AppInput } from "../../UI/AppInput/AppInput";
import { cn } from "../../../helpers/bem";
import { useAppDispatch } from "../../../services/store/store";
import { validateAddress } from "../../../services/store/modules/recommendations";
import { AppSelect, TWSelectOption } from "../../UI/AppSelect/AppSelect";
import { useSelector } from "react-redux";
import { selectAddress } from "../../../services/store/modules/recommendations/selectors";
import { RecommendationParams } from "../../../services/store/modules/recommendations/types";
import { authInfo } from "../../../services/store/modules/auth";
import { createEvent } from "../../../api/clickStream";
import { getDateTimezone } from "../../../common/helpers/getDateTimezone";

const mode: TWSelectOption[] = [
  {
    name: "Смешанный",
    key: "mixed",
  },
  {
    name: "На транспорте",
    key: "transit",
  },
  {
    name: "Пешком",
    key: "walking",
  },
  {
    name: "На автомобиле",
    key: "driving",
  },
];

const b = cn("recommendations");

const FIND_VACANCIES_BUTTON_ID = "find-vacancies-button";

const RecommendationsInput: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [recommendationInput, setRecommendationInput] = useState("");
  const [selectMode, setSelectMode] = useState<TWSelectOption | null>(mode[0]);
  const { address } = useSelector(selectAddress);
  const userData = useSelector(authInfo);

  const handlerAddress = (e) => {
    e.preventDefault();
    const payload = {
      address: recommendationInput,
      mode: selectMode?.key,
    };
    dispatch(validateAddress(payload as RecommendationParams));

    createEvent({
      userId: userData?.id ?? 0,
      elementId: FIND_VACANCIES_BUTTON_ID,
      url: location.pathname,
      timestamp: getDateTimezone("Europe/Moscow"),
    }).catch(() => null);
  };

  return (
    <form onSubmit={handlerAddress} className="p-4 pt-0">
      <p className="text-gray-500 mb-0">
        Приветствуем вас в рекомендательной системе вакансий! Чтобы получить
        список рекомендуемых вакансий - введите адрес вашего местоположения.
      </p>
      <p className="text-gray-700 mb-0 font-bold">
        {" "}
        Например: Казань, ул. Баумана, д.54
      </p>
      <div className="flex items-center pt-4 justify-between">
        <div className="flex-grow pr-2">
          <AppInput
            label="Укажите адрес"
            placeholder="Казань, ул. Баумана, д.54"
            className={b("select")}
            value={recommendationInput}
            id="name"
            onChange={(e) => setRecommendationInput(e.currentTarget.value)}
            type="text"
          />
        </div>
        <div className="w-[170px]">
          <AppSelect
            className={b("select")}
            label="Вид маршрута"
            value={selectMode}
            onChange={(e) => setSelectMode(e)}
            options={mode as TWSelectOption[]}
          />
        </div>

        <button
          id={FIND_VACANCIES_BUTTON_ID}
          type="submit"
          className={
            "rounded-md mt-8 ml-2 px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 bg-indigo-500 hover:bg-indigo-400 cursor-pointer"
          }
        >
          Подобрать
        </button>
      </div>
      {address && (
        <div className="rounded-md bg-blue-50 p-2 mt-4">
          <div className="flex">
            <p className="text-sm text-blue-700 mb-0">
              {" "}
              Найдены вакансии по адресу: {address}
            </p>
          </div>
        </div>
      )}
    </form>
  );
};

export default RecommendationsInput;
