import React, { useState } from "react";
import cn from "classnames/dedupe";
import { useHistory } from "react-router-dom";

import { VacancyStatus, VacancyType } from "graphql/types/types";
import { Title } from "components/UI/Title";
import RowLayout from "layouts/RowLayout";
import { Maps } from "components/UI/Maps";
import { Button } from "components/UI/Button";
import { useToggle } from "common/hooks/useToggle";
import { Modal } from "components/UI/Modal";
import Checkbox from "components/UI/Checkbox";
import { PLATFORM_OPTIONS } from "common/const/options";
import { Platform } from "common/const/platform";
import { ROUTE } from "routes";
import { useAppDispatch, useAppSelector } from "services/store/store";
import { hideVacancy } from "services/store/modules/vacancy/actions";
import { deleteVacancy } from "services/store/modules/vacanciesList";
import {
  setVacancyInWork,
  setVacancyPeopleCount,
} from "services/store/modules/vacancy";
import toaster from "components/UI/Notifications/Notification";
import {
  selectDisabledVacancyList,
  selectVacancyDisabled,
} from "services/store/modules/vacanciesList/selectors";
import { authInfo } from "services/store/modules/auth";
import { Tooltip } from "components/UI/Tooltip";
import { Warning } from "components/UI/icons";
import { getTableRowDisabled } from "common/helpers/table";
import { getVacancyInfoRightColumn, getVacancyInfoLeftColumn } from "./helpers";
import { categories } from "services/store/modules/directory";
import { useChangeVisible } from "common/hooks/visible/useChangeVisible";
import { Spinner } from "components/UI/Spinner";

import "./styles.scss";

type Props = {
  vacancy: VacancyType;
  publishItems?: Platform[];
  changeItems?: (val: Platform[]) => void;
  openModal?: () => void;
  className?: string;
  isHidden?: boolean;
  mapLoading?: boolean;
};

export const VacancyInfo: React.FC<Props> = ({
  vacancy,
  className,
  publishItems,
  changeItems,
  openModal,
  isHidden = false,
  mapLoading,
}) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { onChangeVacancyVisible } = useChangeVisible({ id: vacancy.id });

  const disabledVacancyList = useAppSelector(selectDisabledVacancyList);
  const { managersIds } = useAppSelector((state) =>
    selectVacancyDisabled(state, vacancy.id)
  );
  const userData = useAppSelector(authInfo);
  const categoriesList = useAppSelector(categories);

  const [openPublish, togglePublish] = useToggle(false);
  const [openInWork, toggleInWork] = useToggle(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<null | string>(null);

  const handlePublishClick = () => {
    togglePublish();
    openModal?.();
  };

  const handleClosePublishClick = async () => {
    setLoading(true);
    setError(null);
    try {
      const result = await dispatch(hideVacancy({ id: vacancy.id })).unwrap();

      if (!result.hideVacancy) {
        setError(`Ошибка скрытия вакансии`);
        return;
      }

      dispatch(deleteVacancy({ id: vacancy.id }));
      history.replace(ROUTE.VACANCIES);
    } catch (error) {
      setError(`Ошибка: ${error}`);
    } finally {
      setLoading(false);
    }
  };

  const handleVacancyVisible = async () => {
    const result = await onChangeVacancyVisible({ inWork: true }).unwrap();

    toaster.success({ title: "Вакансия в работе" });
    dispatch(setVacancyInWork({ inWork: true }));

    if (result?.changeVisibleVacancy) {
      const { peopleCountOpen, peopleCountClose } = result.changeVisibleVacancy;

      dispatch(setVacancyPeopleCount({ peopleCountOpen, peopleCountClose }));
    }
  };

  const isVacancyRowDisabled = getTableRowDisabled({
    id: vacancy.id,
    disabledList: disabledVacancyList,
    ownerId: userData?.id,
  });

  const actionButtons = [
    {
      text: "Взять в работу",
      className: "vacancyInfo-container-publish",
      disabled:
        vacancy.status === VacancyStatus.Moderation ||
        isVacancyRowDisabled ||
        Boolean(vacancy.peopleCountOpen) ||
        Boolean(vacancy.inWork),
      onClick: handleVacancyVisible,
    },
    {
      text: "Опубликовать на JobBoard",
      className: "vacancyInfo-container-publish",
      disabled: vacancy.status === VacancyStatus.Moderation,
      onClick: togglePublish,
    },
    {
      text: "Оставить в работе",
      className: "vacancyInfo-container-publish",
      variant: "primary" as const,
      onClick: toggleInWork,
    },
  ];

  return (
    <div className={cn(className, "vacancyInfo-container")}>
      <RowLayout
        leftSpan={isHidden ? 12 : 10}
        leftComponent={
          <div className="vacancyInfo-container-column global-scrollbar">
            {getVacancyInfoLeftColumn(vacancy).map(
              ({ type, title, color, html, content }, ind) => (
                <>
                  <Title
                    key={ind}
                    type={type}
                    color={color}
                    className="vacancyInfo-container-title"
                  >
                    {title}
                  </Title>
                  <div
                    className="vacancyInfo-container-short"
                    dangerouslySetInnerHTML={
                      html ? { __html: html } : undefined
                    }
                  >
                    {content}
                  </div>
                </>
              )
            )}

            {mapLoading ? (
              <Spinner
                size="small"
                className="vacancy-container-table-spinner"
                loading={mapLoading}
              />
            ) : (
              <Maps
                zoom={14}
                width="calc(100% - 16px)"
                height={160}
                placemarks={[
                  {
                    id: String(vacancy.id),
                    lat: Number(vacancy.address.lat),
                    lng: Number(vacancy.address.lng),
                    name: vacancy?.name || "",
                  },
                ]}
              />
            )}
          </div>
        }
        centerSpan={isHidden ? 12 : 10}
        centerComponent={
          <div className="vacancyInfo-container-column global-scrollbar">
            {getVacancyInfoRightColumn(vacancy, categoriesList).map(
              (
                { type, title, color, html, content, isTitle, isShort },
                ind
              ) => (
                <div
                  key={ind}
                  className={cn("vacancyInfo-container-item", {
                    "vacancyInfo-container-item--inline": isShort,
                  })}
                >
                  <Title
                    type={type}
                    color={color}
                    className="vacancyInfo-container-title"
                  >
                    {title}
                  </Title>
                  {isTitle ? (
                    <Title type="h2">{content}</Title>
                  ) : (
                    <div
                      className="vacancyInfo-container-short"
                      dangerouslySetInnerHTML={
                        html ? { __html: html } : undefined
                      }
                    >
                      {content}
                    </div>
                  )}
                </div>
              )
            )}
          </div>
        }
        rightSpan={isHidden ? 0 : 4}
        rightComponent={
          isHidden ? null : (
            <>
              {isVacancyRowDisabled &&
                userData?.id &&
                !managersIds.includes(userData.id) && (
                  <Tooltip
                    placement="bottomLeft"
                    title="Вакансия находится в работе у других менеджеров"
                  >
                    <div className="vacancyInfo-container-tooltip">
                      <Warning />
                    </div>
                  </Tooltip>
                )}
              {actionButtons.map(
                ({ text, className, variant, disabled, onClick }) => (
                  <Button
                    className={className}
                    disabled={disabled}
                    variant={variant}
                    onClick={onClick}
                  >
                    {text}
                  </Button>
                )
              )}

              <div className="vacancyInfo-container-people-count">
                <div className="column">
                  <div>Потребность</div>
                  <div>{vacancy.peopleCountOpen}</div>
                </div>
                <div className="column">
                  <div>Закрыто</div>
                  <div>{vacancy.peopleCountClose}</div>
                </div>
              </div>
            </>
          )
        }
      />
      <Modal
        open={openPublish}
        title="Опубликовать вакансию"
        onCancel={togglePublish}
        footer={
          <Button disabled={!publishItems?.length} onClick={handlePublishClick}>
            Продолжить
          </Button>
        }
      >
        <Checkbox
          className="vacancyInfo-container-checkbox"
          type="multi"
          options={PLATFORM_OPTIONS}
          value={publishItems}
          onChange={(v) => changeItems?.(v as unknown as Platform[])}
        />
      </Modal>

      <Modal
        open={openInWork}
        title="Вакансия будет перенесена в раздел Холодный поиск"
        onCancel={toggleInWork}
        noSidePanel={true}
        footer={
          <div className="vacancyInfo-container-close">
            <Button
              loading={loading}
              onClick={handleClosePublishClick}
              className="vacancyInfo-container-close-publish"
            >
              Подтвердить
            </Button>
            <Button
              variant="primary"
              onClick={toggleInWork}
              className="vacancyInfo-container-close-publish"
            >
              Отменить
            </Button>
            {error && (
              <div className="vacancyInfo-container-error">{error}</div>
            )}
          </div>
        }
      />
    </div>
  );
};
