import React, { useEffect, useState } from "react";
import CandidateForm from "./CandidateForm/CandidateForm";
import { useDispatch, useSelector } from "react-redux";
import { fetchChats } from "../../services/store/modules/chats";
import { RootState } from "../../services/store/rootReducer";
import { selectChats } from "../../services/store/modules/chats/selectors";
import ReplyChatTab from "../Reply/ReplyChatTab";
import { selectReplies } from "../../services/store/modules/candidates/selectors";
import Recommendations from "./RecommendationsTabs/Recommendations";

interface Tab {
  name: string;
  key: string;
}

const tabs: Tab[] = [
  { name: "Контактные данные", key: "candidate" },
  { name: "Чат", key: "chat" },
  { name: "Рекомендации", key: "recommendations" },
];

function classNames(...classes: string[]): string {
  return classes.filter(Boolean).join(" ");
}

export default function CandidateTabs() {
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState<string>(tabs[0].key);

  const [chatId, setChatId] = useState<string>("");
  const { data } = useSelector(selectReplies);
  const chats = useSelector((state: RootState) => selectChats(state));

  const replyItem = data?.data?.find((el) => el.isMain);
  const managerId = replyItem?.ownerId;
  const replyId = replyItem?.candidateId;
  useEffect(() => {
    if (
      replyItem &&
      replyId &&
      chats[replyId] &&
      chatId !== chats[replyId].id
    ) {
      setChatId(chats[replyId].id);
    }
  }, [replyItem, replyId, chats, chatId]);

  useEffect(() => {
    if (managerId) {
      dispatch(
        fetchChats({
          managerID: managerId,
        })
      );
    }
  }, [managerId]);

  const renderComponent = (tabKey: string) => {
    switch (tabKey) {
      case "candidate":
        return <CandidateForm />;
      // case "history":
      //   return data ? (
      //     <CandidateHistory replyList={data} />
      //   ) : (
      //     <div>История недоступна</div>
      //   );
      case "chat":
        if (replyItem && chatId) {
          return <ReplyChatTab item={replyItem} chat_id={chatId} />;
        } else {
          return <div>Чат еще не создан</div>;
        }
      case "recommendations":
        return <Recommendations />;
      default:
        return null;
    }
  };

  return (
    <div>
      <div className="hidden sm:block bg-gray-100 pt-4">
        <nav className="flex space-x-4 px-4" aria-label="Tabs">
          {tabs.map((tab) => (
            <a
              key={tab.key}
              className={classNames(
                activeTab === tab.key
                  ? "border-indigo-500 text-indigo-600"
                  : "border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700",
                "flex whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium ml-4"
              )}
              onClick={() => setActiveTab(tab.key)}
            >
              {tab.name}
            </a>
          ))}
        </nav>
        <div
          style={{ height: "85vh", overflowY: "auto" }}
          className="bg-white pt-4 px-4"
        >
          {renderComponent(activeTab)}
        </div>
      </div>
    </div>
  );
}
