import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, SubmitHandler, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useHistory } from "react-router";

import { VacancyStatus, VacancyType } from "graphql/types/types";
import {
  publishVacancy,
  transferVacancy,
  updateVacancy,
} from "services/store/modules/vacancy/actions";
import { Title } from "components/UI/Title";
import { filterOption } from "components/UI/Select/helpers";
import { FormInput } from "components/UI/Form/FormInput";
import { useAppDispatch, useAppSelector } from "services/store/store";
import { FormSelect } from "components/UI/Form/FormSelect";
import RowLayout from "layouts/RowLayout";
import Input from "components/UI/Input";
import { Platform } from "common/const/platform";
import { FormSelectDadata } from "components/UI/Form/FormSelectDadata";
import { Editor } from "components/UI/Editor";
import { VACANCY_CHARACTER_LIMITS } from "common/const/characterLimits";
import Checkbox from "components/UI/Checkbox";
import { Button } from "components/UI/Button";
import { Form } from "components/UI/Form";
import { PublicationStatusModal } from "../PublicationStatusModal";
import { EMPTY_PUBLICATION, getContacts } from "../helpers";
import { schema } from "./validation";
import { ModalMode, RabotutState } from "./types";
import {
  buttonNameByMode,
  getInitialState,
  getPayload,
  getTitleByMode,
  renderInfoItems,
  renderNotificationByMode,
} from "./helpers";
import Text from "components/UI/Text";
import toaster from "components/UI/Notifications/Notification";
import { setVacancy } from "services/store/modules/vacanciesList";
import FormText from "components/UI/Form/FormText";
import { maskPhone } from "common/utils";
import { selectPositionRelationsById } from "services/store/modules/settings/selectors";
import { IUser } from "services/store/modules/user/types";
import { useChangeVisible } from "common/hooks/visible/useChangeVisible";
import { getTableRowDisabled } from "common/helpers/table";
import { DisabledVacancies } from "services/store/modules/vacanciesList/types";
import { useTimeout } from "common/hooks/useTimeout";
import { HOUR } from "common/const/time";
import { useBeforeUnload } from "common/hooks/useBeforeUnload";

import "./styles.scss";

interface Props {
  vacancy: VacancyType;
  managerList: IUser[];
  userId?: number;
  publishItems?: Platform[];
  mode?: ModalMode;
  disabled?: boolean;
  disabledList?: DisabledVacancies;
  changeTab?: (key: Platform) => void;
  toggleDisabled?: () => void;
  openRejectModal?: () => void;
  openAcceptModal?: () => void;
  openDeleteModal?: () => void;
  onCancel?: () => void;
}

const MAX_TIME_VACANCY_BLOCK = 3 * HOUR;

export const RabotutForm: React.FC<Props> = ({
  vacancy,
  userId,
  managerList,
  disabledList = [],
  publishItems = [],
  mode = "publication",
  disabled = false,
  changeTab,
  toggleDisabled,
  openAcceptModal,
  openRejectModal,
  openDeleteModal,
}) => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const { onChangeVacancyVisible } = useChangeVisible({ id: vacancy.id });

  const { vacancyTags, categories, employers, positions } = useAppSelector(
    (state) => state.directory
  );
  const positionRelations = useAppSelector((state) =>
    selectPositionRelationsById(state, Number(vacancy.positionId))
  );

  const [isSalaryBlock, setIsSalaryBlock] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<null | string>(null);
  const [publishModal, setPublishModal] = useState(EMPTY_PUBLICATION);

  const isEditingMode = mode === "editing";
  const isPublicationMode = mode === "publication";

  const initialState = useMemo<RabotutState>(
    () => getInitialState({ vacancy, positionRelations }),
    [positionRelations, vacancy]
  );

  const {
    handleSubmit,
    control,
    setValue,
    setError: setErrorForm,
    formState: { errors, dirtyFields, isDirty },
  } = useForm<RabotutState>({
    // @ts-expect-error
    resolver: yupResolver(schema),
    defaultValues: initialState,
  });

  const managerId = useWatch({
    control,
    name: "managerId",
  });
  const { phone, email } = useMemo(
    () => getContacts(managerId, managerList),
    [managerId, managerList]
  );

  const changeVacancyVisibleBlocked = useCallback(
    (inBlocked: boolean) =>
      onChangeVacancyVisible({ inWork: false, inBlocked }),
    [onChangeVacancyVisible]
  );

  const changeVacancyVisibleBlockedPromise = async () =>
    await changeVacancyVisibleBlocked(false);

  useTimeout(changeVacancyVisibleBlockedPromise, MAX_TIME_VACANCY_BLOCK);
  useBeforeUnload(changeVacancyVisibleBlockedPromise);

  useEffect(() => {
    return () => {
      changeVacancyVisibleBlockedPromise();
    };
  }, []);

  const updateVacancyForm: SubmitHandler<RabotutState> = useCallback(
    async (formData) => {
      setLoading(true);
      setError(null);
      const form = getPayload(formData, positions, managerList);

      try {
        if (dirtyFields.managerId) {
          await dispatch(
            transferVacancy({ id: vacancy.id, newUserId: managerId })
          ).unwrap();
        }
        const updateResult = await dispatch(
          updateVacancy({ form, id: vacancy.id })
        ).unwrap();
        if (!updateResult.updateVacancy) {
          setPublishModal({
            status: "error",
            message: "Ошибка обновления вакансии",
          });
          return;
        }
        if (isEditingMode && updateResult.updateVacancy) {
          const result = await changeVacancyVisibleBlocked(false).unwrap();
          dispatch(
            setVacancy(
              result?.changeVisibleVacancy ?? updateResult.updateVacancy
            )
          );
          toggleDisabled?.();
          toaster.success({ title: "Данные успешно сохранены" });
        }

        if (isPublicationMode) {
          const publishResult = await dispatch(
            publishVacancy({ id: vacancy.id })
          ).unwrap();

          if (!publishResult.publishVacancy) {
            setPublishModal({
              status: "error",
              message: "Ошибка публикации вакансии",
            });
            return;
          }
        }

        setPublishModal({
          status: "success",
          message: "Данные успешно сохранены и вакансия опубликована",
        });
      } catch (error) {
        setPublishModal({
          status: "error",
          message: error.message,
        });
        setError("Ошибка сохранения вакансии");
      } finally {
        setLoading(false);
      }
    },
    [
      positions,
      managerList,
      dirtyFields.managerId,
      dispatch,
      vacancy.id,
      isEditingMode,
      isPublicationMode,
      managerId,
      changeVacancyVisibleBlocked,
      toggleDisabled,
    ]
  );
  const handleClosePublishModal = () => {
    if (publishModal.status === "success" && publishItems.length > 1) {
      changeTab?.(publishItems.includes("headhunter") ? "headhunter" : "avito");
    } else {
      history.goBack();
    }
    setPublishModal((prev) => ({ ...prev, message: null }));
  };

  const handleAccept = async () => {
    if (isDirty) {
      await handleSubmit(updateVacancyForm)();
    }

    changeVacancyVisibleBlocked(false);
    openAcceptModal?.();
  };

  const handleEditing = async () => {
    if (vacancy.status === VacancyStatus.Moderation) {
      await changeVacancyVisibleBlocked(true);
    }

    toggleDisabled?.();
  };

  const handleRejectModal = async () => {
    changeVacancyVisibleBlocked(false);

    openRejectModal?.();
  };

  const isVisible =
    isEditingMode &&
    vacancy.status &&
    [VacancyStatus.Moderation, VacancyStatus.InWork].includes(vacancy.status);
  const isVacancyDisabled = getTableRowDisabled({
    id: vacancy.id,
    disabledList,
    ownerId: userId,
  });

  const buttons = [
    {
      loading,
      name: buttonNameByMode(disabled, publishItems.length)[mode],
      isVisible: true,
      isDisable:
        Boolean(vacancy.publishedOnRabotut) ||
        isVacancyDisabled ||
        Boolean(vacancy.inBlocked),
      onClick: disabled ? handleEditing : handleSubmit(updateVacancyForm),
    },
    {
      name: "Принять",
      isVisible: isEditingMode && vacancy.status === VacancyStatus.Moderation,
      isDisable: isVacancyDisabled || Boolean(vacancy.inBlocked),
      variant: "green" as const,
      onClick: handleAccept,
    },
    {
      name: "Отклонить",
      isVisible,
      isDisable: isVacancyDisabled || Boolean(vacancy.inBlocked),
      variant: "primary" as const,
      onClick: handleRejectModal,
    },
    {
      name: "Удалить",
      isVisible: isVisible && disabled,
      isDisable: isVacancyDisabled || Boolean(vacancy.inBlocked),
      variant: "primary" as const,
      onClick: openDeleteModal,
    },
  ];

  return (
    <Form className="rabotut-form" disabled={disabled}>
      <div className="rabotut-form-header">
        <Title type="h2">{getTitleByMode(vacancy.name ?? "")[mode]}</Title>

        {isEditingMode && disabled && (
          <Button
            variant="primary"
            disabled={isVacancyDisabled || Boolean(vacancy.inBlocked)}
            onClick={handleEditing}
          >
            Редактировать
          </Button>
        )}
      </div>

      {
        renderNotificationByMode(
          vacancy.status === VacancyStatus.Moderation,
          Boolean(vacancy.publishedOnRabotut)
        )[mode]
      }

      <div className="rabotut-form-info">
        <Title type="h3" color="blue">
          Общая информация
        </Title>

        {disabled ? renderInfoItems(vacancy.status, vacancy.relevance) : null}

        <Controller
          name="positionId"
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState: { error } }) => (
            <FormSelect
              label="Название вакансии"
              defaultValue={vacancy.position?.name}
              showSearch
              error={error?.message}
              required={true}
              optionFilterProp="children"
              filterOption={filterOption}
              options={positions?.map(({ name, id }) => ({
                label: name,
                value: id,
              }))}
              {...field}
            />
          )}
        />
        <Controller
          name="employmentId"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <FormSelect
              label="Тип занятости"
              required={true}
              options={categories.map(({ id, name }) => ({
                label: name,
                value: id,
              }))}
              {...field}
            />
          )}
        />
        <RowLayout
          style={{ display: "inlineBlock", alignItems: "center" }}
          leftComponent={
            <Controller
              name="salaryFrom"
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState: { error } }) => (
                <FormInput
                  label="Предполагаемый уровень дохода в месяц"
                  required={!isSalaryBlock}
                  type="number"
                  disabled={isSalaryBlock || disabled}
                  error={error?.message ?? errors.salaryTo?.message}
                  {...field}
                />
              )}
            />
          }
          leftSpan={12}
          rightComponent={
            <Controller
              name="salaryTo"
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState: { error } }) => (
                <>
                  <Input
                    required={!isSalaryBlock}
                    type="number"
                    disabled={isSalaryBlock || disabled}
                    status={
                      error?.message ?? errors.salaryFrom?.message
                        ? "error"
                        : undefined
                    }
                    {...field}
                  />
                  {(error?.message ?? errors.salaryFrom?.message) && (
                    <div className="rabotut-form-salary-error" />
                  )}
                </>
              )}
            />
          }
          rightSpan={12}
        />

        <Checkbox
          type="single"
          className="rabotut-form-checkbox"
          checked={isSalaryBlock}
          onSingleChange={(e) => {
            const checked = e.target.checked;
            setIsSalaryBlock(checked);
            if (checked) {
              setValue("salaryFrom", "0");
              setValue("salaryTo", "0");
              setErrorForm("salaryFrom", { message: "" });
              setErrorForm("salaryTo", { message: "" });
            }
          }}
        >
          Договорная заработная плата
        </Checkbox>

        <Controller
          name="address"
          control={control}
          rules={{ required: true }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <FormSelectDadata
              label="Место работы"
              required={true}
              error={errors.address?.value?.message ?? error?.message}
              disabled={disabled}
              inputProps={{
                onChange: (event) => {
                  const value = (event.target as HTMLInputElement).value;
                  if (!value) {
                    onChange({});
                  }
                },
              }}
              value={value}
              onChange={onChange}
            />
          )}
        />
        <Controller
          name="tagKey"
          control={control}
          render={({ field }) => (
            <FormSelect
              label="Тег вакансии"
              options={vacancyTags.map(({ key, name }) => ({
                label: name,
                value: key,
              }))}
              {...field}
            />
          )}
        />
        <Controller
          name="relevance"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Checkbox
              type="single"
              className="rabotut-form-checkbox"
              checked={value}
              onSingleChange={onChange}
            >
              Актуальная вакансия
            </Checkbox>
          )}
        />
        <Controller
          name="shortDescription"
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState: { error } }) => (
            <Editor
              label="Описание вакансии"
              required={true}
              error={error?.message}
              disabled={disabled}
              containerClassName="rabotut-form-desc"
              count={VACANCY_CHARACTER_LIMITS.SHORT_DESCRIPTION}
              {...field}
            />
          )}
        />
        <Controller
          name="description"
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState: { error } }) => (
            <Editor
              label="Обязанности"
              required={true}
              error={error?.message}
              disabled={disabled}
              containerClassName="rabotut-form-desc"
              count={VACANCY_CHARACTER_LIMITS.DESCRIPTION}
              {...field}
            />
          )}
        />
        <Controller
          name="terms"
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState: { error } }) => (
            <Editor
              label="Условия"
              required={true}
              disabled={disabled}
              error={error?.message}
              containerClassName="rabotut-form-desc"
              count={VACANCY_CHARACTER_LIMITS.TERMS}
              {...field}
            />
          )}
        />

        <Controller
          name="claims"
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState: { error } }) => (
            <Editor
              label="Требования"
              required={true}
              error={error?.message}
              disabled={disabled}
              containerClassName="rabotut-form-desc"
              count={VACANCY_CHARACTER_LIMITS.CLAIMS}
              {...field}
            />
          )}
        />

        <Controller
          name="platformTags"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Editor
              label="Ключевые слова"
              disabled={disabled}
              error={error?.message}
              containerClassName="rabotut-form-desc"
              count={VACANCY_CHARACTER_LIMITS.PLATFORM_TAGS}
              {...field}
            />
          )}
        />
      </div>
      <div className="rabotut-form-contacts">
        <Title type="h3" color="blue">
          Контакты
        </Title>
        <Controller
          name="managerId"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <FormSelect
              label={"ФИО"}
              showSearch
              required={true}
              optionFilterProp="children"
              filterOption={filterOption}
              options={managerList?.map(({ fullName, id }) => ({
                label: fullName,
                value: id,
              }))}
              {...field}
            />
          )}
        />
        <FormText label="Телефон" text={maskPhone(phone)} />
        <FormText label="Адрес электронной почты" text={email} />
        <Controller
          name="showContacts"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Checkbox
              type="single"
              checked={value}
              onSingleChange={onChange}
              className="rabotut-form-checkbox"
            >
              Показывать контакты
            </Checkbox>
          )}
        />
        <Controller
          name="employerId"
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState: { error } }) => (
            <FormSelect
              label="Работодатель"
              required={true}
              error={error?.message}
              options={employers.map(({ id, name }) => ({
                label: name,
                value: id,
              }))}
              {...field}
            />
          )}
        />
      </div>
      <div className="rabotut-form-cc">
        <Title type="h3" color="blue">
          Поля для КЦ
        </Title>
        <Controller
          name="callCenterInfoProject"
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState: { error } }) => (
            <FormInput
              label="Проект"
              required={true}
              error={error?.message}
              {...field}
            />
          )}
        />
        <Controller
          name="callCenterInfoDesc"
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState: { error } }) => (
            <Editor
              label="Описание компании"
              error={error?.message}
              disabled={disabled}
              required={true}
              {...field}
            />
          )}
        />
      </div>

      <div className="rabotut-form-buttons">
        {buttons.map(
          ({ isVisible, isDisable, name, variant, loading, onClick }) =>
            isVisible ? (
              <Button
                variant={variant}
                loading={loading}
                disabled={isDisable}
                onClick={onClick}
              >
                {name}
              </Button>
            ) : null
        )}
      </div>

      {error && mode === "editing" && (
        <Text style={{ color: "red" }}>{error}</Text>
      )}

      {isPublicationMode && (
        <PublicationStatusModal
          isVisible={Boolean(publishModal.message)}
          onClose={handleClosePublishModal}
          publication={publishModal}
          jobBoard="rabotut"
        />
      )}
    </Form>
  );
};
