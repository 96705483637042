import { TagColorType } from "../../../../types/TagColorType";

const getColorByStatus = (statusKey) => {
  switch (statusKey) {
    case "CANDIDATE":
    case "COMPLETED_CANDIDATE":
    case "SIGNUP_SHIFT":
    case "PUBLISHED":
    case "TRANSFERRED_TO_REPLIES":
    case "TRANSFERRED_TO_BUSINESS":
      return TagColorType.GREEN;
    case "DRAFT":
    case "MODERATION":
    case "CALLBACK":
    case "EXPIRED":
    case "TRANSFERRED_TO_HR_FROM_CC":
    case "WORKED_SHIFT":
    case "NEW":
      return TagColorType.YELLOW;
    case "REFUSED":
    case "NOT_THROUGH":
    case "DOESNT_GET_TOUCH":
    case "DENIED":
    case "CANDIDATE_DISMISSAL":
    case "FIRED":
    case "REFUSAL_CANDIDATE":
      return TagColorType.RED;
    default:
      return TagColorType.GRAY;
  }
};
export default getColorByStatus;
