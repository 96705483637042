import useWebSocket from "react-use-websocket";

import { Message } from "services/store/modules/chats/types";
import { useAppDispatch } from "services/store/store";
import { incrementByMessages } from "services/store/modules/chats";

const wsUrl = process.env.REACT_APP_TG_WS ?? "";

function useWebSocketConnection(chat_id: string) {
  const dispatch = useAppDispatch();

  const { sendJsonMessage, readyState } = useWebSocket(wsUrl, {
    onOpen: () => {
      console.log("WebSocket chat connected");
      sendJsonMessage({
        is_crm: true,
        chat_id: chat_id,
      });
    },
    onClose: (event) => {
      console.log(`WebSocket chat disconnected ${event.code}`);
    },
    shouldReconnect: (closeEvent) => true,
    reconnectAttempts: 3,
    reconnectInterval: 3000,
    onError: (error) => {
      console.error("WebSocket error:", error);

      if (error instanceof Event) {
        console.error("Event type:", error.type);
        console.error("Event target:", error.target);
      }
    },
    onMessage: (event) => {
      const messageData = JSON.parse(event.data);
      const newMessage: Message = {
        chat_id: messageData.chat_id,
        from_user: messageData.from_user,
        files: messageData.files,
        text: messageData.text,
      };
      dispatch(incrementByMessages(newMessage));
    },
  });

  return { readyState, sendJsonMessage };
}

export default useWebSocketConnection;
