import React, { useEffect } from "react";

import { ContentLayout } from "layouts/ContentLayout";
import { Report } from "components/redezign/Report";
import { useFetchUsers } from "common/hooks/users/useFetchUsers";
import { UserRole } from "graphql/types/types";

export const ReportPage: React.FC = () => {
  const { fetchUsers } = useFetchUsers({ role: UserRole.Manager }, 300);

  useEffect(() => {
    fetchUsers();
  }, []);

  return <ContentLayout centralColumn={{ content: <Report /> }} />;
};
