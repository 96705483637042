import React from "react";

interface InfoBlockProps {
  label: string;
  value: string | number | null | undefined;
}

const InfoBlock: React.FC<InfoBlockProps> = ({ label, value }) => {
  return (
    <div>
      <label htmlFor={label} className="block text-sm text-gray-700">
        {label}
      </label>
      <div className="mt-1 p-2 w-full border rounded-md bg-gray-100">
        {value || "не указано"}
      </div>
    </div>
  );
};

export default InfoBlock;
