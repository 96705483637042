import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectAddress } from "../../../services/store/modules/recommendations/selectors";
import { cn } from "../../../helpers/bem";
import classNames from "classnames/dedupe";
import "./styles.scss";
import busSvg from "../../../assets/icons/bus.svg";
import walkingSvg from "../../../assets/icons/walker.svg";
import carSvg from "../../../assets/icons/car.svg";
import { PaperAirplaneIcon } from "@heroicons/react/24/outline";
import Modal from "../../UI/Modals/Modal";
import { useAppDispatch } from "../../../services/store/store";
import { getVacancyById } from "../../../services/store/modules/vacancies";
import { getUserByID } from "../../../services/store/modules/user/action";
import { ReplyInputType, Source } from "../../../graphql/types/types";
import { selectCandidate } from "../../../services/store/modules/candidates/selectors";
import { client as apollo } from "../../../graphql/apollo";
import { CREATE_REPLY } from "../../../graphql/requests/mutation/createReply";
import toaster from "components/UI/Notifications/Notification";
import { RootState } from "../../../services/store/rootReducer";
import { selectChats } from "../../../services/store/modules/chats/selectors";
import { transferCandidate } from "services/store/modules/candidates/action";
import { updateManagerInChat } from "../../../services/store/modules/chats";
import { authInfo } from "../../../services/store/modules/auth";
import axios from "axios";

const b = cn("recommendations");

const getModeImage = (mode: string) => {
  if (mode === "transit") {
    return <img className="pl-1" src={busSvg} alt="Bus Icon" />;
  } else if (mode === "walking") {
    return <img className="pl-1" src={walkingSvg} alt="walking Icon" />;
  } else {
    return <img className="pl-1" src={carSvg} alt="car Icon" />;
  }
};

const RecommendationsTables: React.FC = () => {
  const dispatch = useAppDispatch();

  const { data } = useSelector(selectAddress);
  const authData = useSelector(authInfo);

  const { candidate } = useSelector(selectCandidate);
  const chats = useSelector((state: RootState) => selectChats(state));
  const chatId = chats[candidate?.id]?.id;
  const { contacts } = candidate || {};
  const phone = contacts ? contacts[0].contact : undefined;

  const [isUrl, setIsUrl] = useState("");

  const handlerRowClick = (url) => {
    const vacancyId = url.slice(url.lastIndexOf("/") + 1);
    window.open(`/vacancy/${vacancyId}`, "_blank");
  };

  const transferToOtherVacancy = async () => {
    const vacancyId = isUrl.slice(isUrl.lastIndexOf("/") + 1);
    const vacancyData = await dispatch(
      getVacancyById(Number(vacancyId))
    ).unwrap();
    const managerId = String(vacancyData.manager?.id);

    if (managerId) {
      //@ts-ignore
      const UserByID = await dispatch(getUserByID(managerId));
      if (UserByID?.payload && vacancyData?.position?.id) {
        const userData = UserByID?.payload;
        const replyPayload: ReplyInputType = {
          contacts: {
            //@ts-ignore
            fullName: candidate.fullName,
            email: candidate?.email,
            phone,
          },
          source: Source.Website,
          vacancyId: +vacancyData.id,
          positionId: vacancyData?.position?.id,
          //@ts-ignore
          ownerId: userData.id,
          //@ts-ignore
          companyId: userData.companyId,
          //@ts-ignore
          departmentId: userData.departmentId,
          address: {
            city: vacancyData.address.city,
          },
        };
        try {
          const res = await apollo.mutate({
            mutation: CREATE_REPLY,
            variables: {
              form: replyPayload,
            },
          });
          if (res.data?.createReply) {
            const replyId = res.data?.createReply?.id;
            dispatch(transferCandidate(replyId));
            if (chatId) {
              const payload = {
                chat_id: chatId,
                //@ts-ignore
                manager_id: userData.id,
              };
              dispatch(updateManagerInChat(payload));
            }

            toaster.success({ title: "Кандидат успешно добавлен на вакансию" });
          }
        } catch (error) {
          toaster.error({ title: `Ошибка: ${error}` });
        }
      }
    }
  };

  const sendMessage = (link) => {
    setIsUrl(link);
    const vacancyId = link.slice(link.lastIndexOf("/") + 1);
    const userName = `${authData?.firstName} ${authData?.secondName}`;
    const currentDate = new Date().toLocaleString("ru-RU", {
      timeZone: "Europe/Moscow",
    });
    const message = `Клик от ${userName}, время:${currentDate}, id: ${vacancyId}`;
    const url = `https://community.bitrix24.ru/rest/8/bmahfuflob5a1u0m/im.message.add.json?DIALOG_ID=chat1326&MESSAGE=${encodeURIComponent(
      message
    )}`;

    axios
      .get(url)
      .then((response) => {
        console.log("событие отправлено ", response.data);
      })
      .catch((error) => {
        console.error("Ошибка при отправке сообщения", error);
      });
  };

  return (
    <div className={`${classNames(b())} p-4`}>
      <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-500 uppercase"
              >
                Вакансия
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-500 uppercase"
              >
                Заработная плата
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-500 uppercase"
              >
                Время пути
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-500"
              ></th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {Array.isArray(data) &&
              data?.map((item, index) => (
                <tr key={index + item.distance}>
                  <td className="py-4 pl-4 pr-3 text-sm font-semibold text-indigo-500">
                    <p
                      className="cursor-pointer"
                      onClick={() => handlerRowClick(item.external_link)}
                    >
                      {item.vacancy_name}
                    </p>
                    <a
                      className="text-indigo-400 text-xs"
                      href={item.external_link}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      {item.external_link}
                    </a>
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {item.salary.to === 0 && item.salary.from === 0
                      ? "не указана"
                      : item.salary.to === 0
                      ? `от ${item.salary.from}`
                      : item.salary.from
                      ? `от ${item.salary.from} до ${item.salary.to}`
                      : "не указана"}
                  </td>
                  <td className=" flex whitespace-nowrap px-4 py-4 text-sm text-gray-500 mt-[10%]">
                    {`${Math.ceil(item.duration / 60)} мин.`}
                    {getModeImage(item.mode)}
                  </td>
                  <td
                    className="whitespace-nowrap p-4 text-sm text-gray-500"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <PaperAirplaneIcon
                      className="w-5 h-5 cursor-pointer"
                      onClick={() => sendMessage(item.external_link)}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {!!isUrl && (
        <Modal
          open={!!isUrl}
          onClose={() => setIsUrl("")}
          message={`Кандидат будет передан на другую вакансию`}
          title="Передача кандидата"
          onConfirm={transferToOtherVacancy}
        />
      )}
    </div>
  );
};
export default RecommendationsTables;
