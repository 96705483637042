import React, { FC, Fragment } from "react";
import classNames from "classnames/dedupe";
import { cn } from "helpers/bem";
import "./styles.scss";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { TagColorType } from "../../../types/TagColorType";
import { getTagBgColor } from "../AppTag/helpers/getTagBgColor";
import { getTagTextColor } from "../AppTag/helpers/getTagTextColor";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { useHistory, useLocation } from "react-router-dom";

const b = cn("tw-select");

export type TWSelectOption = {
  key: string;
  name: string;
};

export type Props = {
  className?: string;
  label?: string;
  value: TWSelectOption | null;
  onChange: (option: TWSelectOption | null) => void;
  options: Partial<TWSelectOption>[] | null;
  disabled?: boolean;
  color?: TagColorType;
  clearBtn?: boolean;
};

export const AppSelect: FC<Props> = (props) => {
  const {
    className,
    value,
    onChange,
    options,
    label,
    disabled = false,
    color,
    clearBtn = false,
  } = props;

  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);
  const offset = queryParams.get("offset");

  const clearSelection = (e) => {
    e.stopPropagation();
    onChange({
      key: "",
      name: "",
    });
    if (offset) {
      queryParams.delete("offset");

      history.push({
        pathname: location.pathname,
        search: queryParams.toString(),
      });
    }
  };

  return (
    <Listbox value={value} onChange={onChange} disabled={disabled}>
      {({ open }) => (
        <div className={classNames(b(), className)}>
          {label && (
            <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900 mb-2">
              {label}
            </Listbox.Label>
          )}
          <div className="relative">
            <Listbox.Button className="relative w-full cursor-default min-h-[36px] rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
              <span
                className={classNames(
                  "block truncate rounded-md px-1 pr-3",
                  color && getTagBgColor(color),
                  color && getTagTextColor(color)
                )}
              >
                {value?.name}
              </span>
              {value?.key && clearBtn && (
                <span className="absolute inset-y-0 right-5 flex items-center pr-2">
                  <XCircleIcon
                    onClick={(e) => clearSelection(e)}
                    className="h-5 w-5 text-gray-400"
                  />
                </span>
              )}
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {options?.map((option) => (
                  <Listbox.Option
                    key={option.key}
                    className={({ active }) =>
                      classNames(
                        active ? "bg-indigo-600 text-white" : "text-gray-900",
                        "relative cursor-default select-none py-2 pl-3 pr-9"
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? "font-semibold" : "font-normal",
                            "block text-[14px]"
                          )}
                        >
                          {option?.name}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-indigo-600",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </div>
      )}
    </Listbox>
  );
};
