import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Spinner } from "components/UI/Spinner";
import { useAppDispatch } from "services/store/store";
import {
  fetchCandidateById,
  fetchCandidatesDictionaries,
  getCandidateHistory,
} from "services/store/modules/candidates/action";
import { getCompanySettings } from "services/store/modules/company";
import { selectCandidate } from "services/store/modules/candidates/selectors";
import { FormCandidate } from "./components/FormCandidate";
import { RouteParams } from "common/commonTypes/types";
import { useFetchUsers } from "common/hooks/users/useFetchUsers";

import "./styles.scss";

type Props = {
  className?: string;
};

export const Candidate: React.FC<Props> = (props) => {
  const dispatch = useAppDispatch();
  const { fetchUsers } = useFetchUsers();

  const { id } = useParams<RouteParams>();

  const { candidate, loading } = useSelector(selectCandidate);

  useEffect(() => {
    Promise.all([
      dispatch(fetchCandidateById(id)),
      dispatch(getCandidateHistory(id)),
      dispatch(fetchCandidatesDictionaries()),
      dispatch(getCompanySettings()),
      fetchUsers(),
    ]);
  }, [dispatch, id]);

  return (
    <div className="candidate-container">
      {loading ? (
        <Spinner className="candidate-container-spinner" loading={loading} />
      ) : (
        candidate.id && (
          <FormCandidate className="form-candidate-scrollable global-scrollbar" />
        )
      )}
    </div>
  );
};
