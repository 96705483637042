import { gql } from "@apollo/client";

export const CANDIDATE_SUITABLE_LIST = gql`
  query candidateSuitableList(
    $filter: CandidateSuitableFilterType
    $pagination: PaginationType
  ) {
    candidateSuitableList(filter: $filter, pagination: $pagination) {
      ... on CandidateListSuccess {
        data {
          actualAddress {
            block
            blockType
            city
            cityType
            house
            lat
            region
            value
            streetType
            street
            settlement
            regionType
            lng
            houseType
          }
          age
          badge
          calcDismissDate
          callCompleted
          walkingDuration
          transitDuration
          cardDeliveryAddress {
            block
            blockType
            city
            cityType
            house
            houseType
            lat
            lng
            region
            regionType
            settlement
            street
            streetType
            value
          }
          contacts {
            contact
            id
            isMain
          }
          comments {
            comment
            createdAt
            manager {
              city
              companyId
              departmentId
              email
              firstName
              id
              phone
              role
              secondName
            }
          }
          citizenshipId
          city
          companyId
          createdAt
          dateOfBirth
          deletedAt
          departmentId
          dismissDate
          dismissReason
          driverLicense
          email
          experienceFull
          experienceInCompany
          firstName
          fullName
          hasChat
          hasHistory
          haveCar
          id
          inBlacklist
          isSpam
          timezone
          lastShiftRate
          managerComment
          managerId
          metro {
            distance
            line
            name
          }
          ownerId
          patronymic
          positionId
          positions {
            key
          }
          positionsNew {
            createdAt
            id
            position {
              categoryId
              id
              key
              name
              restriction
            }
          }
          receptionLastDate
          region
          russianCitizenship
          salaryExpectation
          secondName
          sex
          shiftMaxDate
          source
          shiftMinDate
          status
          updatedAt
          updatedBy
        }
        meta {
          limit
          offset
          total
        }
      }
      ... on Error {
        errors
        statusCode
        message
      }
    }
  }
`;
