import React from "react";
import { Avatar, Col, Row, Typography } from "antd";

const { Title } = Typography;

const ChatHeader = ({ fullName }) => {
  return (
    <div
      className="header"
      style={{
        background:
          "linear-gradient(268.66deg, #DAE2FF -16.58%, #F4EFFF 108.92%)",
        borderRadius: "8px 8px 0 0",
      }}
    >
      <Row align="middle">
        <Col>
          <Avatar
            size={44}
            style={{ marginRight: 10, background: "none", padding: "5px" }}
          >
            <svg
              width="40"
              height="40"
              viewBox="0 0 34 34"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="17" cy="17" r="17" fill="#253A84" />
              <path
                d="M16.049 7.92664C16.3483 7.00537 17.6517 7.00538 17.951 7.92664L19.4699 12.6005C19.6038 13.0125 19.9877 13.2915 20.4209 13.2915L25.3354 13.2917C26.3041 13.2917 26.7068 14.5313 25.9232 15.1007L21.9474 17.9895C21.5969 18.2441 21.4503 18.6955 21.5841 19.1075L23.1026 23.7815C23.4019 24.7028 22.3475 25.4689 21.5638 24.8995L17.5878 22.011C17.2373 21.7564 16.7627 21.7564 16.4122 22.011L12.4362 24.8995C11.6525 25.4689 10.5981 24.7028 10.8974 23.7815L12.4159 19.1075C12.5497 18.6955 12.4031 18.2441 12.0526 17.9895L8.07683 15.1007C7.29317 14.5313 7.69592 13.2917 8.66461 13.2917L13.5791 13.2915C14.0123 13.2915 14.3962 13.0125 14.5301 12.6005L16.049 7.92664Z"
                stroke="white"
                strokeWidth="2"
              />
            </svg>
          </Avatar>
        </Col>
        <Col>
          <div style={{ lineHeight: "0.5" }}>
            <Title style={{ marginBottom: "5px" }} level={5}>
              {fullName}
            </Title>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default ChatHeader;
