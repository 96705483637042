import {
  createSlice,
  createAsyncThunk,
  combineReducers,
} from "@reduxjs/toolkit";
import axios from "axios";
import {
  AddressData,
  RecommendationParams,
  RecommendationVacancyResponseArray,
} from "./types";
import { ItemState } from "services/store/types";
import { SuitableVacancyListSuccess } from "graphql/types/types";
import { fetchVacancySuitableList } from "./actions";

const recomApiUrl = "https://api.recommendation.hrb.software/api/v1";
const username = "recom_123";
const password = "recom_321";

export const validateAddress = createAsyncThunk<
  RecommendationVacancyResponseArray,
  RecommendationParams,
  { dispatch: any }
>(
  "address/validate",
  async (params: RecommendationParams, { dispatch, rejectWithValue }) => {
    let response1;
    let response2;
    try {
      const response = await axios.get<AddressData[]>(
        `${recomApiUrl}/validateAddress`,
        {
          headers: {
            Authorization: `Basic ${btoa(`${username}:${password}`)}`,
          },
          params: {
            address: params.address,
          },
        }
      );

      const { address: addressResponse, lat, long } = response.data[0];
      dispatch(addressSlice.actions.setAddress(addressResponse));

      if (params.mode !== "mixed") {
        const secondResponse =
          await axios.get<RecommendationVacancyResponseArray>(
            `${recomApiUrl}/nearestVacancies`,
            {
              headers: {
                Authorization: `Basic ${btoa(`${username}:${password}`)}`,
              },
              params: {
                address: addressResponse,
                lat: lat,
                long: long,
                mode: params.mode,
              },
            }
          );

        return secondResponse.data;
      } else {
        try {
          response1 = await axios.get<RecommendationVacancyResponseArray>(
            `${recomApiUrl}/nearestVacancies`,
            {
              headers: {
                Authorization: `Basic ${btoa(`${username}:${password}`)}`,
              },
              params: {
                address: addressResponse,
                lat: lat,
                long: long,
                mode: "walking",
              },
            }
          );
        } catch (error) {
          response1 = error;
        }
        try {
          response2 = await axios.get<RecommendationVacancyResponseArray>(
            `${recomApiUrl}/nearestVacancies`,
            {
              headers: {
                Authorization: `Basic ${btoa(`${username}:${password}`)}`,
              },
              params: {
                address: addressResponse,
                lat: lat,
                long: long,
                mode: "transit",
              },
            }
          );
        } catch (error) {
          response2 = error;
        }
        if (response1 instanceof Error && response2 instanceof Error) {
          return rejectWithValue(
            `К сожалению, в данный момент мы не можем порекомендовать вакансии в вашем городе (${addressResponse}).`
          );
        } else if (response1 instanceof Error) {
          const transformedDataArray = response2.data.map((curr) => ({
            distance: curr.distance,
            externalLink: curr.external_link,
            salary: curr.salary,
            vacancyName: curr.vacancy_name,
            transitDuration: curr.duration,
            address: curr.postal_address,
            id: curr.id,
            lat: curr.lat,
            lng: curr.long,
          }));

          return transformedDataArray;
        } else if (response2 instanceof Error) {
          const transformedDataArray = response1.data.map((curr) => ({
            distance: curr.distance,
            externalLink: curr.external_link,
            salary: curr.salary,
            vacancyName: curr.vacancy_name,
            walkingDuration: curr.duration,
            address: curr.postal_address,
            id: curr.id,
            lat: curr.lat,
            lng: curr.long,
          }));
          return transformedDataArray;
        } else {
          const data1 = response1.data;
          const data2 = response2.data;
          const combinedData: RecommendationVacancyResponseArray = [
            ...data1,
            ...data2,
          ];

          const transformedData = combinedData.reduce((acc, curr) => {
            const key = curr.external_link;

            if (!acc[key]) {
              acc[key] = {
                distance: curr.distance,
                externalLink: curr.external_link,
                salary: curr.salary,
                vacancyName: curr.vacancy_name,
                walkingDuration: 0,
                transitDuration: 0,
                address: curr.postal_address,
                id: curr.id,
                lat: curr.lat,
                lng: curr.long,
              };
            }
            if (curr.mode === "walking") {
              acc[key].walkingDuration = curr.duration;
            } else if (curr.mode === "transit") {
              acc[key].transitDuration = curr.duration;
            }

            return acc;
          }, {});

          const result = Object.values(transformedData);

          return result;
        }
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        return rejectWithValue(error.response.data.error);
      }
      return rejectWithValue(error?.response?.data?.error);
    }
  }
);

const addressSlice = createSlice({
  name: "address",
  initialState: {
    address: "",
    data: {} as RecommendationVacancyResponseArray,
    loading: true,
    error: null as string | null,
  },
  reducers: {
    clearData: (state) => {
      state.address = "";
      state.data = {} as RecommendationVacancyResponseArray;
      state.error = null;
      state.loading = false;
    },
    setAddress: (state, action) => {
      state.address = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(validateAddress.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(validateAddress.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(validateAddress.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
        state.data = {} as RecommendationVacancyResponseArray;
      });
  },
});

type VacancySuitableListState = ItemState<SuitableVacancyListSuccess | null>;

const initialState: VacancySuitableListState = {
  data: null,
  loading: true,
  error: null,
};

const vacancySuitableListSlice = createSlice({
  name: "vacancySuitableList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchVacancySuitableList.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchVacancySuitableList.fulfilled, (state, { payload }) => {
        state.data = payload.vacancySuitableList;
        state.loading = false;
      })
      .addCase(fetchVacancySuitableList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message as string;
      });
  },
});

export const { clearData, setAddress } = addressSlice.actions;

export const recommendationsReducer = combineReducers({
  address: addressSlice.reducer,
  vacancySuitableList: vacancySuitableListSlice.reducer,
});
