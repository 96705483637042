import { Suspense, useEffect } from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Switch } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";

import store from "./services/store/store";
import { client } from "graphql/apollo";
import { routes } from "routes";
import PreparedRoute from "./common/routes/PreparedRoute";
import DictionaryLoader from "./dictionary/DictionaryLoader";
import { ErrorBoundary } from "components/ErrorBoundary";
import { FallbackLoading } from "components/UI/FallbackLoading";
import { initializeDomainLinker } from "common/helpers/cookies";

import "./resert.scss";
import "./App.scss";
import "./assets/styles/variables.css";
import "react-toastify/dist/ReactToastify.css";

export const App = () => {
  useEffect(() => {
    initializeDomainLinker(); // Настройка правила сопоставления доменов
  }, []);

  return (
    <ErrorBoundary>
      <BrowserRouter>
        <Provider store={store}>
          <ApolloProvider client={client}>
            <Suspense fallback={<FallbackLoading />}>
              <DictionaryLoader />
              <Switch>
                {routes.map((route, index) => (
                  <PreparedRoute key={index} {...route} />
                ))}
              </Switch>
            </Suspense>
          </ApolloProvider>
        </Provider>
      </BrowserRouter>
    </ErrorBoundary>
  );
};

export default App;
