import React, { useState, useLayoutEffect } from "react";
import moment from "moment/moment";
import { useSelector } from "react-redux";
import {
  selectCandidate,
  selectPositionNameById,
  selectReplies,
} from "../../../services/store/modules/candidates/selectors";
import OffsetPagination from "../../OffsetPagination";
import { useAppDispatch } from "../../../services/store/store";
import {
  fetchReplyListByCandidate,
  transferCandidate,
} from "services/store/modules/candidates/action";
import { PaperAirplaneIcon } from "@heroicons/react/24/outline";
import Modal from "../../UI/Modals/Modal";
import { RootState } from "../../../services/store/rootReducer";
import { selectChats } from "../../../services/store/modules/chats/selectors";
import { updateManagerInChat } from "../../../services/store/modules/chats";
import { ReplyType } from "../../../graphql/types/types";

const CandidateFormTables: React.FC = () => {
  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState(1);

  const { data } = useSelector(selectReplies);
  const positionNameById = useSelector(selectPositionNameById);
  const { candidate } = useSelector(selectCandidate);
  const chats = useSelector((state: RootState) => selectChats(state));
  const chatId = chats[candidate?.id]?.id;

  const [reply, setReply] = useState<ReplyType | null>(null);

  const onPageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };
  const handlerConfirmTransfer = () => {
    if (reply) {
      dispatch(transferCandidate(reply.id));
      if (chatId) {
        const payload = {
          chat_id: chatId,
          manager_id: reply?.ownerId,
        };
        dispatch(updateManagerInChat(payload));
      }
    }
    setReply(null);
  };

  useLayoutEffect(() => {
    if (candidate.id) {
      dispatch(
        fetchReplyListByCandidate({
          id: candidate.id,
          pagination: { limit: 5, offset: (currentPage - 1) * 5 },
        })
      );
    }
  }, [candidate, currentPage, dispatch]);

  const handlerRowClick = (vacancyId) => {
    window.open(`/vacancy/${vacancyId}`, "_blank");
  };

  return (
    <div className="p-4">
      <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-500 uppercase"
              >
                Должность
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-500 uppercase"
              >
                Город
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-500 uppercase"
              >
                Дата
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-500 uppercase"
              >
                Менеджер
              </th>
              <th scope="col" className="text-gray-500"></th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {data?.data?.map((item) => (
              <tr
                key={item.id}
                onClick={() => handlerRowClick(item.vacancyId)}
                className={`cursor-pointer ${item.isMain && "bg-blue-100"}`}
              >
                <td className="py-4 pl-4 pr-3 text-sm font-semibold text-indigo-500">
                  {positionNameById(item.positionId)}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {item.address?.city || "Не указан"}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {moment(item?.createdAt).format("DD.MM.YYYY")}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {item.owner?.firstName} {item.owner?.secondName}
                </td>
                <td
                  className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  {!item.isMain && (
                    <PaperAirplaneIcon
                      className="w-5 h-5 cursor-pointer"
                      onClick={() => setReply(item)}
                    />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {data?.meta?.total > 5 && (
          <OffsetPagination
            value={Math.ceil((data?.meta?.offset || 0) / 5) + 1}
            pageCount={Math.ceil((data?.meta?.total || 0) / 5)}
            clickHandler={(newPage) => onPageChange(newPage)}
            defaultSizeValue={5}
            size={5}
          />
        )}
      </div>
      {reply && (
        <Modal
          open={!!reply}
          onClose={() => setReply(null)}
          message={`Кандидат будет передан на другую вакансию`}
          title="Передача кандидата"
          onConfirm={handlerConfirmTransfer}
        />
      )}
    </div>
  );
};

export default CandidateFormTables;
