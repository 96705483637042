import { gql } from "@apollo/client";

export const CANDIDATE = gql`
  query candidate($id: ID!) {
    candidate(id: $id) {
      ... on CandidateType {
        id
        email
        actualAddress {
          block
          blockType
          city
          cityType
          house
          houseType
          lat
          lng
          region
          regionType
          settlement
          street
          streetType
          value
        }
        age
        badge
        calcDismissDate
        callCompleted
        cardDeliveryAddress {
          block
          blockType
          city
          cityType
          house
          houseType
          lat
          lng
          region
          regionType
          settlement
          street
          streetType
          value
        }
        citizenshipId
        city
        comments {
          comment
          createdAt
          manager {
            city
            companyId
            departmentId
            email
            firstName
            id
            phone
            role
            secondName
          }
        }
        companyId
        contacts {
          contact
          id
          isMain
        }
        createdAt
        dateOfBirth
        deletedAt
        departmentId
        dismissDate
        dismissReason
        driverLicense
        experienceFull
        experienceInCompany
        firstName
        fullName
        hasChat
        hasHistory
        haveCar
        inBlacklist
        isSpam
        lastShiftRate
        location {
          lat
          lng
        }
        managerComment
        managerId
        metro {
          distance
          line
          name
        }
        ownerId
        patronymic
        positionId
        positions {
          key
        }
        positionsNew {
          createdAt
          id
          position {
            categoryId
            id
            key
            name
            restriction
          }
        }
        receptionLastDate
        region
        russianCitizenship
        salaryExpectation
        secondName
        sex
        shiftMaxDate
        shiftMinDate
        timezone
        source
        status
        transitDuration
        vacancyCount
        updatedAt
        updatedBy
        updater {
          city
          companyId
          departmentId
          email
          firstName
          id
          phone
          role
          secondName
        }
        walkingDuration
      }
      ... on Error {
        errors
        statusCode
        message
      }
    }
  }
`;
