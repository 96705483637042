import React from "react";

import { CandidateHistoryResponse } from "api/history";
import { Title } from "components/UI/Title";
import Text from "components/UI/Text";
import { COLOR_BY_CANDIDATE_STATUS } from "common/const/colors";
import { CANDIDATE_STATUS_TYPE } from "common/const/status";
import { useAppSelector } from "services/store/store";
import { getDateFormat } from "common/utils/date";
import { selectUsersById } from "services/store/modules/user/selectors";

import "./styles.scss";

interface CandidateHistoryProps {
  candidateHistory: CandidateHistoryResponse;
}

export const CandidateHistory: React.FC<CandidateHistoryProps> = ({
  candidateHistory,
}) => {
  const usersById = useAppSelector(selectUsersById);
  return (
    <>
      <div className="candidate-history global-scrollbar">
        <Title type="h4" color="grey">
          Активность
        </Title>
        {candidateHistory.items.map(
          ({ id, old_value, created_at, new_value, user_id }) => {
            const user = usersById[user_id];
            return (
              <div className="candidate-history-item" key={id}>
                <Text className="candidate-history-item-text">
                  Смена статуса
                </Text>
                <span
                  className="candidate-history-item-status"
                  style={{ color: COLOR_BY_CANDIDATE_STATUS[old_value] }}
                >
                  {CANDIDATE_STATUS_TYPE[old_value]}
                </span>
                →
                <span
                  className="candidate-history-item-status"
                  style={{ color: COLOR_BY_CANDIDATE_STATUS[new_value] }}
                >
                  {`${CANDIDATE_STATUS_TYPE[new_value]}`}
                </span>
                <Text className="candidate-history-item-manager">
                  {user
                    ? `${user.firstName} ${user.secondName}`
                    : "Система CRM Rabotut"}
                </Text>
                <Text className="candidate-history-item-date">
                  {getDateFormat(created_at, "DD.MM.YY HH:mm")}
                </Text>
              </div>
            );
          }
        )}
      </div>
    </>
  );
};
