import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { ContentLayout } from "layouts/ContentLayout";
import { Vacancy } from "components/redezign/Vacancy";
import { useAppDispatch, useAppSelector } from "services/store/store";

import { fetchVacancy } from "services/store/modules/vacancy/actions";
import { PaginationPayload } from "components/UI/Pagination";
import { RouteParams } from "common/commonTypes/types";
import {
  selectVacancyData,
  selectVacancy,
} from "services/store/modules/vacancy/selectors";
import { NotificationStatus } from "components/UI/Notifications/Notification";
import {
  fetchCandidatesDictionaries,
  fetchCandidateSuitableList,
} from "services/store/modules/candidates/action";
import { fetchPositionsRelations } from "services/store/modules/settings/actions";
import { getCompanySettings } from "services/store/modules/company";

export const PAGINATION = { offset: 0, limit: 20 };

export const VacancyPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { id: vacancyId } = useParams<RouteParams>();

  const vacancy = useAppSelector(selectVacancyData);
  const { loading, error } = useAppSelector(selectVacancy);

  const getCandidateList = (pagination: PaginationPayload = PAGINATION) => {
    dispatch(fetchCandidateSuitableList({ pagination, filter: { vacancyId } }));
  };

  useEffect(() => {
    Promise.all([
      dispatch(fetchVacancy({ vacancyId })),
      dispatch(fetchCandidatesDictionaries()),
      dispatch(fetchPositionsRelations()),
      dispatch(getCompanySettings()),
    ]);
  }, [dispatch, vacancyId]);

  return (
    <ContentLayout
      centralColumn={{
        content: (
          <>
            {vacancy ? (
              <Vacancy
                vacancyId={vacancyId}
                vacancy={vacancy}
                getCandidateList={getCandidateList}
              />
            ) : (
              <NotificationStatus
                loading={loading}
                error={error}
                notFoundText="Вакансия не найдена"
              />
            )}
          </>
        ),
      }}
    />
  );
};
