import React, { useState } from "react";
import { useHistory } from "react-router";

import { Card } from "components/UI/Card";
import { Report } from "components/UI/icons";
import { ViewMode, ViewModeToggle } from "components/ViewModeToggle";
import { ROUTE } from "routes";
import { ReportName } from "common/const/reports";

import "./styles.scss";

export const Reports: React.FC = () => {
  const history = useHistory();

  const [mode, setMode] = useState<ViewMode>("grid");

  const handleClick = () => {
    history.push(`${ROUTE.REPORT}/${ReportName.ReportCallCenter}`);
  }

  return (
    <div className="reports">
      <div className="reports-header">
        <ViewModeToggle mode={mode} onChangeMode={(mode) => setMode(mode)} />
      </div>

      <Card
        mode={mode}
        text="Проверка вакансий КЦ"
        icon={<Report />}
        onClick={handleClick}
      />
    </div>
  );
};
