import { createSelector } from "reselect";
import { RootState } from "../../rootReducer";
import { selectVacancyData } from "services/store/modules/vacancy/selectors";
import { VacancySuitableType } from "graphql/types/types";

export const selectRecommendations = (state: RootState) =>
  state.recommendations;

export const selectAddress = createSelector(
  selectRecommendations,
  (state) => state.address
);
export const selectVacancySuitableList = createSelector(
  selectRecommendations,
  (state) => state.vacancySuitableList
);
export const selectVacancySuitableListMeta = createSelector(
  selectVacancySuitableList,
  (state) => state.data?.meta
);
export const selectVacancySuitableListData = createSelector(
  selectVacancySuitableList,
  (state) => state.data?.data ?? []
);

export const selectFilteredVacancySuitable = createSelector(
  selectVacancySuitableListData,
  selectVacancyData,
  (state, vacancy) => {
    const filteredState = [...state];
    const foundIndex = state.findIndex(({ id }) => vacancy?.id === id);

    if (foundIndex !== -1) {
      filteredState.unshift(...filteredState.splice(foundIndex, 1));
    } else if (vacancy && state.length) {
      filteredState.unshift(vacancy as VacancySuitableType);
    }

    return filteredState;
  }
);
