export const ROLE_TYPES = {
  ADMIN: "ADMIN",
  MANAGER: "MANAGER",
  OWNER: "OWNER",
  MODERATOR: "MODERATOR",
  HRBP: "HRBP",
  EMPLOYEE_CC: "EMPLOYEE_CC",
};

export const ROLE: IRole = {
  [ROLE_TYPES.ADMIN]: "Суперадминистратор",
  [ROLE_TYPES.MANAGER]: "Менеджер",
  [ROLE_TYPES.OWNER]: "Владелец",
  [ROLE_TYPES.MODERATOR]: "Супермодератор",
  [ROLE_TYPES.HRBP]: "HR BP",
  [ROLE_TYPES.EMPLOYEE_CC]: "Сотрудник КЦ",
};

export type IRole = {
  [key in string]: string;
};
