import { createAsyncThunk } from "@reduxjs/toolkit";

import { AsyncThunkConfig } from "services/store/types";
import { ReportCallCenterPayload, ReportCallCenterResponse, fetchReport } from "api/reports";

export const getReportCallCenter = createAsyncThunk<
  ReportCallCenterResponse,
  ReportCallCenterPayload,
  AsyncThunkConfig
>(
  "reports/getReportCallCenter",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await fetchReport<ReportCallCenterResponse>(payload);
      
      return response.data;
    } catch (err) {
      return rejectWithValue("Ошибка получения отчета");
    }
  }
);