import React from "react";

type Props = {
  width?: number;
  height?: number;
  color?: string;
};

export const UserCircle: React.FC<Props> = ({
  width = 24,
  height = 24,
  color = "#A6ACB9",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3831_6278)">
        <path
          d="M13.9191 14.6616C13.1741 12.6688 11.2527 11.25 9 11.25C6.74728 11.25 4.82595 12.6688 4.08087 14.6616M13.9191 14.6616C15.5004 13.2866 16.5 11.26 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 11.26 2.49964 13.2866 4.08087 14.6616M13.9191 14.6616C12.6023 15.8067 10.8821 16.5 9 16.5C7.11789 16.5 5.3977 15.8067 4.08087 14.6616"
          stroke={color}
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
        <circle
          cx="2.25"
          cy="2.25"
          r="2.25"
          transform="matrix(1 0 0 -1 6.75 9)"
          stroke={color}
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3831_6278">
          <rect width="18" height="18" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};
