import { UserRole } from "graphql/types/types";

export const isAdmin = (userRole: UserRole) => userRole === UserRole.Admin;

export const isOwners = (userRole: UserRole) => [UserRole.Admin, UserRole.Owner].includes(userRole);

export const isModerators = (userRole: UserRole) => [UserRole.Hrbp, UserRole.Admin, UserRole.Moderator].includes(userRole);

export const isEditor = (userRole: UserRole) => [
  UserRole.Admin,
  UserRole.Owner,
  UserRole.Hrbp,
  UserRole.Moderator,
].includes(userRole);
