import React from "react";

type Props = React.SVGAttributes<SVGElement> & {
  width?: number;
  height?: number;
  color?: string;
};

export const ChevronForward: React.FC<Props> = () => {
  return (
    <svg
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1064_10020)">
        <path
          d="M1.5 1.5L6.5 6L1.5 10.5"
          stroke="#0057AC"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1064_10020">
          <rect width="8" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
