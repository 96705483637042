import moment, { Moment } from "moment";
import "moment/locale/ru";

type Format =
  | "DD.MM.YYYY"
  | "YY-MM-DD"
  | "DD.MM.YY"
  | "YYYY-MM-DD"
  | "DD.MM.YY HH:mm"
  | "D MMMM YYYY";

moment.locale("ru");

export const getDateFormat = (date: string | Date | Moment, format: Format) =>
  moment(date).format(format);
