import React, { useCallback, useRef, useState } from "react";
import cn from "classnames/dedupe";

import { useAppDispatch } from "services/store/store";
import { useHover } from "common/hooks/useHover";
import { useToggle } from "common/hooks/useToggle";
import { useDebounceCallback } from "common/hooks/useDebounceCallback";
import Input from "components/UI/Input";
import { IconStatus, Status } from "components/UI/iconStatus";
import { Edit, Close, CornerUpRight } from "components/UI/icons";
import toaster from "components/UI/Notifications/Notification";
import { PositionType } from "graphql/types/types";
import { setPositionCountPeople } from "services/store/modules/directory";
import { updatePositionCount } from "services/store/modules/company";

import "./styles.scss";

type Props = {
  classNames?: string;
  countPeople?: PositionType["countPeople"];
  id: PositionType["id"];
  isOpen: () => void;
  setModalMessage: (message: string) => void;
};

export const FieldPositionCount: React.FC<Props> = ({
  classNames,
  countPeople,
  id,
  isOpen,
  setModalMessage,
}) => {
  const dispatch = useAppDispatch();

  const positionRef = useRef<HTMLDivElement>(null);

  const isHover = useHover(positionRef);
  const [isEdit, toggleIsEdit] = useToggle(false);

  const [value, setValue] = useState(String(countPeople) ?? "");
  const [status, setStatus] = useState<Status | null>(null);

  const debounceStatus = useDebounceCallback(setStatus, 3000);

  const handleEnterClick = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== "Enter") {
      return;
    }
    updatePosition();
  };

  const updatePosition = useCallback(async () => {
    setStatus("pending");

    const payload = {
      id: Number(id),
      count: Number(value),
    };
    try {
      const result = await dispatch(updatePositionCount(payload)).unwrap();

      if (!result) {
        setStatus("error");
        return;
      }
      "updatePositionCount" in result &&
      result?.updatePositionCount?.countPeopleNew
        ? setModalMessage(
            "Константа будет изменена в 2.00 по Московскому времени"
          )
        : setModalMessage("Константа изменена");

      isOpen();

      dispatch(setPositionCountPeople(payload));
      setStatus("success");
    } catch (error) {
      setStatus("error");
      toaster.error({ title: `Ошибка: ${error.message}` });
    } finally {
      toggleIsEdit();
      debounceStatus(null);
    }
  }, [id, value, debounceStatus, dispatch, toggleIsEdit]);

  const classNameList = cn("field-count", classNames, {
    "field-count-edit": isEdit,
    "field-count-hover": isHover,
  });

  const saveIcon = (
    <span
      className="field-count-icon"
      onClick={(e) => {
        e.stopPropagation();
        updatePosition();
      }}
    >
      <CornerUpRight />
    </span>
  );

  const inputCount = isEdit ? (
    <Input
      className="field-count-input"
      value={value}
      suffix={saveIcon}
      onChange={(e) => setValue(e.target.value)}
      onKeyDown={handleEnterClick}
      onClick={(e) => e.stopPropagation()}
    />
  ) : (
    <span className="field-count-value">{countPeople}</span>
  );

  return (
    <div className={classNameList}>
      {inputCount}

      {!status ? (
        <span className="field-count-icon" onClick={toggleIsEdit}>
          {isEdit ? (
            <Close width={16} height={16} />
          ) : (
            <span className="field-count-icon--edit">
              <Edit width={16} height={16} />
            </span>
          )}
        </span>
      ) : (
        <span className="field-count-icon">
          <IconStatus status={status} />
        </span>
      )}
    </div>
  );
};
