import Cookies from "js-cookie";

export default function getCookie(name: string) {
  let matches = document.cookie.match(
    new RegExp(
      /* eslint-disable */
      "(?:^|; )" +
        name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") +
        "=([^;]*)"
    )
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

export const initializeDomainLinker = () => {
  const host = window.location.hostname;
  const cookieDomain = "auto";
  const cookieFlags = "secure; samesite=none"; // Необходимо для работы на HTTPS
  const cookieConfig = `domains=${host}; cookieflags=${cookieFlags}; cookieSecure=${
    cookieFlags.includes("secure") ? "true" : "false"
  }`;

  document.cookie = `_gl=${window.dataLayer} ${cookieConfig}; domain=${cookieDomain}; path=/`;
  document.cookie = `_gl=${window.dataLayer} ${cookieConfig}; domain=.${cookieDomain}; path=/`;
};

export const parseJwt = (token: string) => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export const removeCookie = (cookieName: string) => {
  const rootDomain = process.env.REACT_APP_ROOT_DOMAIN ?? "";
  let domain = "";

  if (rootDomain.indexOf(":") >= 0) {
    [domain] = rootDomain.split(":");
  } else {
    domain = rootDomain;
  }
  Cookies.remove(cookieName, { domain: domain });
};
