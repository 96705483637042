import React, { useCallback, useRef, useState } from "react";
import cn from "classnames/dedupe";

import { useAppDispatch, useAppSelector } from "services/store/store";
import {
  CandidateType,
  CandidatePositionType,
  MutationUpdateCandidateArgs,
} from "graphql/types/types";
import { useHover } from "common/hooks/useHover";
import { useToggle } from "common/hooks/useToggle";
import { Edit, Close, CornerUpRight } from "components/UI/icons";
import {
  setCandidate,
  updateCandidateInList,
} from "services/store/modules/candidates";
import { updateCandidate } from "services/store/modules/candidates/action";
import { positions as positionsList } from "services/store/modules/directory";
import { filterOption } from "components/UI/Select/helpers";
import Select from "components/UI/Select";
import { useDebounceCallback } from "common/hooks/useDebounceCallback";
import { getUpdatedCandidatePayload } from "../../helpers";
import { IconStatus, Status } from "components/UI/iconStatus";
import { fetchVacancySuitableList } from "services/store/modules/recommendations/actions";
import { CandidatesTableMode } from "components/CandidatesTable";
import { PAGINATION } from "common/const/pagination";
import { updateCandidateInReply } from "services/store/modules/repliesList";
import { updateCandidateInClaim } from "services/store/modules/claimsList";

import "./styles.scss";

type Props = {
  positions: CandidatePositionType[];
  candidate: CandidateType;
  mode?: CandidatesTableMode;
  className?: string;
};

export const FieldPosition: React.FC<Props> = ({
  positions,
  candidate,
  mode,
  className,
}) => {
  const dispatch = useAppDispatch();

  const vacancyPositions = useAppSelector(positionsList);

  const positionRef = useRef<HTMLDivElement>(null);

  const isHover = useHover(positionRef);
  const [isEdit, toggleIsEdit] = useToggle(false);

  const [value, setValue] = useState(() =>
    positions.map(({ position }) => position?.id ?? 0)
  );
  const [status, setStatus] = useState<Status | null>(null);

  const debounceStatus = useDebounceCallback(setStatus, 3000);

  const handleChangeEditClick = (e: React.SyntheticEvent<HTMLSpanElement>) => {
    e.stopPropagation();
    toggleIsEdit();
  };

  const updateCandidateForm = useCallback(async () => {
    setStatus("pending");
    // TODO: если будет задача с этим компонентом, провести рефакторинг, вынести метод обновления кандидата наверх в родителя.
    const payload: MutationUpdateCandidateArgs = {
      form: {
        ...getUpdatedCandidatePayload(candidate),
        positionsNew: value,
      },
      id: candidate.id,
    };

    try {
      const result = await dispatch(updateCandidate(payload)).unwrap();

      if (!result?.updateCandidate) {
        setStatus("error");
        return;
      }

      dispatch(setCandidate(result.updateCandidate));
      dispatch(updateCandidateInList(result.updateCandidate));
      dispatch(updateCandidateInReply(result.updateCandidate));
      dispatch(updateCandidateInClaim(result.updateCandidate));
      // TODO: не должно быть бизнес логики других сущностей внутри FieldPosition таблицы кандидатов
      if (mode === "reply") {
        dispatch(
          fetchVacancySuitableList({
            filter: { candidateId: candidate.id },
            pagination: PAGINATION.RECOMMENDED,
          })
        );
      }
      setStatus("success");
    } catch {
      setStatus("error");
    } finally {
      toggleIsEdit();
      debounceStatus(null);
    }
  }, [candidate, debounceStatus, dispatch, mode, toggleIsEdit, value]);

  const saveIcon = (
    <span
      className="field-position-icon"
      onClick={(e) => {
        e.stopPropagation();
        updateCandidateForm();
      }}
    >
      <CornerUpRight />
    </span>
  );

  return (
    <div
      className={cn("field-position", className)}
      ref={mode !== "recommended" ? positionRef : undefined}
    >
      {isEdit ? (
        <Select
          size="middle"
          mode="multiple"
          maxTagCount="responsive"
          className="field-position-select"
          value={value}
          options={vacancyPositions.map(({ id, name }) => ({
            label: name,
            value: id,
          }))}
          showSearch={true}
          optionFilterProp="children"
          maxTagPlaceholder={(tags) => `Ещё ${tags.length}`}
          filterOption={filterOption}
          suffixIcon={saveIcon}
          showArrow={true}
          onChange={(v) => setValue(v)}
          onClick={(e) => e.stopPropagation()}
        />
      ) : (
        <div
          className="field-position-short"
          title={positions.map(({ position }) => position?.name).join(", ")}
        >
          {positions.map(({ position, id }) => (
            <span key={id}>{position ? `${position.name}, ` : ""}</span>
          ))}
        </div>
      )}
      {(isHover || isEdit) && !status && (
        <span className="field-position-icon" onClick={handleChangeEditClick}>
          {isEdit ? (
            <Close width={16} height={16} />
          ) : (
            <Edit width={16} height={16} />
          )}
        </span>
      )}
      {status && (
        <span className="field-position-icon">
          <IconStatus status={status} />
        </span>
      )}
    </div>
  );
};
