import React, { Fragment } from "react";
import { Transition } from "@headlessui/react";
import { useSelector } from "react-redux";
import {
  deleteNotification,
  fetchNotifications,
} from "../../../services/store/modules/chats";
import { useAppDispatch } from "../../../services/store/store";
import { useHistory } from "react-router-dom";
import { selectNotification } from "../../../services/store/modules/chats/selectors";
import { BellIcon } from "@heroicons/react/24/solid";
import { authInfo } from "../../../services/store/modules/auth";

export default function NotificationChat() {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const authData = useSelector(authInfo);
  const { notifies, count } = useSelector(selectNotification);

  const managerID = authData?.id || null;

  const handlerNotificationClick = (chatId, candidateId) => {
    dispatch(deleteNotification(chatId));
    history.push({
      pathname: `/candidates/${candidateId}`,
    });
    if (managerID) {
      dispatch(fetchNotifications(managerID));
    }
  };

  return (
    <>
      {notifies?.[0] && (
        <div
          aria-live="assertive"
          className="fixed inset-x-0 bottom-0 flex justify-start p-6 pointer-events-none sm:p-6"
        >
          <div className="flex w-full flex-col items-center space-y-4 sm:items-start">
            <Transition
              show={true}
              as={Fragment}
              enter="transform ease-out duration-300 transition"
              enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
              enterTo="translate-y-0 opacity-100 sm:translate-x-0"
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="pointer-events-auto flex w-full max-w-md rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                <div className="w-0 flex-1 p-4">
                  <div className="flex items-start">
                    <div className="flex-shrink-0 pt-0.5">
                      <svg
                        width="40"
                        height="40"
                        viewBox="0 0 34 34"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="17" cy="17" r="17" fill="#253A84" />
                        <path
                          d="M16.049 7.92664C16.3483 7.00537 17.6517 7.00538 17.951 7.92664L19.4699 12.6005C19.6038 13.0125 19.9877 13.2915 20.4209 13.2915L25.3354 13.2917C26.3041 13.2917 26.7068 14.5313 25.9232 15.1007L21.9474 17.9895C21.5969 18.2441 21.4503 18.6955 21.5841 19.1075L23.1026 23.7815C23.4019 24.7028 22.3475 25.4689 21.5638 24.8995L17.5878 22.011C17.2373 21.7564 16.7627 21.7564 16.4122 22.011L12.4362 24.8995C11.6525 25.4689 10.5981 24.7028 10.8974 23.7815L12.4159 19.1075C12.5497 18.6955 12.4031 18.2441 12.0526 17.9895L8.07683 15.1007C7.29317 14.5313 7.69592 13.2917 8.66461 13.2917L13.5791 13.2915C14.0123 13.2915 14.3962 13.0125 14.5301 12.6005L16.049 7.92664Z"
                          stroke="white"
                          strokeWidth="2"
                        />
                      </svg>
                    </div>
                    <div className="ml-3 w-0 flex-1">
                      <p className="text-sm font-medium text-gray-900">
                        {notifies?.[0]?.candidate_name}
                      </p>
                      <p className="mt-1 text-sm text-gray-500">
                        {notifies?.[0]?.text}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex items-start pt-1 pr-1">
                  <BellIcon className="h-5 w-5 text-red-500 mt-1 mr-1" />
                  <span className="text-sm text-red-500 font-bold">
                    {count}
                  </span>
                </div>

                <div className="flex border-l border-gray-200">
                  <button
                    type="button"
                    className="flex w-full items-center justify-center rounded-none rounded-r-lg border border-transparent p-4 text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    onClick={() =>
                      handlerNotificationClick(
                        notifies?.[0]?.chat_id,
                        notifies?.[0]?.candidate_id
                      )
                    }
                  >
                    Reply
                  </button>
                </div>
              </div>
            </Transition>
          </div>
        </div>
      )}
    </>
  );
}
