import Cookies from "js-cookie";

export const getSessionIds = (name: string): string[] => {
  const ids = Cookies.get(name);

  return ids ? JSON.parse(ids) : [];
};

export const setSessionIds = (name: string, id: string) => {
  const sessionIds = getSessionIds(name);
  const ids = new Set(sessionIds);

  ids.add(id);

  Cookies.set(name, JSON.stringify(Array.from(ids)), {
    expires: 0.5,
  });
};
