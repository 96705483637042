import React, { FC, useState } from "react";
import { PhotoIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { deleteFile, uploadFile } from "../../../services/store/modules/chats";
import { useAppDispatch } from "../../../services/store/store";
import { useSelector } from "react-redux";
import { selectFiles } from "../../../services/store/modules/chats/selectors";

interface ImageUploadModalProps {
  handlerHideModal: () => void;
  chatId: string;
  visible: boolean;
  handlerSendMessage: () => void;
}

const ImageUploadModal: FC<ImageUploadModalProps> = ({
  handlerHideModal,
  chatId,
  visible,
  handlerSendMessage,
}) => {
  const dispatch = useAppDispatch();

  const [isDragging, setIsDragging] = useState(false);
  const { thumbnail } = useSelector(selectFiles);

  const handlerDeleteFile = () => {
    dispatch(deleteFile());
  };
  const handlerFileUpload = async (file: any) => {
    try {
      const params: { file: string; chatID: string } = {
        file: file,
        chatID: chatId,
      };
      dispatch(uploadFile(params));
    } catch (error) {
      console.error("Ошибка при загрузке файла:", error);
      throw error;
    }
  };
  const handlerSendFile = () => {
    handlerSendMessage();
    handlerHideModal();
  };

  const handlerInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      await handlerFileUpload(file);
    }
  };

  const handlerDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handlerDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handlerDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(false);

    const files = e.dataTransfer.files;

    if (files && files.length > 0) {
      const file = files[0];
      await handlerFileUpload(file);
    }
  };

  return (
    <div
      className={`fixed z-10 inset-0 overflow-y-auto ${
        visible ? "block" : "hidden"
      }`}
    >
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          className={`fixed inset-0 transition-opacity ${
            visible
              ? "ease-out duration-300 opacity-100"
              : "ease-in duration-200 opacity-0"
          }`}
        >
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>

        <div
          className={`inline-block align-middle bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full ${
            visible
              ? "ease-out duration-300 translate-y-4 sm:translate-y-0 sm:scale-95"
              : "ease-in duration-200 translate-y-20 sm:translate-y-0 sm:scale-95"
          }`}
        >
          <div
            className={`flex justify-center bg-gray-50 m-8 px-4 pt-5 pb-4 sm:p-6 sm:pb-4 ${
              isDragging ? "border-dashed border-2 border-gray-500" : ""
            }`}
            onDragOver={handlerDragOver}
            onDragLeave={handlerDragLeave}
            onDrop={handlerDrop}
          >
            <div className=" text-center">
              <PhotoIcon
                className="ml-28 h-10 w-10 text-gray-500"
                aria-hidden="true"
              />
              <div className="mt-4 flex text-sm leading-5 text-gray-600">
                <label
                  htmlFor="file-upload"
                  className="relative cursor-pointer rounded-md font-semibold text-indigo-600  hover:text-indigo-500"
                >
                  <span>Загрузить файл</span>
                  <input
                    id="file-upload"
                    name="file-upload"
                    type="file"
                    className="sr-only"
                    onChange={handlerInputChange}
                  />
                </label>
                <p className="pl-1">или перетащите в окно</p>
              </div>
              <p className="text-xs leading-5 text-gray-600">
                PNG, JPG up to 10MB
              </p>
            </div>
          </div>
          {thumbnail && (
            <div className="bg-white pb-4 rounded-lg shadow-md mb-4">
              <div className="w-24 h-24 flex justify-center items-center border border-gray-300 mx-auto mb-2 relative p-1">
                <img
                  src={thumbnail}
                  alt="картинка сломана"
                  className="w-16 h-16 object-cover "
                />
                <XMarkIcon
                  className="h-3 w-3 bg-red-500 absolute top-0 right-0 cursor-pointer text-gray-50"
                  onClick={handlerDeleteFile}
                />
              </div>
            </div>
          )}
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <span className="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
              <button
                type="button"
                className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm sm:leading-5"
                onClick={handlerSendFile}
              >
                Отправить
              </button>
            </span>
            <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
              <button
                type="button"
                className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus-ring-offset-2 focus:ring-indigo-500 sm:text-sm sm:leading-5"
                onClick={handlerHideModal}
              >
                Отмена
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageUploadModal;
