import { gql } from "@apollo/client";

export const USER_ITEM = gql`
  query user($id: ID!) {
    user(id: $id) {
      ... on UserType {
        id
        firstName
        secondName
        email
        phone
        role
        tz
        city
        departmentId
        companyId
        department {
          name
          id
          companyId
          ownerId
          owner {
            companyId
            firstName
            secondName
            id
          }
        }
      }
      ... on Error {
        errors
        statusCode
        message
      }
    }
  }
`;
