import { createAsyncThunk } from "@reduxjs/toolkit";
import { client as apollo } from "../../../../graphql/apollo";
import { DAILY_PLAN_LIST } from "graphql/requests/query/dailyPlanList";
import {
  DailyPlanListPayload,
  DailyPlanListRequestResponse,
  DictionariesRequestResponse,
  VacancyListByCategoryPayload,
  VacancyListByCategoryResponse,
} from "./types";
import { VACANCIES_DICTIONARIES } from "graphql/requests/query/vacanciesDictionaries";
import { VACANCY_LIST_BY_CATEGORY } from "graphql/requests/query/vacanciesList";
import { AsyncThunkConfig } from "services/store/types";

export const fetchDailyPlanList = createAsyncThunk<
  DailyPlanListRequestResponse | null,
  DailyPlanListPayload,
  AsyncThunkConfig
>("vacanciesList/fetchDailyPlanList", async (payload, { rejectWithValue }) => {
  try {
    const res = await apollo.query<DailyPlanListRequestResponse>({
      query: DAILY_PLAN_LIST,
      variables: payload,
    });
    return res.data;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

export const fetchVacanciesDictionaries = createAsyncThunk<
  DictionariesRequestResponse | null,
  undefined,
  AsyncThunkConfig
>(
  "vacanciesList/fetchVacanciesDictionaries",
  async (_, { rejectWithValue }) => {
    try {
      const res = await apollo.query<DictionariesRequestResponse>({
        query: VACANCIES_DICTIONARIES,
      });
      return res.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchVacancyListByCategory = createAsyncThunk<
  VacancyListByCategoryResponse | null,
  VacancyListByCategoryPayload,
  AsyncThunkConfig
>(
  "vacanciesList/fetchVacancyListByCategory",
  async (payload, { rejectWithValue }) => {
    try {
      const res = await apollo.query<VacancyListByCategoryResponse>({
        query: VACANCY_LIST_BY_CATEGORY,
        variables: payload,
      });
      return res.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);
