import React, { useState } from "react";
import { dataSms, dataType } from "./data";
import { Button } from "components/UI/Button";
import { Modal } from "components/UI/Modal";

import "./styles.scss";

interface ModalProps {
  open: boolean;
  onClose: () => void;
  title: string;
  onConfirm: (selectedSms: dataType) => void;
}

export const SEND_SMS_BUTTON_ID = "send-sms-button";

const ModalSms: React.FC<ModalProps> = ({
  open,
  onClose,
  title,
  onConfirm,
}) => {
  const [selectedCheckbox, setSelectedCheckbox] = useState<string>("telegram");

  const handleTemplateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedCheckbox(event.target.value);
  };

  const handleSendClick = (selectedToggle: string) => {
    const selectedSms = dataSms.find((item) => item.key === selectedToggle);
    if (selectedSms?.key) {
      onConfirm(selectedSms);
      onClose();
    }
  };

  return (
    <Modal
      size="normal"
      title={title}
      open={open}
      onCancel={onClose}
      className="modal-sms"
      footer={
        <div className="modal-sms-footer">
          <Button variant="primary" onClick={onClose}>
            Не отправлять
          </Button>
          <Button
            id={SEND_SMS_BUTTON_ID}
            onClick={() => handleSendClick(selectedCheckbox)}
          >
            Отправить
          </Button>
        </div>
      }
    >
      <>
        {dataSms.map((sms) => (
          <div key={sms.key}>
            {dataSms.length > 1 ? (
              <input
                type="radio"
                className="mr-4 h-4 w-4 text-indigo-600 focus:ring-indigo-600 border-gray-300"
                value={sms.key}
                checked={selectedCheckbox === sms.key}
                onChange={handleTemplateChange}
              />
            ) : null}
            <label className="text-medium font-semibold leading-6 text-gray-500">
              {sms.templateNameSms}
            </label>
            <p className="text-m text-gray-500 bg-gray-100 p-2 rounded-md font-medium">
              {sms.textSms}
            </p>
          </div>
        ))}
        <p className="text-sm text-gray-500 italic">
          * Имейте ввиду, что кандидат может получить смс не сразу, это зависит
          от мобильного оператора.
        </p>
      </>
    </Modal>
  );
};

export default ModalSms;
