import { gql } from "@apollo/client";

export const REPLY_LIST_BY_CANDIDATE = gql`
  query replyListByCandidate(
    $candidateId: String!
    $pagination: PaginationType
  ) {
    replyListByCandidate(candidateId: $candidateId, pagination: $pagination) {
      ... on ReplyListSuccess {
        data {
          actionPayClick
          address {
            block
            blockType
            city
            cityType
            house
            houseType
            lat
            lng
            region
            regionType
            settlement
            street
            streetType
            value
          }
          candidateId
          chatLink
          age
          citizenshipId
          russianCitizenship
          isMain
          hasChat
          cityAdsClick
          claimId
          companyId
          contacts {
            email
            fullName
            phone
          }
          createdAt
          deletedAt
          departmentId
          employmentId
          expiredAt
          expiredAtAfterTransferFromCc
          expiredCallTime
          expiredTime
          expiredTimeAfterTransferFromCc
          id
          isCreatedAtBusinessHours
          isExpiredAtBusinessHours
          isTransferFromCcAtBusinessHours
          manager {
            city
            companyId
            departmentId
            email
            firstName
            id
            phone
            role
            secondName
          }
          managerComment
          managerId
          notCallCount
          owner {
            city
            companyId
            departmentId
            email
            firstName
            id
            phone
            role
            secondName
          }
          ownerId
          positionId
          refusalId
          refusalText
          s2Id
          source
          status
          updatedAt
          updatedBy
          userComment
          userContent
          utm {
            campaign
            content
            medium
            source
            term
          }
          vacancyId
        }
        meta {
          limit
          offset
          total
        }
      }
      ... on Error {
        errors
        message
        statusCode
      }
    }
  }
`;
