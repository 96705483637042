import React, { useState } from "react";
import SendSMSButton from "../../SendSMS/SendSMSButton";
import { useSelector } from "react-redux";
import { authInfo } from "../../../services/store/modules/auth";
import TextButton from "../../UI/TextButton/TextButton";
import { PhoneIcon } from "@heroicons/react/24/solid";
import Modal from "../../UI/Modals/Modal";
import { useAppDispatch } from "../../../services/store/store";
import { RootState } from "../../../services/store/rootReducer";
import { selectMainReply } from "../../../services/store/modules/candidates/selectors";
import {
  fetchReplyListByCandidate,
  setupCallCandidate,
} from "services/store/modules/candidates/action";
import { setCurrentCallReply } from "../../../services/store/modules/calls";
import { IReply } from "../../../services/store/modules/replies/types";
import { callOktell } from "../../../services/store/modules/sockets/actions";
interface CommunicationComponentProps {
  phoneNumber: string;
  id: string;
  fullName: string;
  setupCall: boolean;
}
const CALL_CANDIDATE_FORM_BUTTON_ID = "call-candidate-form-button";

const CommunicationComponent: React.FC<CommunicationComponentProps> = ({
  phoneNumber,
  id,
  fullName,
  setupCall,
}) => {
  const authData = useSelector(authInfo);
  const [showCallCandidateList, setShowCallCandidateList] = useState(false);

  const dispatch = useAppDispatch();

  const isActiveCall = useSelector(
    (state: RootState) => state.sockets.isActiveCall
  );
  const replyMain = useSelector(selectMainReply);

  // если понадобится проверка на подключение к oktell
  const oktellStatus = useSelector(
    (state: RootState) => state.sockets.oktellStatus
  );
  const oktellAccess = oktellStatus !== "Оффлайн";

  const onCallOktell = () => {
    dispatch(setupCallCandidate({ id, vacancyId: 0 }));
    if (!isActiveCall) {
      if (replyMain !== null) {
        dispatch(
          setCurrentCallReply(replyMain as unknown as IReply | undefined)
        );
        dispatch(callOktell(phoneNumber));
      } else {
        console.warn("replyMain is null");
      }
    } else {
      console.warn("the call is already in progress");
    }
  };

  const makeCall = () => {
    dispatch(
      fetchReplyListByCandidate({
        id: id,
        pagination: { limit: 5, offset: 0 },
      })
    );
    setShowCallCandidateList(true);
  };

  return (
    <div className="flex mt-[20px]">
      <TextButton
        id={CALL_CANDIDATE_FORM_BUTTON_ID}
        onClick={makeCall}
        icon={<PhoneIcon className="h-5 w-5" />}
        text="Позвонить"
        className="bg-indigo-100 text-indigo-500 max-h-[40px] mt-1"
      />
      <SendSMSButton
        className={`p-3 mt-1 ml-2 border rounded-md max-h-[40px] text-indigo-500 items-center ${
          !setupCall
            ? "bg-gray-100 text-gray-500 cursor-not-allowed"
            : "bg-indigo-100 hover:bg-indigo-200"
        }`}
        phoneNumber={phoneNumber}
        candidateId={id}
        userName={fullName}
        userId={authData?.id ?? 0}
        disablePopup={false}
        disabled={setupCall}
      />

      {showCallCandidateList && (
        <Modal
          title={"Позвонить клиенту"}
          message={
            "Вы собираетесь осуществить звонок клиенту через систему Oktell."
          }
          onClose={() => setShowCallCandidateList(false)}
          onConfirm={onCallOktell}
          open={showCallCandidateList}
          confirmButtonText={"Позвонить"}
        />
      )}
    </div>
  );
};

export default CommunicationComponent;
