import React from "react";
import { Form } from "antd";
import classNames from "classnames";
import "./styles.scss";
import {
  CustomSelectDadataProps,
  SelectDadata,
} from "components/UI/SelectDadata";

interface FormSelectProps extends CustomSelectDadataProps {
  label: string;
  required?: boolean;
  className?: string;
}

export const FormSelectDadata: React.FC<FormSelectProps> = ({
  label,
  required,
  className,
  error,
  ...props
}) => {
  const formSelectClassName = classNames(
    "ui-form-select-dadata",
    { "ui-form-select-dadata--required": required },
    className
  );
  return (
    <Form.Item
      label={label}
      className={formSelectClassName}
      rules={[{ required, message: `Пожалуйста выберите ${label}` }]}
      required={required}
    >
      <SelectDadata error={error} {...props} />
      {error && <div className="ui-form-input-error">{error}</div>}
    </Form.Item>
  );
};
