import React, { useMemo, useCallback, useState } from "react";
import type { MenuProps } from "antd";

import { useAppDispatch, useAppSelector } from "services/store/store";
import { selectCandidateStatuses } from "services/store/modules/candidates/selectors";
import { Dropdown } from "components/UI/Dropdown";
import { ChevronDown, ChevronUp } from "components/UI/icons";
import {
  CandidateStatus,
  CandidateType,
  VacancyType,
} from "graphql/types/types";
import { CANDIDATE_STATUS_TYPE } from "common/const/status";
import { changeCandidateStatus } from "services/store/modules/candidates/action";
import {
  removeCandidate,
  setCandidate,
} from "services/store/modules/candidates";
import { useToggle } from "common/hooks/useToggle";
import { useDebounceCallback } from "common/hooks/useDebounceCallback";
import { IconStatus, Status } from "components/UI/iconStatus";
import { COLOR_BY_CANDIDATE_STATUS } from "common/const/colors";
import { updateCandidateInReply } from "services/store/modules/repliesList";

import "./styles.scss";

interface Props {
  candidate: CandidateType;
  status: CandidateStatus;
  vacancyId?: VacancyType["id"];
  setIsModalOpen: (isOpen: boolean) => void;
}

export const FieldStatus: React.FC<Props> = ({
  status,
  candidate,
  vacancyId,
  setIsModalOpen,
}) => {
  const dispatch = useAppDispatch();
  const [open, toggleOpen] = useToggle(false);

  const candidateStatuses = useAppSelector(selectCandidateStatuses);

  const [iconStatus, setIconStatus] = useState<Status | null>(null);
  const debounceStatus = useDebounceCallback(setIconStatus, 3000);

  const updateCandidateForm = useCallback(
    async (status: CandidateStatus) => {
      setIconStatus("pending");
      toggleOpen();

      try {
        const payload = {
          id: candidate.id,
          vacancyId,
          status,
          comment: "",
        };
        const result = await dispatch(changeCandidateStatus(payload)).unwrap();
        if (!result?.changeCandidateStatus) {
          setIconStatus("error");
          return;
        }
        dispatch(setCandidate(result.changeCandidateStatus));
        dispatch(removeCandidate({ candidateId: candidate.id }));
        dispatch(updateCandidateInReply(result.changeCandidateStatus));

        if (status === CandidateStatus.Candidate) {
          setIsModalOpen(true);
        }
        setIconStatus("success");
      } catch {
        setIconStatus("error");
      } finally {
        debounceStatus(null);
      }
    },
    [
      candidate.id,
      debounceStatus,
      dispatch,
      setIsModalOpen,
      toggleOpen,
      vacancyId,
    ]
  );

  const changeStatusClick = useCallback(
    (e: React.SyntheticEvent<HTMLSpanElement>, status: CandidateStatus) => {
      e.stopPropagation();

      updateCandidateForm(status);
    },
    [updateCandidateForm]
  );

  const items: MenuProps["items"] = useMemo(
    () =>
      candidateStatuses.map(({ key, name }) => ({
        key,
        label: (
          <span
            className={`field-status-label--${key.toLowerCase()}`}
            onClick={(e) => changeStatusClick(e, key as CandidateStatus)}
          >
            {name}
          </span>
        ),
      })),
    [candidateStatuses, changeStatusClick]
  );

  const statusIcon = iconStatus ? (
    <IconStatus status={iconStatus} />
  ) : (
    <>{open ? <ChevronUp /> : <ChevronDown />}</>
  );

  return (
    <Dropdown
      className="field-status"
      overlayClassName="field-status-overlay"
      items={items}
      trigger={["click"]}
      placement="bottomLeft"
      open={open}
      onOpenChange={toggleOpen}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="field-status-trigger"
        title={status ? CANDIDATE_STATUS_TYPE[status] : ""}
      >
        <span style={{ color: COLOR_BY_CANDIDATE_STATUS[status] }}>
          {status ? CANDIDATE_STATUS_TYPE[status] : "Другой"}
        </span>

        <span className="field-status-icon">{statusIcon}</span>
      </div>
    </Dropdown>
  );
};
