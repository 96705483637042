import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  CandidateSuitableListRequestPayload,
  CandidateSuitableListResponse,
  ChangeCandidateStatusResponse,
  ICandidateListByVacancyPayload,
  ICandidateListByVacancyRequestResponse,
  ICandidateListPayload,
  ICandidateRequestResponse,
  IReplyCandidatePayload,
  UpdateCandidateResponse,
} from "./types";
import { client as apollo } from "graphql/apollo";
import { CANDIDATES_LIST } from "graphql/requests/query/candidatesList";
import { CANDIDATES_LIST_BY_VACANCY } from "graphql/requests/query/candidateListByVacancy";
import { CANDIDATE } from "graphql/requests/query/candidates";
import { CANDIDATES_DICTIONARIES } from "graphql/requests/query/candidatesDictionaries";
import { REPLY_LIST_BY_CANDIDATE } from "graphql/requests/query/replyListByCandidate";
import { CANDIDATE_SUITABLE_LIST } from "graphql/requests/query/candidateSuitableList";
import {
  CandidateType,
  MutationChangeCandidateStatusArgs,
  MutationSetupCallArgs,
  MutationUpdateCandidateArgs,
} from "graphql/types/types";
import { CHANGE_CANDIDATE_STATUS } from "graphql/requests/mutation/changeCandidateStatus";
import { TRANSFER_CANDIDATE } from "graphql/requests/mutation/transferCandidate";
import { SETUP_CALL } from "graphql/requests/mutation/setupCall";
import { UPDATE_CANDIDATE } from "graphql/requests/mutation/updateCandidate";
import { AsyncThunkConfig } from "services/store/types";
import { CandidateHistoryResponse, fetchHistoryCandidate } from "api/history";

export const fetchCandidateList = createAsyncThunk(
  "candidates/fetchCandidateList",
  async (payload: ICandidateListPayload, { rejectWithValue }) => {
    try {
      const res = await apollo.query<ICandidateRequestResponse>({
        query: CANDIDATES_LIST,
        variables: payload,
      });
      return res.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);
export const fetchCandidateListByVacancy = createAsyncThunk(
  "candidates/fetchCandidateListByVacancy",
  async (payload: ICandidateListByVacancyPayload, { rejectWithValue }) => {
    try {
      const res = await apollo.query<ICandidateListByVacancyRequestResponse>({
        query: CANDIDATES_LIST_BY_VACANCY,
        variables: payload,
      });
      return res.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);
export const fetchCandidateById = createAsyncThunk(
  "candidates/fetchCandidateById",
  async (id: string, { rejectWithValue }) => {
    try {
      const res = await apollo.query({
        query: CANDIDATE,
        variables: { id },
      });
      return res.data.candidate;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);
export const fetchCandidatesDictionaries = createAsyncThunk(
  "candidates/fetchDictionaries",
  async (_, { rejectWithValue }) => {
    try {
      const res = await apollo.query({
        query: CANDIDATES_DICTIONARIES,
      });
      return res.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);
export const fetchReplyListByCandidate = createAsyncThunk(
  "replies/fetchReplyListByCandidate",
  async (payload: IReplyCandidatePayload, thunkAPI) => {
    const { id, pagination } = payload;
    try {
      const res = await apollo.query({
        query: REPLY_LIST_BY_CANDIDATE,
        variables: {
          candidateId: id,
          pagination,
        },
      });
      return res.data?.replyListByCandidate;
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const fetchCandidateSuitableList = createAsyncThunk<
  CandidateSuitableListResponse,
  CandidateSuitableListRequestPayload,
  AsyncThunkConfig
>(
  "candidates/fetchCandidateSuitableList",
  async (payload, { rejectWithValue }) => {
    try {
      const res = await apollo.query<CandidateSuitableListResponse>({
        query: CANDIDATE_SUITABLE_LIST,
        variables: payload,
      });
      return res.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const changeCandidateStatus = createAsyncThunk<
  ChangeCandidateStatusResponse | null | undefined,
  MutationChangeCandidateStatusArgs,
  AsyncThunkConfig
>("candidate/changeStatus", async (payload, { rejectWithValue }) => {
  try {
    const response = await apollo.mutate<ChangeCandidateStatusResponse>({
      mutation: CHANGE_CANDIDATE_STATUS,
      variables: payload,
    });

    return response.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const transferCandidate = createAsyncThunk(
  "candidate/transferCandidate",
  async (replyId: number, thunkAPI) => {
    try {
      const response = await apollo.mutate({
        mutation: TRANSFER_CANDIDATE,
        variables: { id: replyId },
      });
      return response.data.transferCandidate;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const setupCallCandidate = createAsyncThunk<
  CandidateType,
  MutationSetupCallArgs,
  AsyncThunkConfig
>("candidate/setupCallCandidate", async (payload, thunkAPI) => {
  try {
    const response = await apollo.mutate({
      mutation: SETUP_CALL,
      variables: payload,
    });
    return response.data.setupCall;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const updateCandidate = createAsyncThunk<
  UpdateCandidateResponse | null | undefined,
  MutationUpdateCandidateArgs
>("candidate/updateCandidate", async (payload, { rejectWithValue }) => {
  try {
    const response = await apollo.mutate<UpdateCandidateResponse>({
      mutation: UPDATE_CANDIDATE,
      variables: payload,
    });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const getCandidateHistory = createAsyncThunk<
  CandidateHistoryResponse,
  string,
  AsyncThunkConfig
>("candidate/getCandidateHistory", async (payload, { rejectWithValue }) => {
  try {
    const response = await fetchHistoryCandidate<CandidateHistoryResponse>(
      payload
    );

    return response.data;
  } catch (err) {
    return rejectWithValue("Ошибка получения отчета");
  }
});
