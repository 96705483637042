import React, { useEffect, useRef, useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import { selectMessages } from "../../services/store/modules/chats/selectors";
import "./Reply.css";
import { IReply } from "../../services/store/modules/replies/types";
import moment from "moment/moment";
import {
  deleteNotification,
  fetchMessages,
} from "../../services/store/modules/chats";
import { useAppDispatch } from "../../services/store/store";
import { ReplyType } from "../../graphql/types/types";
import { ReplyChatMessage } from "./Chats/ReplyChatMessage";
import MessageInput from "./Chats/MessageInput";
import ChatHeader from "./Chats/ChatHeader";

const currentDate = () => moment().utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

interface ReplyChatTabProps {
  item: IReply | ReplyType;
  chat_id: string;
}

const ReplyChatTab: React.FC<ReplyChatTabProps> = React.memo(
  ({ item, chat_id }) => {
    const memoizedProps = useMemo(() => ({ item, chat_id }), [item, chat_id]);
    const dispatch = useAppDispatch();
    const { contacts } = memoizedProps.item;

    const chatRef = useRef<HTMLDivElement>(null);

    const { data } = useSelector(selectMessages);

    useEffect(() => {
      dispatch(
        fetchMessages({
          chatID: chat_id,
          since: currentDate(),
          limit: 50,
        })
      );
      dispatch(deleteNotification(chat_id));
    }, [chat_id, currentDate]);

    useEffect(() => {
      scrollToBottom();
    }, [data]);

    const scrollToBottom = useCallback(() => {
      if (chatRef.current) {
        chatRef.current.scrollTop = chatRef.current.scrollHeight;
      }
    }, []);

    return (
      <div
        className="reply-chat-tab"
        style={{
          width: "100%",
          position: "absolute",
          left: "0",
          top: "70px",
        }}
      >
        <ChatHeader fullName={contacts.fullName} />
        {data && (
          <ReplyChatMessage
            messages={data}
            contacts={contacts}
            chatRef={chatRef}
          />
        )}
        {data && <MessageInput chatId={chat_id} />}
      </div>
    );
  }
);

export default ReplyChatTab;
