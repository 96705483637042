import React, { useMemo, useRef, useState } from "react";
import cn from "classnames/dedupe";

import { Table, Props as TableProps } from "components/UI/Table";
import {
  CandidateCommentType,
  CandidateType,
  Source,
  VacancyType,
} from "graphql/types/types";
import { Tooltip } from "components/UI/Tooltip";
import { Copy } from "components/UI/icons";
import { useCopyToClipboard } from "common/hooks/useCopyToClipboard";
import { CANDIDATE_STATUS_TYPE } from "common/const/status";
import { FieldName } from "./components/FieldName";
import { FieldStatus } from "./components/FieldStatus";
import { FieldPhone } from "./components/FieldPhone";
import { CommentColumn } from "./components/CommentColumn";
import { getSessionIds } from "common/utils/cookies";
import { FieldPosition } from "./components/FieldPosition";
import useDraggableScroll from "common/hooks/useDraggableScroll";
import { SOURCE_TYPE } from "common/const/source";
import { CandidateModals } from "../CandidateModals";

import "./styles.scss";

export type CandidatesTableMode = "vacancy" | "recommended" | "reply" | "claim";

type Props = {
  className?: string;
  mode?: CandidatesTableMode;
  vacancyId?: VacancyType["id"];
  candidatesList: CandidateType[];
  handleRowClick?: (id: CandidateType["id"]) => void;
  emptyText?: string;
};

export const CandidatesTable: React.FC<Props> = ({
  className,
  vacancyId,
  mode = "vacancy",
  candidatesList,
  handleRowClick,
  emptyText,
}) => {
  const [, copy] = useCopyToClipboard();

  const tableRef = useRef<HTMLDivElement>(null);
  const { onMouseDown, isDragging } = useDraggableScroll(tableRef);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const openedCandidatesIds = useMemo(
    () => getSessionIds("openedVacancyCandidateIds"),
    []
  );

  const columns: TableProps<CandidateType>["columns"] = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      className: "vacancy-container-id",
      render: (id) => (
        <Tooltip
          placement="bottomLeft"
          title={
            <div
              className="vacancy-container-copyTooltip"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="vacancy-container-copy" onClick={() => copy(id)}>
                <Copy />
              </div>
              {id}
            </div>
          }
        >
          <div className="vacancy-container-short">{id}</div>
        </Tooltip>
      ),
    },
    {
      title: "Фамилия",
      dataIndex: "secondName",
      key: "secondName",
      className: "candidates-table-name",
      ...(mode !== "recommended"
        ? {
            render: (secondName, candidate) => (
              <FieldName
                type="secondName"
                name={secondName}
                candidate={candidate}
              />
            ),
          }
        : {}),
    },
    {
      title: "Имя",
      dataIndex: "firstName",
      key: "firstName",
      className: "candidates-table-name",
      ...(mode !== "recommended"
        ? {
            render: (firstName, candidate) => (
              <FieldName
                type="firstName"
                name={firstName}
                candidate={candidate}
              />
            ),
          }
        : {}),
    },
    {
      title: "Отчество",
      dataIndex: "patronymic",
      key: "patronymic",
      className: "candidates-table-name",
      ...(mode !== "recommended"
        ? {
            render: (patronymic, candidate) => (
              <FieldName
                type="patronymic"
                name={patronymic}
                candidate={candidate}
              />
            ),
          }
        : {}),
    },
    {
      title: "Возраст",
      dataIndex: "age",
      key: "age",
      render: (age) => <div>{age ?? ""}</div>,
    },
    {
      title: "Адрес проживания",
      dataIndex: "actualAddress",
      key: "actualAddress",
      className: "candidates-table-address",
      render: (actualAddress, candidate) =>
        mode !== "recommended" ? (
          <FieldName
            type="actualAddress"
            address={actualAddress}
            candidate={candidate}
            mode={mode}
          />
        ) : (
          <div className="vacancy-container-short">
            {actualAddress?.value || ""}
          </div>
        ),
    },
    {
      title: "Телефон",
      dataIndex: "contacts",
      key: "contacts",
      className: "vacancy-container-phone",
      render: (contacts, candidate) => (
        <FieldPhone
          contacts={contacts}
          candidate={candidate}
          vacancyId={vacancyId ?? 0}
        />
      ),
    },
    ...(mode === "vacancy"
      ? [
          {
            title: (
              <div className="vacancy-container-duration">
                <div>Расстояние, мин.</div>
                <div>Общественный траспорт</div>
              </div>
            ),
            dataIndex: "transitDuration",
            key: "transitDuration",
            className: "vacancy-container-duration",
          },
          {
            title: (
              <div className="vacancy-container-duration">
                <div className="vacancy-container-duration-sub">
                  Расстояние, мин.
                </div>
                <div className="vacancy-container-duration-main">Пешком</div>
              </div>
            ),
            dataIndex: "walkingDuration",
            key: "walkingDuration",
            className: "vacancy-container-duration",
          },
        ]
      : []),
    {
      title: "Ожидаемая ЗП",
      dataIndex: "salaryExpectation",
      key: "salaryExpectation",
      render: (salaryExpectation) => (
        <div>{salaryExpectation ? `${salaryExpectation} ₽` : ""}</div>
      ),
    },
    {
      title: "Справочно: зарплата на прошлом месте работы",
      dataIndex: "lastShiftRate",
      key: "lastShiftRate",
      className: "vacancy-container-duration",
      render: (lastShiftRate) => (
        <div>{lastShiftRate ? `${lastShiftRate} ₽` : ""}</div>
      ),
    },
    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
      className: "candidates-table-status",
      render: (status, candidate) =>
        mode === "vacancy" || mode === "reply" ? (
          <FieldStatus
            status={status}
            candidate={candidate}
            vacancyId={vacancyId}
            setIsModalOpen={setIsModalOpen}
          />
        ) : (
          <div
            title={CANDIDATE_STATUS_TYPE[status]}
            className={`vacancy-container-short vacancy-container-status-${status.toLowerCase()}`}
          >
            {CANDIDATE_STATUS_TYPE[status]}
          </div>
        ),
    },
    ...(mode === "reply"
      ? [
          {
            title: "Источник",
            dataIndex: "source",
            key: "source",
            render: (source: Source) => SOURCE_TYPE[source],
          },
        ]
      : []),
    {
      title: "Комментарий",
      dataIndex: "comments",
      key: "comments",
      render: (comments: CandidateCommentType[] | null, candidate) => (
        <CommentColumn
          comments={comments}
          candidate={candidate}
          canAddComment={mode === "vacancy" || mode === "reply"}
        />
      ),
    },
    {
      title: "Вакансии, которые рассматривает кандидат",
      dataIndex: "positionsNew",
      key: "positionsNew",
      className: "candidates-table-positions",
      render: (positionsNew, candidate) =>
        positionsNew ? (
          <FieldPosition
            mode={mode}
            positions={positionsNew}
            candidate={candidate}
          />
        ) : undefined,
    },
    {
      title: "Гражданство",
      dataIndex: "russianCitizenship",
      key: "russianCitizenship",
      render: (russianCitizenship) => (
        <div>{russianCitizenship ? `РФ` : "Не РФ"}</div>
      ),
    },
    {
      title: "Наличие Автомобиля",
      dataIndex: "haveCar",
      key: "haveCar",
      render: (haveCar) => <div>{haveCar ? `Есть` : "Нет"}</div>,
    },
    {
      title: "Наличие прав категории",
      dataIndex: "driverLicense",
      key: "driverLicense",
      render: (driverLicense) => (
        <>
          {driverLicense && driverLicense.length ? (
            <div className="vacancy-container-short">
              {driverLicense.join(", ")}
            </div>
          ) : null}
        </>
      ),
    },
    ...(mode === "reply"
      ? [
          {
            title: "Резюме",
            //TODO: добавить когда у кандидата будет резюме
          },
        ]
      : []),
  ];

  return (
    <>
      <div
        className={cn("candidates-table", className)}
        ref={tableRef}
        onMouseDown={onMouseDown}
      >
        <Table
          dataSource={candidatesList}
          columns={columns}
          onRow={(candidate) => ({
            onClick: (e) => {
              if (!isDragging) {
                handleRowClick?.(candidate.id);
              }
            },
            className: cn({
              "candidates-table-row--highlighted":
                mode === "vacancy" &&
                openedCandidatesIds.includes(candidate.id),
            }),
          })}
          locale={{ emptyText }}
        />
      </div>
      <CandidateModals
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        mode="vacancy"
      />
    </>
  );
};
