import { createAsyncThunk } from "@reduxjs/toolkit";
import { ObservableSubscription } from "@apollo/client";
import axios from "axios";

import {
  AvitoInfoResult,
  CallsResult,
  Channel,
  IOktellAuthRequestPayload,
  MessagesContent,
  MessagesResult,
} from "./types";
import { client as apollo } from "graphql/apollo";
import toaster from "components/UI/Notifications/Notification";
import {
  IOktellCallData,
  oktellResponseParser,
} from "common/helpers/oktellResponseParser";
import { createCall, setModalResult, updateCall } from "../calls";
import { CALLS } from "graphql/subscriptions/calls";
import { HOT_CALL_STATUS_VALUE } from "common/const/hotCallStatuses";
import { ICall, IUpdateCallPayload } from "../calls/types";
import { SAVE_OKTELL_CREDENTIALS } from "graphql/requests/mutation/saveOktellCredentials";
import { sendLog, setAuthInfo, setAuthLoading } from "../auth";
import { LOG_LEVEL } from "../../../logger/log";
import { AsyncThunkConfig } from "services/store/types";
import { MESSAGES } from "graphql/subscriptions/messages";
import { getActionsByChannel } from "./helpers";
import {
  setActiveCall,
  setCurrentCallOktellData,
  setCurrentHotCallData,
  setOktellStatus,
  setOktellUserLogin,
} from ".";
import { AVITO_INFO } from "graphql/subscriptions/avitoInfo";
import { AvitoStatus } from "graphql/types/types";

export const callsSocket = createAsyncThunk<
  ObservableSubscription,
  void,
  AsyncThunkConfig
>("sockets/callsSocket", async (_, { dispatch, getState }) => {
  const observer = apollo.subscribe<CallsResult>({
    query: CALLS,
  });
  const subscription = observer.subscribe({
    next: ({ data }) => {
      const { calls } = data || {};

      if (calls) {
        const isModal = getState().calls.isModalResult;

        if (!isModal && calls.status === HOT_CALL_STATUS_VALUE.START) {
          dispatch(setModalResult(true));
        }
        dispatch(setCurrentHotCallData(calls as ICall));
      }
    },
    error: (err: { message: string }) => {
      toaster.error({
        title: err?.message || "Ошибка получения данных текущего звонка",
      });
    },
  });

  return subscription;
});

export const callOktell = createAsyncThunk<void, string, AsyncThunkConfig>(
  "sockets/callOktell",
  async (payload, { dispatch, getState }) => {
    const { statusSocket, statusWorker, oktellStatus } = getState().sockets;
    const access =
      statusSocket === "Онлайн" &&
      statusWorker === "Онлайн" &&
      oktellStatus === "Онлайн";
    if (!access) {
      toaster.error({ title: "Ошибка, oktell не подключен" });
      const logData = {
        level: LOG_LEVEL.ERROR,
        message: "Ошибка, oktell не подключен",
        field: "oktell",
      };

      dispatch(sendLog(logData));

      throw new Error("oktell is not active");
    }
    const phone = payload
      .replace(/ /g, "")
      .replace("+7", "8")
      .replace(/-/g, "");
    try {
      await axios
        .get(`http://127.0.0.1:4059/callto?number=${phone}`, {})
        .then((res) => {
          if (res?.status === 200) {
            dispatch(setActiveCall(true));
            dispatch(getCurrentCallInfo());
            dispatch(createCall());
            const isModal = getState()?.calls?.isModalResult;

            if (!isModal) {
              dispatch(setModalResult(true));
            }
          }
        });
    } catch (err) {
      const logData = {
        level: LOG_LEVEL.ERROR,
        message: `Ошибка звонка ${err?.message || ""}`,
        field: "call_oktell",
      };
      dispatch(sendLog(logData));
      toaster.error({
        title: err?.message || "Ошибка звонка, пожалуйста, попробуйте позже",
      });
      dispatch(setActiveCall(false));
    }
  }
);

export const getCurrentCallInfo = createAsyncThunk<
  void,
  undefined,
  AsyncThunkConfig
>("sockets/callInfo", async (_, { getState, dispatch }) => {
  try {
    const res = await axios.get("http://127.0.0.1:4059/getcurrentcallinfo", {
      responseType: "text",
    });
    if (res?.status === 200) {
      const xml = res?.data || "";
      const currentData = getState().sockets.currentOktellCallData;
      if (xml) {
        const temp = oktellResponseParser(xml);
        if (!currentData) {
          dispatch(setCurrentCallOktellData(temp));
        }
        const { currentHotCallData } = getState().sockets;
        if (
          currentHotCallData &&
          currentHotCallData?.status === HOT_CALL_STATUS_VALUE.START &&
          temp?.mode?.value === "connected"
        ) {
          const payload: IUpdateCallPayload = {
            form: {
              result: currentHotCallData?.result || "",
              status: HOT_CALL_STATUS_VALUE.IN_PROGRESS,
            },
            id: currentHotCallData?.id,
            action: "UPDATE",
          };
          if (payload.id) {
            dispatch(updateCall(payload));
          }
        }
        if (currentData?.starttime && temp?.mode?.value === "none") {
          const newValue = {
            ...currentData,
            finishtime: new Date().toISOString(),
          } as IOktellCallData;
          dispatch(setCurrentCallOktellData(newValue));
        }
      }
    }
  } catch (err) {
    const logData = {
      level: LOG_LEVEL.ERROR,
      message: `Ошибка получения данных звонка ${err?.message || ""}`,
      field: "current_call_oktell",
    };
    dispatch(sendLog(logData));
    toaster.error({
      title: err?.message || "Ошибка получения данных текущего звонка",
    });
    dispatch(setActiveCall(false));
  }
});

export const endCallOktell = createAsyncThunk<void, string, AsyncThunkConfig>(
  "sockets/endCallOktell",
  async (status, { getState, dispatch }) => {
    try {
      axios.get("http://127.0.0.1:4059/disconnectcall", {}).then((res) => {
        if (res?.status === 200) {
          const { currentHotCallData } = getState().sockets;

          if (currentHotCallData) {
            const payload: IUpdateCallPayload = {
              form: {
                result: currentHotCallData?.result || "",
                status: status || HOT_CALL_STATUS_VALUE.FINISH,
                finishAt: new Date().toISOString(),
              },
              id: currentHotCallData?.id,
              action: "FINISH",
            };
            if (payload.id) {
              dispatch(updateCall(payload));
            }
          }
          dispatch(setActiveCall(false));
        }
      });
    } catch (err) {
      const logData = {
        level: LOG_LEVEL.ERROR,
        message: `Ошибка завершения звонка ${err?.message || ""}`,
        field: "end_call_oktell",
      };
      dispatch(sendLog(logData));
      toaster.error({
        title:
          err?.message ||
          "Ошибка завершения звонка, пожалуйста, попробуйте позже",
      });
      dispatch(setActiveCall(false));
    }
  }
);

export const getOktellStatus = createAsyncThunk<
  void,
  undefined,
  AsyncThunkConfig
>("sockets/getOktellStatus", async (_, { getState, dispatch }) => {
  const res = await axios.get("http://127.0.0.1:4059/getcurrentuserlogin", {
    responseType: "text",
  });
  const status = res.status === 200 ? "Онлайн" : "Оффлайн";
  const { oktellStatus } = getState().sockets;
  if (status !== oktellStatus) {
    dispatch(setOktellStatus(status));
  }
  if (res?.status === 200) {
    const xml = res?.data || "";
    const { oktellUserLogin } = getState().sockets;
    if (xml) {
      const temp = oktellResponseParser(xml);
      if (
        temp?.userlogin?.value &&
        oktellUserLogin !== temp?.userlogin?.value
      ) {
        const userLogin = temp?.userlogin?.value?.toString();
        dispatch(setOktellUserLogin(userLogin || ""));
      }
    }
  } else {
    const logData = {
      level: LOG_LEVEL.ERROR,
      message: `Не удалось получить статус oktell`,
      field: "oktell_status",
    };
    dispatch(sendLog(logData));
  }
});

export const oktellAuth = createAsyncThunk<
  void,
  IOktellAuthRequestPayload,
  AsyncThunkConfig
>("sockets/oktellAuth", async ({ id, form }, { dispatch }) => {
  dispatch(setAuthLoading(true));

  try {
    const res = await apollo.query({
      query: SAVE_OKTELL_CREDENTIALS,
      variables: {
        id: id,
        form: form,
      },
    });
    if (res.data) {
      const userData = res.data?.saveOktellCredentials;
      if (!userData?.oktellLogin) {
        toaster.error({ title: "Неверные данные" });
      }
      if (userData) {
        dispatch(setAuthInfo(userData));
      }
    }
  } catch (err) {
    const logData = {
      level: LOG_LEVEL.ERROR,
      message: `Ошибка входа oktell ${err?.message || ""}`,
      field: "oktell_login",
    };
    dispatch(sendLog(logData));
  } finally {
    dispatch(setAuthLoading(false));
  }
});

export const subscribeEnableMessages = createAsyncThunk<
  ObservableSubscription,
  { channel: Channel },
  AsyncThunkConfig
>("sockets/messages", async (payload, { dispatch }) => {
  const observer = apollo.subscribe<MessagesResult>({
    query: MESSAGES,
    variables: payload,
  });

  const subscription = observer.subscribe({
    next: ({ data }) => {
      const { messages } = data || {};

      if (messages) {
        const { content, owner } = messages;
        const {
          idVacancy: vacancyId,
          idReply: replyId,
          inWork,
          managersIds,
        }: MessagesContent = JSON.parse(content) ?? {};

        const { addAction, removeAction } = getActionsByChannel({
          vacancyId,
          replyId,
          owner,
          managersIds,
        })[payload.channel];

        if (inWork && addAction) {
          dispatch(addAction);
        } else if (!inWork && removeAction) {
          dispatch(removeAction);
        }
      }
    },
    error: () => {
      toaster.error({
        title: `Ошибка подключения сокета по каналу ${payload.channel}`,
      });
    },
  });

  return subscription;
});

export const subscribeAvitoInfo = createAsyncThunk<
  ObservableSubscription,
  void,
  AsyncThunkConfig
>("sockets/avitoInfo", async () => {
  const observer = apollo.subscribe<AvitoInfoResult>({
    query: AVITO_INFO,
  });

  const subscription = observer.subscribe({
    next: ({ data }) => {
      const { avitoInfo } = data || {};

      if (avitoInfo) {
        const { content, status } = avitoInfo;

        if (status === AvitoStatus.Success) {
          toaster.success({ title: content }, { autoClose: 6000 });
        }

        if (status === AvitoStatus.Error) {
          toaster.error({ title: content }, { autoClose: 6000 });
        }
      }
    },
    error: () => {
      toaster.error({
        title: `Ошибка подключения сокета avitoInfo`,
      });
    },
  });

  return subscription;
});
