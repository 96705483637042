import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch } from "services/store/store";
import { loadUsers } from "services/store/modules/user/action";
import { UserFilterType, UserRole } from "graphql/types/types";
import { IUser } from "services/store/modules/user/types";

const LIMIT = 400;

export const useFetchUsers = (
  filter: UserFilterType = {},
  limit = LIMIT,
  roles: UserRole[] = []
) => {
  const dispatch = useAppDispatch();

  const [userList, setUserList] = useState<IUser[]>([]);

  const fetchUsers = useCallback(
    (role?: UserRole) => {
      return dispatch(
        loadUsers({
          filter: role ? { role } : filter,
          pagination: { limit },
        })
      );
    },
    [dispatch, filter, limit]
  );

  const fetchUsersPromises = useMemo(
    () => roles.map((role) => fetchUsers(role)),
    []
  );

  const fetchUsersByRoles = useCallback(async () => {
    const usersList = await Promise.all(
      fetchUsersPromises.map((promise) => promise.unwrap())
    );
    setUserList(usersList.flat());
  }, [fetchUsersPromises]);

  useEffect(() => {
    if (roles.length) {
      fetchUsersByRoles();
    }
  }, []);

  return { fetchUsers, userList };
};
