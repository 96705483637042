import React from "react";

export const PhoneCall: React.FC = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.2875 3.75C12.02 3.89292 12.6932 4.25119 13.221 4.77895C13.7488 5.30671 14.107 5.97995 14.2499 6.7125M11.2875 0.75C12.8094 0.919077 14.2286 1.60063 15.3121 2.68276C16.3956 3.76488 17.079 5.18326 17.2499 6.705M16.4999 12.69V14.94C16.5008 15.1489 16.458 15.3556 16.3743 15.547C16.2907 15.7384 16.1679 15.9102 16.014 16.0514C15.8601 16.1926 15.6784 16.3001 15.4805 16.367C15.2826 16.4339 15.073 16.4588 14.8649 16.44C12.5571 16.1892 10.3402 15.4006 8.39245 14.1375C6.58032 12.986 5.04395 11.4496 3.89245 9.6375C2.62493 7.6809 1.83613 5.45325 1.58995 3.135C1.57121 2.9276 1.59586 2.71857 1.66233 2.52122C1.72879 2.32387 1.83563 2.14252 1.97602 1.98872C2.11642 1.83491 2.2873 1.71203 2.47779 1.62789C2.66828 1.54375 2.87421 1.5002 3.08245 1.5H5.33245C5.69643 1.49642 6.04929 1.62531 6.32527 1.86265C6.60125 2.09999 6.78151 2.42959 6.83245 2.79C6.92742 3.51005 7.10354 4.21705 7.35745 4.8975C7.45836 5.16594 7.4802 5.45769 7.42038 5.73816C7.36056 6.01863 7.2216 6.27608 7.01995 6.48L6.06745 7.4325C7.13512 9.31016 8.68979 10.8648 10.5675 11.9325L11.5199 10.98C11.7239 10.7784 11.9813 10.6394 12.2618 10.5796C12.5423 10.5198 12.834 10.5416 13.1024 10.6425C13.7829 10.8964 14.4899 11.0725 15.21 11.1675C15.5743 11.2189 15.907 11.4024 16.1448 11.6831C16.3827 11.9638 16.5091 12.3222 16.4999 12.69Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
