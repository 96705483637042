import { gql } from "@apollo/client";

export const CREATE_REPLY = gql`
  mutation createReply($form: ReplyInputType!) {
    createReply(form: $form) {
      ... on ReplyType {
        id
        contacts {
          email
          fullName
          phone
        }
        ownerId
        positionId
        vacancyId
        source
        status
        isMain
      }
      ... on Error {
        statusCode
        errors
        message
      }
    }
  }
`;
