import React from "react";
import ReactInputMask, { Props } from "react-input-mask";
import classNames from "classnames";
import "./styles.scss";

export type InputMaskProps = Props & {
  suffix?: React.ReactNode;
};

export const InputMask: React.FC<InputMaskProps> = ({
  className,
  disabled,
  required,
  suffix,
  maskPlaceholder = "",
  mask,
  ...props
}) => {
  const inputMaskClassNames = classNames(
    "ui-input-mask",
    {
      "ui-input-mask--disabled": disabled,
      "ui-input-mask--required": required,
    },
    className
  );

  return (
    <div className="ui-input-mask-wrapper">
      <ReactInputMask
        mask={mask}
        className={inputMaskClassNames}
        maskPlaceholder={maskPlaceholder}
        {...props}
      />
      {suffix && <div className="ui-input-mask-suffix">{suffix}</div>}
    </div>
  );
};
