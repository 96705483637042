import React, { FC, ReactNode } from "react";

interface ButtonProps {
  onClick: () => void;
  icon: ReactNode;
  text: string;
  className?: string;
  id?: string;
}

const TextButton: FC<ButtonProps> = ({
  onClick,
  icon,
  text,
  className,
  id,
}) => {
  return (
    <button
      id={id}
      className={`flex text-sm items-center p-2 rounded hover:bg-indigo-200 ${className}`}
      onClick={onClick}
    >
      <span className="mr-2">{icon}</span>
      {text}
    </button>
  );
};

export default TextButton;
